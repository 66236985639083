import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import { store } from "./store";
import SchedulePageWrapper from "./components/SchedulePageWrapper";
import ErrorBoundary from "../Shared/StatelessComponents/ErrorBoundary";

// This component is the top level entry point for the bundle
// We render this component using react_on_rails in the slim template, passing in the relevant props
// This component is responsible for providing access to the redux store
function SchedulePage({ questions, scheduleIdentifier, uuid, submitUrl, ajaxUrls, formAuthenticityToken, exampleCSVPath, brandingSettings, honeybadgerConfig, honeybadgerContext }) {
  return (
    <ErrorBoundary honeybadgerConfig={honeybadgerConfig} honeybadgerContext={honeybadgerContext} >
      <Provider store={store}>
        <SchedulePageWrapper
          scheduleIdentifier={scheduleIdentifier}
          questions={questions}
          uuid={uuid}
          action={submitUrl}
          ajaxUrls={ajaxUrls}
          formAuthenticityToken={formAuthenticityToken}
          exampleCSVPath={exampleCSVPath}
          brandingSettings={brandingSettings}
        />
      </Provider>
    </ErrorBoundary>
  );
}

SchedulePage.propTypes = {
  uuid: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  ajaxUrls: PropTypes.object.isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
  scheduleIdentifier: PropTypes.string.isRequired,
  questions: PropTypes.object.isRequired,
  exampleCSVPath: PropTypes.string.isRequired,
  brandingSettings: PropTypes.object.isRequired,
  honeybadgerConfig: PropTypes.object.isRequired,
  honeybadgerContext: PropTypes.object.isRequired,
};

export default SchedulePage;
