/* global $ */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import addSolidOrangeImage from "../../../images/add_solid_orange.svg";
import questionMarkImage from "../../../images/question_mark.svg";

// This component renders the label for a question group
// it includes a humanized display for the question group as
// well as an add button if the question group is multiple entries
// It is also responsible for displaying the group level errors

const QuestionGroupLabel = ({
  question,
  questionIdentifier,
  onAddButtonClick,
}) => {
  const attemptedSubmit = useSelector(state => state.form.attemptedSubmit);
  const fieldValidations = useSelector(state => state.form.field_validations?.[questionIdentifier] || []);

  const questionIdentifierWithoutVersion = questionIdentifier.replace(
    /_v\d+$/,
    "",
  );
  const humanizedIdentifier = questionIdentifierWithoutVersion
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  useEffect(() => {
    $("[data-bs-toggle=\"tooltip\"]").tooltip();
  }, []);

  return (
    <div className="question_group_label_wrapper">
      <b className="question_group_label">{question.label}</b>
      {question.tooltip && (
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={question.tooltip}
          style={{ display: "inline", width: "40px" }}
        >
          <img
            src={questionMarkImage}
            alt="Tooltip icon"
            style={{ width: "20px", marginLeft: "5px" }}
          />
        </div>
      )}
      {question.multiple_entries && !question.exact_count && (
        <div className="multiple_entry_add_button_wrapper">
          <button className="btn" onClick={onAddButtonClick} type="button">
            <img src={addSolidOrangeImage} alt="Add" />
            Add {humanizedIdentifier.split(" ").slice(-1)[0]}
          </button>
        </div>
      )}
      <div className="error_container" >
        {attemptedSubmit && question.errors && question.errors[0] && (
          <>
            <small
              className="form-text validation-error"
              style={{ color: "red" }}
            >
              {question.errors[0]}
            </small>
            <br></br>
          </>
        )}
        {/* This is a hack to support old stage-driven custom field validations. */}
        {/* Automated field validations created by redirects on incomplete forms are not supported */}
        {/* field validations on redirects on incomplete forms are powered by the questions data instead */}
        {attemptedSubmit && fieldValidations && fieldValidations[0] && fieldValidations.every(item => typeof item === "string") && (
          fieldValidations.map((error, index) => (
            <>
              <small
                className="form-text validation-error"
                style={{ color: "red" }}
              >
                {error}
              </small>
              <br></br>
            </>
          ))
        )}

      </div>
    </div>
  );
};

QuestionGroupLabel.propTypes = {
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    multiple_entries: PropTypes.bool,
    exact_count: PropTypes.number,
    tooltip: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  questionIdentifier: PropTypes.string.isRequired,
  onAddButtonClick: PropTypes.func,
};

export default QuestionGroupLabel;
