import React, { useRef } from "react";
import PropTypes from "prop-types";

function CurrencyInput({
  questionName,
  value,
  describerId,
  hasFieldError,
  question,
  onChange,
  onBlur,
  inputRef,
}) {
  const ref = inputRef || useRef(null);

  const stringToInt = (str) => {
    const num = parseInt(str.replace(/[^\d]/g, ""), 10);
    return isNaN(num) ? 0 : num;
  };

  const formatNumber = (n) => {
    const intString = stringToInt(n).toString();
    if (intString === "NaN") {
      return "0";
    }
    return intString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const formattedValue = (value === undefined || value === null || value === "") ? "" : `$${formatNumber(value.toString())}`;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = `$${formatNumber(inputValue)}`;
    onChange(formattedValue);

    // Cursor positioning logic
    const originalLen = inputValue.length;
    const updatedLen = formattedValue.length;
    let caretPos = event.target.selectionStart;
    caretPos = updatedLen - originalLen + caretPos;
    if (caretPos < 1) caretPos = 1;

    // Schedule the cursor positioning to run after React's state update
    setTimeout(() => {
      ref.current.setSelectionRange(caretPos, caretPos);
    }, 0);
  };

  return (
    <input
      ref={ref}
      className={"form-control question_block_input currency"}
      type="text"
      name={questionName}
      pattern="^\$\d{1,3}(,\d{3})*$"
      aria-describedby={describerId}
      style={hasFieldError ? { border: "1px solid red" } : {}}
      value={formattedValue}
      min="0"
      onChange={handleChange}
      onBlur={(e) => onBlur(e.target.value)}
      disabled={question.locked}
    />
  );
}

CurrencyInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  describerId: PropTypes.string,
  hasFieldError: PropTypes.bool,
  question: PropTypes.shape({
    locked: PropTypes.bool,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

CurrencyInput.defaultProps = {
  fieldType: "",
  hasFieldError: false,
  value: null,
};

export default CurrencyInput;
