import React, { useState, useEffect, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Honeybadger } from "@honeybadger-io/react";
import questionMarkImage from "../../../images/question_mark.svg";
import greenCheckmarkImage from "../../../images/green_check.svg";
import { usePersistentState } from "./usePersistentState";

export const useE2Value = ({ individualKey, blockingKey, currentLocationData }) => {
  const [replacementCost, setReplacementCost] = usePersistentState(`${individualKey}.replacementCost`, 0);
  const [propertyId, setPropertyId] = usePersistentState(`${individualKey}.propertyId`, "");
  const [showRecommendation, setShowRecommendation] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [didShowRecommendation, setDidShowRecommendation] = useState(false);
  const [acknowledgementAccepted, setAcknowledgementAccepted] = usePersistentState(`${blockingKey}.accepted`, false);
  const [acknowledgementBlocker, setAcknowledgementBlocker] = usePersistentState(blockingKey, false);
  const buildingValueInputRef = useRef(null);

  const timer = useRef(null);

  const requiredFields = [
    "structure_type", "square_feet", "address_1",
    "city", "state", "zip", "construction_type", "year_built",
    "exterior", "roof_covering",
  ];
  const optionalFields = [
    "address_2",
  ];

  const sliceObject = (obj, keys) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key]) => keys.includes(key)),
    );
  };

  const acknowledgementIdentifier = "replacement_cost_estimator";
  const acknowledgementContent = "The replacement cost estimator is just a tool/guide based on 3rd party data to help prevent being over/under insured and possibly facing a coinsurance penalty in the event of a claim. It is the responsibility of the insured to determine the actual replacement value of all property.";
  const submitAcknowledgement = async (e) => {
    e.preventDefault();
    const endpoint = `/api/acknowledgements/${acknowledgementIdentifier}`;
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ content: acknowledgementContent }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("input[name=\"authenticity_token\"]")?.value,
      },
    });
    response.json().then((res) => {
      if (res.error) {
        console.error(res.error);
        Honeybadger.notify(res.error);
      } else {
        setShowPopover(true);
        setAcknowledgementAccepted(true);
        setAcknowledgementBlocker(false);
        setTimeout(() => {
          setShowPopover(false);
        }, 1000);
      }
    });
  };

  const getResponse = async (requestPayload) => {
    const endpoint = "/api/services/e2value";
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(requestPayload),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("input[name=\"authenticity_token\"]")?.value,
      },
    });
    return response.json();
  };

  const stringToInt = (str) => {
    const num = parseInt((str || "").replace(/[^\d]/g, ""), 10);
    return isNaN(num) ? 0 : num;
  };

  const formatNumber = (n) => {
    const intString = stringToInt(n).toString();
    if (intString === "NaN") {
      return "0";
    }
    return intString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // do this whenever the relevant location data changes
  useEffect(() => {
    const refreshState = async () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      const timeoutLength = 300;
      // check if all required fields are present
      if (requiredFields.some(key => !currentLocationData[key])) {
        return;
      }
      const requestData = sliceObject(currentLocationData, requiredFields.concat(optionalFields));
      if (propertyId) {
        requestData.property_id = propertyId;
      }
      timer.current = setTimeout(async () => {
        try {
          const responsePayload = await getResponse(requestData); // store the property id to use in subsequent requests

          if (responsePayload.error) {
            console.error(responsePayload.error);
          } else {
            const newReplacementCost = responsePayload.total_replacement_cost_medium;
            if (newReplacementCost === 0) {
              throw new Error("E2Value returned a replacement cost of 0");
            }
            if (!newReplacementCost) { return; }
            setPropertyId(responsePayload.PropertyID);
            setReplacementCost(newReplacementCost);
            const currentBuildingValue = buildingValueInputRef.current?.value;
            if (!currentBuildingValue) { return; }
            // if we have the blocker in cookies, but haven't shown it in this refresh, then show the popover so that it can be acknowledged
            if (acknowledgementBlocker && !didShowRecommendation) {
              setShowRecommendation(true);
              // otherwise, if the building value is greater than 10% of the median e2value estimate, hide the popover
            } else if (newReplacementCost * 0.9 < stringToInt(currentBuildingValue)) {
              setShowRecommendation(false);
              // TODO - add the plolicy value state flex where we show the suggestion if the value is much greater than the estimate
              // if we get here then the building value is less than the threshold, so trigger the popover to show
            } else {
              setShowRecommendation(true);
              setDidShowRecommendation(true);
              setTimeout(() => {
                // after showing the popover set the blocker, but don't reset it if the acknowledgement has already been accepted once
                if (acknowledgementBlocker !== (didShowRecommendation && !acknowledgementAccepted)) {
                  setAcknowledgementBlocker(didShowRecommendation && !acknowledgementAccepted);
                }
              }, 0);
            }
          }
        } catch (error) {
          Honeybadger.notify(error);
        }
      }, timeoutLength);
    };

    refreshState();

    return () => {};
  }, [currentLocationData]);

  // only show the popover if we're showing the recommendation and it hasn't been acknowledged yet
  const showAcknowledgementPopover = (
    (showRecommendation || didShowRecommendation) && !acknowledgementAccepted
  ) || showPopover;

  const popover = (
    <Popover id="popover-basic" className={`${acknowledgementIdentifier} acknowledgement_popover`}>
      <Popover.Header as="h3">Replacement Cost Estimator</Popover.Header>
      <Popover.Body>
        {acknowledgementContent}
        {acknowledgementAccepted
          ? (
            <span className="text-success float-end" style={{ fontWeight: "bold", marginTop: "4px" }}><img src={greenCheckmarkImage} style={{ width: "20px", marginRight: "5px" }} />Acknowledged</span>
            )
          : (
            <button type="submit" className="btn btn-danger mb-3 mt-3 float-end" style={{ padding: "2px 12px" }} onClick={submitAcknowledgement}>I understand</button>
            )}
      </Popover.Body>
    </Popover>
  );
  const renderedInfoLine = (
    <div className="infoline_wrapper" style={(showRecommendation || showAcknowledgementPopover) ? null : { display: "none" } }>
      <span className="infoline">A replacement cost estimator calculates the cost to replace this type of building between &quot;${
        formatNumber((replacementCost && Math.floor(replacementCost * 0.9))?.toString())
      } - ${
        formatNumber((replacementCost && Math.floor(replacementCost * 1.1))?.toString())
      }&quot;</span>
      <OverlayTrigger show={showAcknowledgementPopover} placement="bottom" overlay={popover}>
        <img
          className="question_mark_icon"
          src={questionMarkImage}
          style={{ width: "20px", marginLeft: "5px" }}
          alt="Tooltip icon"
          onClick={() => setShowPopover(!showPopover)}
        />
      </OverlayTrigger>
    </div>
  );

  return {
    renderedInfoLine,
    buildingValueInputRef,
  };
};
