// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import ReactOnRails from 'react-on-rails'
import NotificationCenter from '../bundles/Admin/NotificationCenter'
import SchedulePage from '../bundles/SchedulePage/SchedulePage'
import SubmissionsDataPage from '../bundles/SubmissionsDataPage/SubmissionsDataPage'

ReactOnRails.register({
  NotificationCenter,
  SchedulePage,
  SubmissionsDataPage,
})
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require("jquery")
import 'bootstrap'
require('@popperjs/core')
require("@nathanvda/cocoon")
import '@fortawesome/fontawesome-free/css/all'
import '@fortawesome/fontawesome-free/js/all'
import svgPanZoom from 'svg-pan-zoom';
window.svgPanZoom = svgPanZoom;
import mermaid from 'mermaid';
window.mermaid = mermaid;

window.Helpers = require('apply/helpers.js').default
// require('apply/helpers.js')

require('apply/new.js')
require('apply/notify_subproducer_quote.js')
require('apply/notify_quote_ready.js')
require('apply/offer.js')
require('apply/company_settings.js')
require('apply/email_templates.js')
require('apply/taxes_and_fees.js')
require('apply/quote_modifications.js')
require('apply/product_selection.js')
require('apply/esign.js')
require('apply/popovers.js')
require('apply/question_box.js')
require('apply/checkout.js')
require('apply/confetti.js')
require('apply/forms.js')
require('apply/policy.js')
require('apply/progress_bars.js')
require('apply/restart_application.js')
require('apply/indication_button.js')
require('distribution/integrations.js')
require('distribution/quote_not_taken_helper.js')
require('distribution/portal.js')

require('admin/offer_adjustment.js')
require('admin/journey_flow.js')
require('admin/document_storage.js')
require('admin/history_view.js')
require('admin/distribution.js')
require('admin/admins.js')
require('admin/email_templates.js')
require('admin/underwriting_notes.js')
require('admin/underwriter_reviews.js')
require('admin/application_overview.js')
require('admin/servicing.js')
require('admin/business_intel_portfolios.js')
require('admin/open_quotes.js')
require('admin/originations_emails.js')

require('widgets/fold_button.js')
require('widgets/click_to_show.js')
require('admin/prism.js')

// blitz stuff
window.ViewHelpers = require('../src/view_helpers')

$(document).ready(function() {
  $('[data-bs-toggle="tooltip"]').tooltip()
})
import SlidingPane from '../src/SlidingPane'
$(document).ready(SlidingPane.renderPane)
