import Helpers from './helpers.js'

$(()=>{

  $(document).on('click', '.triggers_submit', (e) => {
    $('.disable_on_submits').addClass('disabled')
  })

  $(document).on('click', '.disabled', (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
  })

  // initial format of currency fields
  $('input.currency').each((i, element) =>{
    Helpers.formatDollars($(element))
  })
  // generic form submit logic to disable buttons and avoid double click/double submit issues

  $(document).on('submit', 'form', (e)=> {
    // e.preventDefault()

    let $form = $(e.target)
    if ($form.hasClass('onboarding')) { return }
    // console.log($form)

    let $submit_buttons = $form.find('button[type="submit"], input[type="submit"]')
    // console.log($submit_buttons)

    $submit_buttons.addClass('disabled_with_spinner')
    $submit_buttons.prop('disabled', true)
    $('.disable_on_submits').addClass('disabled')

  })

  let ignored_key_events = [
    9,  // tab
    16, // shift
    37, // left arrow key
    39, // right arrow key
  ]
  $(document).on('keyup', 'input.currency', (e)=>{
    if (ignored_key_events.includes(e.which)) { return };

    Helpers.formatDollars($(e.target))
  })
  $(document).on('blur', 'input.currency', (e)=>{ Helpers.formatDollars($(e.target))})
})

