import React, { useMemo, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useE2Value } from "../../Shared/Services/useE2Value";
import QuestionBlock from "./QuestionBlock.jsx";
import { FormContext } from "../../Shared/StatelessComponents/FormContext";

function SmartBuildingValue({ question, parentQuestionIdentifier, groupIndex }) {
  const calculatedFields = question.calculated_fields;
  const { registerAcknowledgement, uuid } = useContext(FormContext);
  const relevantKeys = useMemo(() => {
    return Object.keys(question.relevant_fields);
  }, [question.relevant_fields]);

  const compositeSelector = useMemo(() => {
    return state => {
      const data = {};
      for (const key of relevantKeys) {
        data[key] = state.form.questions[parentQuestionIdentifier].groups[groupIndex][key].value;
      }
      return data;
    };
  }, [relevantKeys, parentQuestionIdentifier, groupIndex]);
  const relevantLocationData = useSelector(compositeSelector);
  const currentLocationData = { ...relevantLocationData, ...calculatedFields };

  // individual key is used for local storage caching of current field data
  const individualKey = `react.acknowledgement.${uuid}.${parentQuestionIdentifier}.${groupIndex}.smart_building_value`;
  // blocking key is used for local storage caching of acknowledgement blocker, which is app-specific
  // at least one replacement cost estimator disclosure must be acknowledged before the form can be submitted
  // if there are multiple locations that have suggestions appear, we don't require the acknowledgement for each
  const blockingKey = `react.acknowledgement.${uuid}.replacement_cost_estimator`;

  const {
    buildingValueInputRef,
    renderedInfoLine,
  } = useE2Value({
    individualKey,
    blockingKey,
    currentLocationData,
  });
  useEffect(() => {
    try { // wrap in a try so that we don't need to handle acknowledgements on manual intake
      registerAcknowledgement(blockingKey);
    } catch (error) {
      console.error(error);
    }
  }, [blockingKey]);

  const parentQuestion = useSelector((state) => state.form.questions[parentQuestionIdentifier]);

  return (
    <div className="smart_building_value">
      {Object.entries(question.aliased_fields).map(([valueFieldIdentifier, childQuestion]) => {
        return (
          <QuestionBlock
            key={valueFieldIdentifier}
            questionIdentifier={childQuestion.question_identifier}
            question={childQuestion}
            multiple={parentQuestion.multiple_entries}
            parentQuestionIdentifier={parentQuestionIdentifier}
            groupIndex={groupIndex}
            inputRef={buildingValueInputRef}
          />
        );
      })}
      {renderedInfoLine}
    </div>
  );
}

SmartBuildingValue.propTypes = {
  question: PropTypes.object.isRequired,
  parentQuestionIdentifier: PropTypes.string.isRequired,
  groupIndex: PropTypes.number,
};

export default SmartBuildingValue;
