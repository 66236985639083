$(()=>{
  function handle_progress_bar_scroll_indicators() {
    let $step_container = $('#sub_progress_bar .step_container')
    let max_scroll = $step_container[0].scrollWidth
    let offset = $step_container[0].offsetWidth
    let current_scroll = $step_container[0].scrollLeft

    if (current_scroll <= 10) {
      $('#sub_progress_bar_head_scroll_indicator').hide()
    } else {
      $('#sub_progress_bar_head_scroll_indicator').show()
    }


    if ((max_scroll - (current_scroll + offset)) <= 10) {
      $('#sub_progress_bar_tail_scroll_indicator').hide()
    } else {
      $('#sub_progress_bar_tail_scroll_indicator').show()
    }
  }

  if ($('#sub_progress_bar')[0] != undefined) {
    handle_progress_bar_scroll_indicators()
    setTimeout(()=>{
      let current_step = $('.sub_progress_bar_step.current')
      if (current_step[0] != undefined) {
        current_step.each((i, element) => {
          $(element.parentElement).animate({ scrollLeft: element.offsetLeft - element.offsetWidth * 2 }, 100)
        })
      }
    }, 10)
  }

  $('#sub_progress_bar .step_container').on('scroll', (e)=>{
    handle_progress_bar_scroll_indicators()
  })

  $('#sub_progress_bar .scroll_indicator').on('mouseenter', (e)=>{
    e.stopPropagation()
    e.stopImmediatePropagation()
    let $target = $(e.target)
    let direction = $target.data('direction')
    let $step_container = $('#sub_progress_bar .step_container')
    let max_scroll = $step_container[0].scrollWidth - $step_container[0].offsetWidth
    let current_scroll = $step_container[0].scrollLeft
    if (direction == 'left') {
      let duration = Math.max(2000 - 2000 * (1 + max_scroll - current_scroll) / max_scroll, 200)
      $step_container.animate({ scrollLeft: 0 }, duration, 'linear')
    } else if (direction == 'right') {
      let duration = Math.max(2000 * (1 + max_scroll - current_scroll) / max_scroll, 200)
      $step_container.animate({ scrollLeft: max_scroll }, duration, 'linear')
    }
  })
  $('#sub_progress_bar .step_container').on('mouseleave', (e)=> {
    e.stopPropagation()
    e.stopImmediatePropagation()
    $('#sub_progress_bar .step_container').stop(true)
  })
})