$(function() {
  const $elem = $(".fold_button");

  if ($elem.length > 0) {
    $elem.on("click", e => {
      const $clicked = $(e.target);
      const fold_target = $clicked.data("fold_target");
      if (fold_target === null || fold_target === undefined) {
        return;
      }

      const fold_targets = document.querySelectorAll(fold_target);
      fold_targets.forEach(function($fold_target) {
        if (!$fold_target.style.overflow) {
          $fold_target.style.overflow = "hidden";
        }
        if (!$fold_target.style.transition) {
          $fold_target.style.transition = "max-height 0.3s";
        }

        if ($clicked.is(":checked")) {
          $fold_target.style.maxHeight = "0px";
          setTimeout(()=>{
            $fold_target.classList.add("folded");
          }, 150)
        } else {
          $fold_target.classList.remove("folded");
          $fold_target.style.maxHeight = $fold_target.scrollHeight + "px";
          $fold_target.style.overflow = "";
          setTimeout(()=>{
            $fold_target.style.maxHeight = "";
          },200)
        }
      });
      // async update view options
      setTimeout(() => {
        const url = $clicked.data("view_options_url");
        const name = $clicked[0].name;
        let value;
        if ($clicked.is(":checked")) {
          value = true;
        } else {
          value = false;
        }
        const form_data = new FormData();
        form_data.append(name, value);
        Helpers.post_form_data({
          url,
          form_data,
          success: (res) => {
            // console.log(res);
          },
          failure: (res) => {
            // console.error(res);
          },
        });
      }, 10);
    });
  }
});
