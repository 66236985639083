import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { initializeState as initializeScheduleData } from "../formSlice";
import { initializeState as initializeAjaxUrls } from "../ajaxUrlsSlice";
import InnerSchedulePage from "./InnerSchedulePage";

// This component is responsible for initializing the state of the form
function SchedulePageWrapper({ questions, scheduleIdentifier, uuid, action, ajaxUrls, formAuthenticityToken, exampleCSVPath, brandingSettings }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeScheduleData({ questions, isSchedule: true }));
    dispatch(initializeAjaxUrls(ajaxUrls));
  });

  return (
    <InnerSchedulePage
      uuid={uuid}
      action={action}
      formAuthenticityToken={formAuthenticityToken}
      scheduleIdentifier={scheduleIdentifier}
      exampleCSVPath={exampleCSVPath}
      brandingSettings={brandingSettings}
    />
  );
}

SchedulePageWrapper.propTypes = {
  uuid: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
  questions: PropTypes.object.isRequired,
  ajaxUrls: PropTypes.shape({
    dryRun: PropTypes.string.isRequired,
    deleteEntity: PropTypes.string.isRequired,
    fileUpload: PropTypes.string.isRequired,
  }).isRequired,
  scheduleIdentifier: PropTypes.string.isRequired,
  exampleCSVPath: PropTypes.string.isRequired,
  brandingSettings: PropTypes.object.isRequired,
};

export default SchedulePageWrapper;
