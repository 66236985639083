$(()=>{
  if (
    // set loading if all the following are true
    $('#fallback_journey_flow')[0] != undefined && // fallback journey flow page
    window.localStorage.getItem('pending_refresh') == 'true' && // pending refresh cookie
    $('#admin_app_flow_container .outstanding_item')[0] == undefined && // no outstanding items
    $('#journey_flow_chart_wrapper .stage.current')[0] != undefined) { // there is a current stage
    $('#admin_app_flow_container > .card').addClass('loading')
  } else {
    setTimeout(()=>{
      let current_stage = $('#journey_flow_chart_wrapper .stage.current')
      if (current_stage[0] != undefined) {
        current_stage.each((index, element)=> {
          let $element = $(element)
          $(element.parentElement).animate({scrollLeft: element.offsetLeft - element.offsetWidth * 2}, 1500)
        })
      }
    }, 100)
  }

})
