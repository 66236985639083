
class EsignPage {
  static send_esign_request(e) {
    let $form = $("#esign-form")
    let url = $form.attr('action');
    let $ajax_wrapper = $(e.currentTarget).closest('.ajax_wrapper')
    $ajax_wrapper.addClass('loading')

    Helpers.post_form_data({
      url: url,
      form_data: new FormData($form[0]),
      success: (res) => {
        console.log(res)
        $ajax_wrapper.html(res.partial)
        $ajax_wrapper.removeClass('loading')

        EsignPage.set_edit_and_cancel_delay()
      },
      failure: (res) => {
        console.error(res)

        $ajax_wrapper.removeClass('loading')
        let $submit_buttons = $form.find('button[type="submit"], input[type="submit"]')
        $submit_buttons.removeClass('disabled_with_spinner')
        $submit_buttons.prop('disabled', false)

        try {
          let response = res.responseJSON
          alert(response.error_message)
        } catch (error) {
          console.error(error)
          alert('Something went wrong. Please refresh the page and contact the Blitz tech team if the problem persists.')
        }
      }
    })
  }

  static edit_esign_request(e) {
    if (confirm("The current esign request will be cancelled and a new one will be sent. Are you sure you want to continue?")) {
      let $form = $("#edit-esign-form")
      let url = $form.attr('action');
      let $ajax_wrapper = $(e.currentTarget).closest('.ajax_wrapper')
      $ajax_wrapper.addClass('loading')

      Helpers.post_form_data({
        url: url,
        form_data: new FormData($form[0]),
        success: (res) => {
          console.log(res)
          $ajax_wrapper.html(res.partial)
          $ajax_wrapper.removeClass('loading')
        },
        failure: (res) => {
          console.error(res)
          $ajax_wrapper.removeClass('loading')

          try {
            let response = res.responseJSON
            alert(response.error_message)
          } catch (error) {
            console.error(error)
            alert('Something went wrong. Please refresh the page and contact the Blitz tech team if the problem persists.')
          }
        }
      })
    }
  }

  static set_edit_and_cancel_delay() {
    // Delay to prevent cancelling too early which might fail
    var wait_time = 5000;
    setTimeout(function() {
      if($("#edit_esign").length) {
        let $edit_esign_button = $("#edit_esign")
        $edit_esign_button.removeClass('loading')
      }

      if($("#cancel_esign").length) {
        let $cancel_esign_button = $("#cancel_esign")
        $cancel_esign_button.removeClass('loading')
      }
    }, wait_time);
  }
}

$(document).on('submit', '#esign-form', (e)=> {
  e.preventDefault()
  EsignPage.send_esign_request(e)
})

$(document).on('click', '#edit_esign', (e)=>{
  e.preventDefault()
  EsignPage.edit_esign_request(e)
})

$(document).on('click', '#esign_reminder0', (e)=>{
  $("#checkmark_icon_reminder0").removeClass("hidden");
  $("#checkmark_icon_reminder0").addClass("esign_reminder_button_img");
  $("#envelope_icon_reminder0").addClass("hidden");
})

$(document).on('click', '#esign_reminder1', (e)=>{
  $("#checkmark_icon_reminder1").removeClass("hidden");
  $("#checkmark_icon_reminder1").addClass("esign_reminder_button_img");
  $("#envelope_icon_reminder1").addClass("hidden");
})
$( function(){ if($("#edit_esign").length || $("#cancel_esign").length) {
  EsignPage.set_edit_and_cancel_delay()
} })