import React from "react";

function ErrorBoundaryDisplay({ ...props }) {
  return (
    <div className="error_boundary_display alert alert-danger">
      Something went wrong. Please try again or contact the Blitz Tech team if the problem persists.
    </div>
  );
}

export default ErrorBoundaryDisplay;
