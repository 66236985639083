
$(function() {

  $(document).on('click', '.click_to_show, .click_to_hide', function(e) {
    let $this = $(e.target)
    // console.log($this)
    // console.log(e.target)
    // console.log($(e.target) == $this)
    if ($this.hasClass('click_to_show') || $this.hasClass('click_to_hide')) {
      if (e.offsetX < 100 && e.offsetY < 24) {
        $this.toggleClass('click_to_show click_to_hide')
      }
    }
  })


});
