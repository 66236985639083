import Helpers from "../apply/helpers";

class BusinessIntelPortfolios {
  static update_pending_portfolios() {
    let $pending_portfolio_rows = $('#portfolios_table .portfolio_info_row.pending')
    if ($pending_portfolio_rows.length > 0) {
      let url = $('#business_intel_portfolios_wrapper').data('show_url')

      $pending_portfolio_rows.each((index, pending_portfolio_row) => {
        let $pending_portfolio_row = $(pending_portfolio_row)
        let uuid = $pending_portfolio_row.data('uuid')
        $.ajax({
          url: url,
          type: 'GET',
          data: { 'uuid': uuid },
          success: (res) => {
            $pending_portfolio_row.replaceWith(res)
            let $new_pending_portfolio_row = $(`#portfolios_table .portfolio_info_row[data-uuid='${uuid}']`)
            if (!$new_pending_portfolio_row.hasClass('pending')) {
              $new_pending_portfolio_row.fadeOut(500).fadeIn(500)
            }
          },
          error: (res) => {
          }
        })
      })
    }
  }

  static publish_portfolios(event) {
    event.stopPropagation()
    event.preventDefault()
    let $target = $(event.target)

    if ($target.prop("disabled")) {
      return
    }
    $target.prop("disabled", true)

    let url = $target.prop('href')

    $.ajax({
      url: url,
      type: 'POST',
      data: {},
      success: (res) => {
        $target.prop("disabled", false)
        $('#portfolios_table > tbody').prepend(res)
        $('#portfolios_table .portfolio_info_row').first().fadeOut(500).fadeIn(500)
      },
      error: (res) => {
        $target.prop("disabled", false)
      }
    })
  }
}

$(() => {
  setInterval(()=>{
    BusinessIntelPortfolios.update_pending_portfolios()
  }, 60000)

  $('#publish_business_intel_portfolios').on('click', (event)=>{
    BusinessIntelPortfolios.publish_portfolios(event)
  })
})
