$(document).ready(function() {
    $('#topic_selection').change(function() {
        $('#producer_send_question_button').prop('disabled', !$(this).val());
    });
});

function updateQuestionBoxText() {
    var windowWidth = $(window).width();
    var questionLabel = $("#producer_send_question_label");
    var questionButton = $("#producer_send_question_button");

    if ($('.alert.alert-info:contains("Question Sent to Underwriter")').length > 0 && questionLabel.text() !== 'Email Sent!') {
      questionLabel.text("Email Sent!");
      setTimeout(function() {
        updateQuestionBoxText();
      }, 10000);
      return;
    }

    if(windowWidth < 1100){
        questionLabel.text("Question?").fadeIn("slow");
        questionButton.val("Send");
    }
    else {
        if(questionLabel.text() == "Question?" || questionLabel.text() == "Email Sent!"){
            questionLabel.text("Have a Question?").fadeIn("slow");
        }
        questionButton.val("Send Question");
    }
}

$(document).scroll(function() {
    checkOffset();
  });

  function checkOffset() {
    if(!$('#producer_send_questions_window').length || !$('#portal_footer').length) {return;}
    if($('#producer_send_questions_window').offset().top + 3*$('#producer_send_questions_window').height() >= $('#portal_footer').offset().top)
      {
        $('#producer_send_questions_window').css('bottom', '15%');
      }
    if($(document).scrollTop() + window.innerHeight < $('#portal_footer').offset().top)
      {
        $('#producer_send_questions_window').css('bottom', '3%');
      }
  }

window.addEventListener('resize', updateQuestionBoxText);
window.addEventListener('load', updateQuestionBoxText);