
class CheckoutPage {
  static handle_coverage_fold(e) { // TODO - delete
    let $clicked = $(e.target)
    let coverage_identifier = $clicked.data('coverage_identifier')
    if ($clicked.is(':checked')) {
      $(`#${coverage_identifier}_entities .cell_contents_wrapper`).slideUp()
      setTimeout(()=>{
        $(`#${coverage_identifier}_entities`).addClass('folded')
      }, 300)

      $(`#${coverage_identifier}`).addClass('folded')
    } else {
      $(`#${coverage_identifier}_entities`).removeClass('folded')
      setTimeout(()=>{
        $(`#${coverage_identifier}_entities .cell_contents_wrapper`).slideDown()
      }, 200)
      $(`#${coverage_identifier}`).removeClass('folded')
    }
  }


  static handle_effective_date_update(e) {
    let $input_date_picker = $(e.target)
    let value = $input_date_picker.val();
    let original_value = $input_date_picker.data('requested_effective_date')
    let url = $input_date_picker.data('update_effective_date_url')

    let form_data = new FormData()
    form_data.append('requested_effective_date', value)

    let $loading_container = $('#checkout_summary_form')
    $loading_container.addClass('loading')

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        $loading_container.removeClass('loading')

        $input_date_picker.data('requested_effective_date', value)
        $('#bind_button').data('effective_date', value)
      },
      failure: (res) => {
        $loading_container.removeClass('loading')

        try {
          let body = JSON.parse(res.responseText)
          alert(body.error_message)
        } catch {
          alert("Something went wrong. Please refresh the page and contact the Blitz tech team if problem persists.")
        }

        $input_date_picker.val(original_value);
      }
    })
  }

  static handle_nested_item_click(e) {
    // console.log('triggered handle nested item click')
    e.preventDefault()

    let $clicked = $(e.currentTarget)
    let $target_item_content = $($clicked.data('slider_target'))
    if ($target_item_content[0] == undefined) { return }

    let $current_content = $($clicked.data('slider_parent'))
    let movement = $target_item_content.css('left')
    $current_content.removeClass('slider_current')
    $current_content.animate({
      'left': `-${movement}`
    }, ()=>{
      $current_content.addClass('slider_left')
    })
    $target_item_content.animate({
      'left': '0px'
    }, ()=>{
      $target_item_content.removeClass('slider_right')
      $target_item_content.addClass('slider_current')
    })

  }

  static handle_slider_back_click(e) {
    // console.log('triggered handle slider back click')

    let $clicked = $(e.currentTarget)
    let $target_item_content = $($clicked.data('slider_target'))

    if ($target_item_content[0] == undefined) { return }

    let $current_content = $clicked.closest('.modal-content')
    let movement = $target_item_content.css('left').replace('-', '')
    $current_content.removeClass('slider_current')
    $current_content.animate({
      'left': `${movement}`
    }, ()=>{
      $current_content.addClass('slider_right')
    })
    $target_item_content.animate({
      'left': '0px'
    }, ()=>{
      $target_item_content.addClass('slider_current')
      $target_item_content.removeClass('slider_left')
    })
  }

  static handle_nested_item_modal_close(e) {
    let $modal = $(e.target)
    let $slider_wrapper = $modal.find('.slider_wrapper')
    let primary_content_id = $slider_wrapper.data('primary')
    let $primary_content = $(primary_content_id)
    if ($primary_content.hasClass('slider_current')) {
      // primary content is already current
      return
    } else {
      // need to slide out current content and replace primary
      let $current_content = $slider_wrapper.find('.slider_current')
      let movement = $primary_content.css('left').replace('-', '')
      $current_content.removeClass('slider_current')
      $current_content.animate({
        'left': `${movement}`
      }, ()=>{
        $current_content.addClass('slider_right')
      })
      $primary_content.animate({
        'left': '0px'
      }, ()=>{
        $primary_content.addClass('slider_current')
        $primary_content.removeClass('slider_left')
      })
    }
  }
}

$(()=>{

  $(document).on('click', 'a.item_block.not_included', (e)=>{
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
  })
  $(document).on('click', '#checkout_wrapper #checkout_table label.fold_button input', CheckoutPage.handle_coverage_fold) // TODO - delete


  $(document).on('change', '#checkout_summary_form .effective_date_selector input', CheckoutPage.handle_effective_date_update)

  // disable click of the download quote proposal button while loading
  $(document).on('click', '#checkout_wrapper #download_quote_proposal_button.loading', (e)=> { // TODO - delete
    e.preventDefault()
    e.stopPropagation()
  })
  $(document).on('click', '#checkout #download_quote_proposal_button.loading', (e)=> {
    e.preventDefault()
    e.stopPropagation()
  })

  $(document).on('click', '#checkout_wrapper #bind_button', (e)=> {
    if ($('#bind_button').hasClass('loading')) {
      e.preventDefault()
      e.stopPropagation()
    }
  })

  $(document).on('click', 'a[data-slider_toggle="slider"]', CheckoutPage.handle_nested_item_click)
  $(document).on('click', 'button[data-slider_toggle="slider"]', CheckoutPage.handle_slider_back_click)
  $('.modal.with_nested_items').on('hidden.bs.modal', CheckoutPage.handle_nested_item_modal_close)

  if($("#download_quote_proposal_button").length) {
    // Quote proposal generation gets kicked off in the bakgroud on a previous stage. We'll restart it if it hasn't completed
    var quote_propal_wait_time = 60000;
    const generate_quote_proposal = (url) => {
      const req = $.ajax({
        url: url,
        data: null,
        type: 'POST',
        dataType: 'json',
      })

      req.then(function (response) {
        console.log(response)
      }).catch(function (error) {
        console.error(error)
        alert("Something went wrong. Please refresh the page and try again. Contact the Blitz Tech Team if the problem persists.");
      })
    }

    setTimeout(function() {
      // Look for the download button with a loading state, if it is loading still call the generate quote API to rerun in.
      let $quote_download_button = $("#download_quote_proposal_button")
      if($quote_download_button.hasClass('loading')) {
        generate_quote_proposal_url = $quote_download_button.data('generate_quote_proposal_url')
        generate_quote_proposal(generate_quote_proposal_url)
      }
    }, quote_propal_wait_time);
  }
})