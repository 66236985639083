import Helpers from './helpers.js'

class TaxesAndFeesPage {
  static handle_summary_fold(e) {
    let $clicked = $(e.target)
    let product_identifier = $clicked.data('product_identifier')
    if ($clicked.is(':checked')) {
      $(`#premium_summary_${product_identifier}_coverages`).slideUp()
    } else {
      $(`#premium_summary_${product_identifier}_coverages`).slideDown()
    }
  }

  static handle_add_fee(e) {
    var $clone =  $('#fee-form-row-template')
      .clone()
    
    $clone.toggleClass('hidden')
      .prop('id', 'fee-form-row')
      .appendTo('#fees')
    
    $clone.find('input').prop('required',true)
  }

  static handle_remove_fee(e) {
    $(e.target).closest('#fee-form-row').remove()
  }

  static handle_add_tax(e) {
    var $clone = $('#tax-form-row-template')
      .clone()

    $clone.toggleClass('hidden')
      .prop('id', 'tax-form-row')
      .appendTo('#taxes')

    $clone.find('input').prop('required',true)
    $clone.find('select').prop('required',true)
  }

  static handle_remove_tax(e) {
    $(e.target).closest('#tax-form-row').remove()
  }
}

$(()=>{
  $(document).on('click', '.taxes_and_fees_wrapper #taxes_and_fees label.fold_button input', TaxesAndFeesPage.handle_summary_fold)

  $(document).on('click', '.taxes_and_fees_wrapper #fees_header_wrapper .add_button_wrapper', TaxesAndFeesPage.handle_add_fee)
  $(document).on('click', '.taxes_and_fees_wrapper #fees .remove_button', TaxesAndFeesPage.handle_remove_fee)

  $(document).on('click', '.taxes_and_fees_wrapper #taxes_header_wrapper .add_button_wrapper', TaxesAndFeesPage.handle_add_tax)
  $(document).on('click', '.taxes_and_fees_wrapper #taxes .remove_button', TaxesAndFeesPage.handle_remove_tax)
})
