class Helpers {
  static post_form_data({ url, form_data, success, failure } = {}) {
    // console.log("Posting form data")
    // for ( var pair of form_data.entries()) {
    //   console.log(pair[0], pair[1])
    // }
    $.ajax({
      url: url,
      type: 'POST',
      dataType: "JSON",
      data: form_data,
      processData: false,
      contentType: false,
      success: success,
      error: failure,
    })
  }

  static post_empty_form({ url, success, failure } = {}) {
    Helpers.post_form_data({url: url, form_data: "", success: success, failure: failure})
  }

  static formatNumber(n) {
    // format number 1234567 to 1,234,567
    let intString = Helpers.string_to_int(n).toString()
    if (intString == "NaN") { return "0" }
    return intString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  static formatDollars($input) {
    var input_val = $input.val();

    if (input_val === "") { return; }

    var original_len = input_val.length;
    var caret_pos = $input.prop("selectionStart");

    // add commas to number
    // remove all non-digits
    input_val = this.formatNumber(input_val);

    input_val = "$" + input_val;

    $input.val(input_val);

    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    if (caret_pos < 1) { caret_pos = 1 }
    if ($input.is(':focus')) {
      $input[0].setSelectionRange(caret_pos, caret_pos);
    }
  }

  static string_to_int(n) {
    return parseInt(n.replace(/[^\d\.]/g, ""))
  }

  static enforce_min_max(element) {
    if(element.value != ""){
      if ($(element).hasClass('currency')) {
        if(parseInt(element.value.replace(/\D/g, "")) < parseInt(element.min)){
          element.value = parseInt(element.min);
          this.formatDollars($(element));
        }
        if(parseInt(element.value.replace(/\D/g, "")) > parseInt(element.max)){
          element.value = parseInt(element.max);
          this.formatDollars($(element));
        }
      } else {
        if(parseInt(element.value) < parseInt(element.min)){
          element.value = parseInt(element.min);
        }
        if(parseInt(element.value) > parseInt(element.max)){
          element.value = parseInt(element.max);
        }
      }

    }
  }


}

export default Helpers


