/* global $ */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Honeybadger } from "@honeybadger-io/react";

import Questions from "../../Shared/StatefulComponents/Questions";
import GlobalErrorDisplay from "../../Shared/StatefulComponents/GlobalErrorDisplay";
import { updateField, ajaxUpdate, startLoading, stopLoading, removeEntry, ajaxDeleteEntity, addEntry } from "../formSlice";
import { FormContext } from "../../Shared/StatelessComponents/FormContext";

// This is the react component that renders the form
// It is responsible for rendering the questions and the global error display
// as well as handling the dynamic scrolling and loading behavior
function Form({ uuid, action, formAuthenticityToken }) {
  const isLoading = useSelector((state) => state.form.isLoading);
  const justClickedSubmit = useSelector(
    (state) => state.form.justClickedSubmit,
  );
  const addedNewEntry = useSelector((state) => state.form.addedNewEntry);
  const [showSpinner, setShowSpinner] = useState(false);
  const [submitScrollRefs, setSubmitScrollRefs] = useState([]);
  const [newEntryScrollRefs, setNewEntryScrollRefs] = useState([]);
  const [acknowledgementBlockers, setAcknowledgementBlockers] = useState([]);

  const formRef = useRef(null);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setShowSpinner(true);
      }, 350); // Show after a delay to avoid flickering
    } else {
      clearTimeout(timer);
      setShowSpinner(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isLoading]);

  const scrollToElement = (element) => {
    const offset = 200;

    const rect = element.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop;
    const targetPosition = rect.top + scrollTop - offset;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (submitScrollRefs.length > 0 && justClickedSubmit) {
      if (submitScrollRefs[0].current) {
        scrollToElement(submitScrollRefs[0].current);
        setSubmitScrollRefs([]);
      }
    }
  }, [justClickedSubmit, submitScrollRefs]);

  useEffect(() => {
    if (newEntryScrollRefs.length > 0 && addedNewEntry) {
      if (newEntryScrollRefs[newEntryScrollRefs.length - 1].current) {
        scrollToElement(
          newEntryScrollRefs[newEntryScrollRefs.length - 1].current,
        );
      }
    }
  }, [addedNewEntry, newEntryScrollRefs]);

  function getAllItemsFromLocalStorage() {
    const allItems = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      allItems[key] = value;
    }
    return allItems;
  }

  const handleSubmit = async (e) => {
    if (acknowledgementBlockers.some((key) => {
      return localStorage.getItem(key) === "true";
    })) {
      e.preventDefault();

      const allLocalStorageItems = getAllItemsFromLocalStorage();
      Honeybadger.notify("User tried to submit form without acknowledging all disclaimers", { context: { acknowledgementBlockers, allLocalStorageItems } });
      alert("Please acknowledge all the disclaimer(s) below before submitting the form.");

      setTimeout(() => {
        console.log("removing spinner");
        // all this is here to undo the jquery logic in the helpers module
        const $form = $(e.target);

        const $submit_buttons = $form.find("button[type=\"submit\"], input[type=\"submit\"]");

        $submit_buttons.removeClass("disabled_with_spinner");
        $submit_buttons.prop("disabled", false);
        $(".disable_on_submits").removeClass("disabled");
      }, 1);

      return false;
    }
  };

  const registerSubmitScrollRef = (scrollRef) => {
    setSubmitScrollRefs((prev) => [...prev, scrollRef]);
  };

  const registerNewEntryScrollRef = (scrollRef) => {
    setNewEntryScrollRefs([scrollRef]);
  };

  const registerAcknowledgement = (acknowledgement) => {
    if (acknowledgementBlockers.includes(acknowledgement)) {
      return;
    }
    setAcknowledgementBlockers((prev) => [...prev, acknowledgement]);
  };

  return (
    <FormContext.Provider
      value={{ formRef, registerSubmitScrollRef, registerNewEntryScrollRef, registerAcknowledgement, updateField, ajaxUpdate, startLoading, stopLoading, removeEntry, ajaxDeleteEntity, addEntry, uuid }}
    >
      <form
        ref={formRef}
        id="submissions_data_form"
        className={`form ${showSpinner ? "loading" : ""}`}
        action={action}
        method="post"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <input name="uuid" type="hidden" value={uuid} />
        <input
          name="authenticity_token"
          type="hidden"
          value={formAuthenticityToken}
        />
        <GlobalErrorDisplay />
        <Questions />

        <div className="submit_row gx-0 my-2 text-end">
          <button
            id="submission-data-submit-button"
            className="btn btn-primary orange_button"
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
    </FormContext.Provider>
  );
}

Form.propTypes = {
  uuid: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
};

export default Form;
