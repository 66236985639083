import Helpers from "../apply/helpers";

class QuoteNotTakenHelper {
  static display_initial_fields(e) {
    let reasons_with_qnt_info_needed = $(e.target).data("reasons_with_qnt_info_needed").split(",")

    var quote_not_taken_header = $("#quoteNotTakenHeader")
    var quote_not_taken_dropdown = $("#quoteNotTakenReason")
    var quote_not_taken_elements = [quote_not_taken_header, quote_not_taken_dropdown]

    var carrier_dropdown = $("#competitorCarrier")
    var premium_text_field = $("#competitorPremium")
    var coverage_dropdown = $("#competitorCoverage")
    const other_competitor_carrier = $("#otherCompetitorCarrier")
    const other_competitor_coverage = $("#otherCompetitorCoverage")
    var missing_coverage_dropdown = $("#missingCoverage")

    // First, hide everything
    quote_not_taken_header.hide()
    quote_not_taken_dropdown.hide()

    carrier_dropdown.hide()
    premium_text_field.hide()
    coverage_dropdown.hide()
    other_competitor_carrier.hide()
    other_competitor_coverage.hide()
    missing_coverage_dropdown.hide()

    // Then, reset drop downs and other carrier/coverage text fields
    $("#competitor_carrier_dropdown").prop('selectedIndex',0)
    $("#competitor_coverage_dropdown").prop('selectedIndex',0)
    $("#missing_coverage_dropdown").prop('selectedIndex', 0)
    $("#other_carrier_field").val('')
    $("#other_coverage_field").val('')
    $("#competitor_premium_field").val('')

    if ($.inArray(this.value, reasons_with_qnt_info_needed) > -1){
      for (let i = 0; i < quote_not_taken_elements.length; i++) quote_not_taken_elements[i].show();
    }
  }

  static display_further_fields(e) {
    let reasons_with_premium_info_needed = $(e.target).data("reasons_with_premium_info_needed").split(",")
    let reasons_with_coverage_info_needed = $(e.target).data("reasons_with_coverage_info_needed").split(",")
    let reasons_with_missing_coverage_info_needed = $(e.target).data("reasons_with_missing_coverage_info_needed").split(",")

    var carrier_dropdown = $("#competitorCarrier")
    var premium_text_field = $("#competitorPremium")
    var coverage_dropdown = $("#competitorCoverage")
    const other_competitor_carrier = $("#otherCompetitorCarrier")
    const other_competitor_coverage = $("#otherCompetitorCoverage")
    var missing_coverage_dropdown = $("#missingCoverage")
    var premium_info_elements = [carrier_dropdown, premium_text_field]
    var coverage_info_elements = [carrier_dropdown, coverage_dropdown]
    var missing_coverage_info_elements = [carrier_dropdown, missing_coverage_dropdown]

    // First, hide everything
    carrier_dropdown.hide()
    premium_text_field.hide()
    coverage_dropdown.hide()
    other_competitor_carrier.hide()
    other_competitor_coverage.hide()
    missing_coverage_dropdown.hide()

    // Then, reset drop downs and other carrier/coverage text fields
    $("#competitor_carrier_dropdown").prop('selectedIndex',0)
    $("#competitor_coverage_dropdown").prop('selectedIndex',0)
    $("#missing_coverage_dropdown").prop('selectedIndex', 0)
    $("#other_carrier_field").val('')
    $("#other_coverage_field").val('')
    $("#competitor_premium_field").val('')

    // Then, show just the fields that need to be displayed
    if($.inArray(this.value, reasons_with_premium_info_needed) > -1) {
      for(let i = 0; i < premium_info_elements.length; i++) premium_info_elements[i].show();
    }

    if($.inArray(this.value, reasons_with_coverage_info_needed) > -1) {
      for(let i = 0; i < coverage_info_elements.length; i++) coverage_info_elements[i].show();
    }

    if($.inArray(this.value, reasons_with_missing_coverage_info_needed) > -1) {
      for (let i = 0; i < missing_coverage_info_elements.length; i++) missing_coverage_info_elements[i].show();
    }

  }

  static other_carrier_selected(e) {
    if(this.value === 'Other') {
      $("#otherCompetitorCarrier").show()
    } else {
      $("#otherCompetitorCarrier").hide()
    }
  }

  static other_coverage_selected(e) {
    if(this.value === 'Other') {
      $("#otherCompetitorCoverage").show()
    } else {
      $("#otherCompetitorCoverage").hide()
    }
  }

  static submit_form(e) {
    e.preventDefault();
    $(e.target).prop('disabled', true); // Disable the button after it is clicked

    let $form = $('#quote_not_taken_form')
    let url = $form.data('url')
    let form_data = new FormData($form[0])

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        $("#quote_not_taken_submission_section").remove()
      },
      failure: (res) => {
        window.Honeybadger.notify(`Could not successfully submit quote not taken reason! ${res} -- ${url} -- ${form_data}`)
        console.log(`Could not successfully submit quote not taken reason!`)
      }
    })
  }
}

$(() => {
  /*if($("#quote_not_taken_submission_section").length === 0) {
    // Do nothing if the quote not taken section isn't available
    return
  }*/
  $("#cancellation_reason_dropdown").on('change', QuoteNotTakenHelper.display_initial_fields)
  $("#quote_not_taken_select_dropdown").on('change', QuoteNotTakenHelper.display_further_fields)
  $("#competitor_carrier_dropdown").on('change', QuoteNotTakenHelper.other_carrier_selected)
  $("#competitor_coverage_dropdown").on('change', QuoteNotTakenHelper.other_coverage_selected)
  $("#quote_not_taken_submit_button").on('click', QuoteNotTakenHelper.submit_form)
})
