import React, { useState, useEffect, useRef } from "react";
import * as SmartySDK from "smartystreets-javascript-sdk";
import AddressSuggestion from "../StatelessComponents/AddressSuggestion";

// const websiteKey = process.env.SMARTY_STREETS_WEBSITE_KEY;
// we need to figure out how we want to handle FE env vars
// we can't just use a geneic heroku config -> process.env approach
// because then anyone would be able to see all our env vars
const websiteKey = "119339475782419791";

export const useSmartyStreets = ({ initialAddress1Value, currentAddress1Value, callbackOnSelection }) => {
  const SmartyCore = SmartySDK.core;
  const smartySharedCredentials = new SmartyCore.SharedCredentials(websiteKey);
  const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(smartySharedCredentials).withLicenses(["us-autocomplete-pro-cloud"]);
  const autoCompleteClient = autoCompleteClientBuilder.buildUsAutocompleteProClient();
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(-1);
  const [prevAddress1Value, setPrevAddress1Value] = useState(initialAddress1Value);
  const [prevQuery, setPrevQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const address1InputRef = useRef(null);

  const timer = useRef(null);

  const queryAutocompleteForSuggestions = (query, hasSecondaries = false) => {
    if (timer.current) {
      clearTimeout(timer.current); // cancel pending query if any
    }
    let timeoutLength = 300;
    if (query.length < 3) {
      // don't query if we have less than 3 characters
      // but set the prev query so we don't get stuck
      setPrevQuery(query);
      return;
    }
    if (query === prevQuery) { return; } // don't requery if query is the same as last one
    if (query === prevQuery.slice(0, -1)) { timeoutLength = 500; } // use a longer debounce delay if we're just deleting a character

    timer.current = setTimeout(() => {
      const lookup = new SmartySDK.usAutocompletePro.Lookup(query);

      if (hasSecondaries) {
        lookup.selected = query;
      }
      autoCompleteClient.send(lookup)
        .then(res => {
          setPrevQuery(query);
          setSuggestions(res.result);
        })
        .catch(console.warn);
    }, timeoutLength); // delay before sending request
  };
  const formatAutocompleteSuggestion = (suggestion) => {
    const street = suggestion.streetLine ? `${suggestion.streetLine} ` : "";
    const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : "";
    const entries = suggestion?.entries !== 0 ? `(${suggestion.entries}) ` : "";
    const city = suggestion?.city ? `${suggestion.city} ` : "";
    const state = suggestion?.state ? `${suggestion.state}, ` : "";
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : "";

    return street + secondary + entries + city + state + zip;
  };

  const selectSuggestion = (suggestion) => {
    // Triggered on keydown, so make sure to keep focus on the input
    address1InputRef.current.focus();
    if (suggestion.entries > 1) {
      // get nested suggestions
      queryAutocompleteForSuggestions(formatAutocompleteSuggestion(suggestion), true);
      setFocusedSuggestionIndex(-1);
      setTimeout(() => {
        setFocusedSuggestionIndex(0);
        address1InputRef.current.focus();
      }, 0);
    } else {
      // grab selection data and update state
      const address1 = suggestion.streetLine ? suggestion.streetLine : "";
      const address2 = suggestion?.secondary ? suggestion.secondary : "";
      const city = suggestion?.city ? suggestion.city : "";
      const state = suggestion?.state ? suggestion.state : "";
      const zip = suggestion?.zipcode ? suggestion.zipcode : "";

      setPrevAddress1Value(address1);
      setSuggestions([]);
      // call the selection callback with the selected suggestion data
      callbackOnSelection({
        address1, address2, city, state, zip,
      });
      setTimeout(() => {
        // keep the focus on the input after the rerender from the state updates
        address1InputRef.current.focus();
      }, 0);
    }
  };

  // allow for keyboard navigation of suggestions
  const handleKeyDown = (event) => {
    if (["Tab", "ArrowDown", "ArrowUp", "Enter"].includes(event.key)) {
      if (suggestions.length === 0) { return; } // don't do anything if there are no suggestions

      event.preventDefault();
    }

    if (event.key === "ArrowUp" || (event.key === "Tab" && event.shiftKey)) {
      // Move focus up
      setFocusedSuggestionIndex((prev) => Math.max(prev - 1, -1));
    } else if (event.key === "Tab" || event.key === "ArrowDown") {
      // Move focus down
      setFocusedSuggestionIndex((prev) => Math.min(prev + 1, suggestions.length - 1));
    } else if (event.key === "Enter" && focusedSuggestionIndex >= 0) {
      // Select the focused suggestion
      selectSuggestion(suggestions[focusedSuggestionIndex]);
    } else if (event.key === "Escape") {
      // Clear suggestions
      setSuggestions([]);
    }
  };

  // do this whenever suggestions change
  useEffect(() => {
    // attach the event listener for suggestion keyboard navigation
    address1InputRef.current?.addEventListener("keydown", handleKeyDown);
    return () => {
      address1InputRef.current?.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedSuggestionIndex, suggestions]);

  // do this whenever the value of the address 1 field changes
  useEffect(() => {
    // get new suggestions if the address 1 value has changed
    if (address1InputRef.current === document.activeElement && currentAddress1Value !== prevAddress1Value) {
      queryAutocompleteForSuggestions(currentAddress1Value.trimEnd());
    }
  }, [currentAddress1Value]);

  const renderedSuggestions = (
    <div className="suggestions_wrapper" style={suggestions.length === 0 ? { display: "none" } : null}>
      <div className="close_button" onClick={() => setSuggestions([])}>x</div>
      <div className="suggestions">
        {suggestions.map((suggestion, index) => (
          <AddressSuggestion key={index} suggestion={suggestion} selectSuggestion={selectSuggestion} isFocused={index === focusedSuggestionIndex} />
        ))}
      </div>
    </div>
  );

  return {
    address1InputRef,
    renderedSuggestions,
  };
};
