import { useState, useEffect } from "react";

export const usePersistentState = (key, initialValue) => {
  if (!key.startsWith("react.")) {
    throw new Error("usePersistentState key must begin with namespace 'react.'");
  }
  // Initialize state with data from local storage or the initial value
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return (storedValue !== null && storedValue !== undefined) ? JSON.parse(storedValue) : initialValue;
  });

  // Function to update local state based on local storage
  const updateValueFromLocalStorage = () => {
    const storedValue = localStorage.getItem(key);
    setValue(storedValue !== null ? JSON.parse(storedValue) : initialValue);
  };

  // Update local storage and emit an event to sync other components
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new CustomEvent("storageChange", { detail: { key, value } }));
  }, [key, value]);

  // Listen for custom event to sync state across components
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.detail.key === key) {
        updateValueFromLocalStorage();
      }
    };

    window.addEventListener("storageChange", handleStorageChange);
    return () => {
      window.removeEventListener("storageChange", handleStorageChange);
    };
  }, [key]);

  return [value, setValue];
};
