import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ForeignEntitySelectInput = ({
  question,
  questionName,
  hasFieldError,
  questionIdentifier,
  onChange,
  onBlur,
  value,
}) => {
  const foreignEntityType = question.foreign_entity_question_identifier;
  const foreignEntityQuestion = useSelector((state) => {
    return state.form.questions[foreignEntityType];
  });
  const availableUUIDs = foreignEntityQuestion.groups.map((v) => v.entity_identifier.value);
  const generateEntityDisplay = (uuid) => {
    let formattedString = question.display_template;

    const entity = foreignEntityQuestion.groups.find(e => e.entity_identifier.value === uuid);
    for (const [k, v] of Object.entries(entity)) {
      formattedString = formattedString.replace(
        new RegExp(`:${k}`, "g"),
        String(v.value),
      );
    }
    return formattedString;
  };

  const options = availableUUIDs.reduce((acc, uuid) => {
    acc.push([uuid, generateEntityDisplay(uuid)]);
    return acc;
  }, []);

  const handleChange = (event) => {
    onChange(event.target.value);
    onBlur(event.target.value);
  };

  const selectedValue = availableUUIDs.includes(value) ? value : "";

  return (
    <select
      className="form-select question_block_select"
      style={hasFieldError ? { border: "1px solid red" } : {}}
      name={questionName}
      aria-label={`for_${questionIdentifier}`}
      disabled={question.locked}
      onChange={handleChange}
      value={selectedValue || ""}
    >
      <option value="">Select an Option</option>
      {options.map(([optionValue, optionLabel]) => (
        <option key={optionValue} value={optionValue}>
          {optionLabel}
        </option>
      ))}
    </select>
  );
};

ForeignEntitySelectInput.propTypes = {
  question: PropTypes.shape({
    locked: PropTypes.bool,
    foreign_entity_question_identifier: PropTypes.string.isRequired,
    display_template: PropTypes.string.isRequired,
  }).isRequired,
  questionName: PropTypes.string.isRequired,
  hasFieldError: PropTypes.bool,
  questionIdentifier: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ForeignEntitySelectInput.defaultProps = {
  hasFieldError: false,
  value: "",
};

export default ForeignEntitySelectInput;
