/* global $ */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import questionMarkImage from "../../../images/question_mark.svg";

// This component renders the label for an individual question
function QuestionLabel({ fieldType, question, hasFieldError }) {
  if (fieldType === "attestation") return null;
  if (fieldType === "entity_identifier") return null;

  if (question.label === undefined || question.label === null) throw new Error("question.label is undefined");

  useEffect(() => {
    $("[data-bs-toggle=\"tooltip\"]").tooltip();
  }, []);

  return (
    <label
      style={hasFieldError ? { color: "red" } : {}}
      className={`question_block_label ${fieldType}`}
    >
      {question.label}
      {question.tooltip && (
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={question.tooltip}
          style={{ display: "inline", width: "40px" }}
        >
          <img
            src={questionMarkImage}
            alt="Tooltip icon"
            style={{ width: "20px", marginLeft: "5px" }}
          />
        </div>
      )}
    </label>
  );
}

QuestionLabel.propTypes = {
  fieldType: PropTypes.string.isRequired,
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    errors: PropTypes.array,
  }).isRequired,
  hasFieldError: PropTypes.bool.isRequired,
};

export default QuestionLabel;
