import React from "react";
import PropTypes from "prop-types";

function HiddenInput({ questionName, value }) {
  return (
    <input
      type="hidden"
      name={questionName}
      value={value}
    />
  );
}

HiddenInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default HiddenInput;
