import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// This component is responsible for initializing the state of the form
function FormWrapper({ data, ajaxUrls, initializeQuestions, initializeAjaxUrls, ...props }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeQuestions(data));
    dispatch(initializeAjaxUrls(ajaxUrls));
  });

  return (
    <>
      {props.children}
    </>
  );
}

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  initializeQuestions: PropTypes.func.isRequired,
  initializeAjaxUrls: PropTypes.func.isRequired,
  data: PropTypes.shape({
    questions: PropTypes.object.isRequired,
  }).isRequired,
  ajaxUrls: PropTypes.shape({
    dryRun: PropTypes.string.isRequired,
    deleteEntity: PropTypes.string,
    multiFileUpload: PropTypes.string,
    multiFileDelete: PropTypes.string,
  }).isRequired,
};

export default FormWrapper;
