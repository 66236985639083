import React from "react";
import PropTypes from "prop-types";

function NumberInput({
  fieldType,
  value,
  questionName,
  hasFieldError,
  question,
  step,
  describerId,
  onChange,
  onBlur,
}) {
  const handleInput = (event) => {
    if (!event.target.validity.valid) {
      onChange("");
    }
  };

  return (
    <input
      className={`form-control question_block_input ${fieldType}`}
      type="number"
      name={questionName}
      style={hasFieldError ? { border: "1px solid red" } : {}}
      value={value || ""}
      placeholder={question.placeholder}
      step={step}
      min="0"
      onWheel={() => document.activeElement.blur()}
      onInput={handleInput}
      onChange={e => onChange(e.target.value)}
      onBlur={e => onBlur(e.target.value)}
      disabled={question.locked}
      aria-describedby={describerId}
    />
  );
}

NumberInput.propTypes = {
  fieldType: PropTypes.string,
  questionName: PropTypes.string.isRequired,
  hasFieldError: PropTypes.bool,
  question: PropTypes.shape({
    placeholder: PropTypes.string,
    locked: PropTypes.bool,
  }).isRequired,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  describerId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NumberInput.defaultProps = {
  hasFieldError: false,
  step: "1",
  fieldType: "",
  value: "",
};

export default NumberInput;
