import React from "react";
import PropTypes from "prop-types";

function BooleanToggleInput({ questionName, value, onChange, onBlur }) {
  const handleUpdate = (e) => {
    onChange(JSON.parse(e.target.value));
    setTimeout(() => {
      onBlur(e.target.value); // trigger a blur on change because there is no blur normally
    }, 0);
  };

  return (
    <span className="boolean_toggle">
      <span className="toggle_background"></span>

      <label className="left toggle_label">
        <input
          type="radio"
          name={questionName}
          value="true"
          checked={JSON.parse(value) === true}
          onChange={handleUpdate}
        />
        <span className="slider"></span>
        Yes
      </label>

      <label className="right toggle_label">
        <input
          type="radio"
          name={questionName}
          value="false"
          checked={JSON.parse(value) === false}
          onChange={handleUpdate}
        />
        <span className="slider"></span>
        No
      </label>
    </span>
  );
}

BooleanToggleInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

BooleanToggleInput.defaultProps = {
  value: null,
};

export default BooleanToggleInput;
