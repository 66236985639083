$(function() {
  const $elem = $('#copy_digital_application_button')

  if ($elem.length > 0) {
    $elem.popover({
      placement: 'auto',
      trigger: 'hover',
      html: true,
      container: $elem,
      animation: true,
      title: "What's this?",
      content: "Send this link along to another user for them to start an application that is automatically tied to your account! They won't be able to see pricing, but should have an easier time completing the required questions to quote. Curious what it looks like? Try it out on our testing site <a href='https://app.blitz-staging.com/digital/e98c1f9d-5de0-48da-903a-f240124a92b4' target='_blank'>here</a>."
    })

    $elem.on('click', event => {
      navigator.clipboard.writeText($elem.val())
      $('#copy_digital_application_button .copy_icon').addClass('hidden')
      $('#copy_digital_application_button .checkmark_icon').removeClass('hidden')
      setTimeout(()=>{
        $('#copy_digital_application_button .copy_icon').removeClass('hidden')
        $('#copy_digital_application_button .checkmark_icon').addClass('hidden')
      }, 3000)
    })
  }
});

$(function() {
  const $elem = $('#copy_partner_resource_library_button')

  if ($elem.length > 0) {
    $elem.on('click', event => {
      navigator.clipboard.writeText($elem.val())
      $('#copy_partner_resource_library_button .copy_icon').addClass('hidden')
      $('#copy_partner_resource_library_button .checkmark_icon').removeClass('hidden')
      setTimeout(()=>{
        $('#copy_partner_resource_library_button .copy_icon').removeClass('hidden')
        $('#copy_partner_resource_library_button .checkmark_icon').addClass('hidden')
      }, 3000)
    })
  }
});

$(function() {
  const $elem = $('#copy_producer_application_button')

  if ($elem.length > 0) {

    $elem.on('click', event => {
      navigator.clipboard.writeText($elem.val())
      $('#copy_producer_application_button .copy_icon').addClass('hidden')
      $('#copy_producer_application_button .checkmark_icon').removeClass('hidden')
      setTimeout(()=>{
        $('#copy_producer_application_button .copy_icon').removeClass('hidden')
        $('#copy_producer_application_button .checkmark_icon').addClass('hidden')
      }, 3000)
    })
  }
});

$(function() {
  const $elem = $('#download_supplemental_application_portal_button')

  if ($elem.length > 0) {
    $elem.popover({
      placement: 'auto',
      trigger: 'hover',
      html: true,
      container: $elem,
      animation: true,
      title: "What's this?",
      content: "This PDF will include a list of all questions that you have to complete based on your current answers in order to request a quote.<br>As you continue through the digital application and enter more responses, the list of questions in the PDF will decrease"
    })
  }
});