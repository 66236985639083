import React from "react";
import { useSelector } from "react-redux";

// This component is responsible for displaying the global validations that
// are returned from the async submit responses

function GlobalErrorDisplay() {
  const errors = useSelector((state) => state.form.errors);

  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className="global_error_display">
      {errors.map((error, index) => (
        <div key={index} className="global_validation_wrapper row">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>
      ))}
    </div>
  );
}

export default GlobalErrorDisplay;
