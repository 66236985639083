import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import QuestionBlock from "./QuestionBlock";

// This component renders top level individual questions that are not part of a group

const IndividualQuestion = ({ question, questionIdentifier }) => {
  const isHidden =
    useSelector((state) => {
      return state.form.questions[questionIdentifier].hidden;
    }) || false;

  return (
    <CSSTransition in={!isHidden} timeout={300} classNames="fade" unmountOnExit>
      <div className="individual_question card">
        <QuestionBlock
          question={question}
          questionIdentifier={questionIdentifier}
          multiple={question.multiple_entries}
        />
      </div>
    </CSSTransition>
  );
};

IndividualQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  questionIdentifier: PropTypes.string.isRequired,
};

export default IndividualQuestion;
