import React from "react";
import { useSelector } from "react-redux";

import QuestionGroup from "./QuestionGroup";
import MultipleEntryQuestionGroup from "./MultipleEntryQuestionGroup";
import IndividualQuestion from "./IndividualQuestion";

// This component renders all top level questions, picking the appropriate component
// based on whether the question is a group, multiple entry group or individual

function Questions() {
  const questions = useSelector(state => state.form.questions);
  if (!questions) {
    return null;
  }

  return (
    <>
      {Object.entries(questions).map(([questionIdentifier, question]) => {
        if (question.keep === false) { return null; }
        if (question.question_group) {
          if (question.multiple_entries) {
            return (
              <MultipleEntryQuestionGroup
                key={questionIdentifier}
                question={question}
                questionIdentifier={questionIdentifier}
              />
            );
          } else {
            return (
              <QuestionGroup
                key={questionIdentifier}
                question={question}
                questionIdentifier={questionIdentifier}
              />
            );
          }
        } else {
          return (
            <IndividualQuestion
              key={questionIdentifier}
              question={question}
              questionIdentifier={questionIdentifier}
            />
          );
        }
      })}
    </>
  );
}

export default Questions;
