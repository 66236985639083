import React from "react";
import PropTypes from "prop-types";

// There's probably no reason to ever use a file type instead of multi-file
// This input type is basically the same but less pretty and doesn't do any ajax

function FileInput({ questionName, describerId, question, value, hasFieldError }) {
  const renderFileLinks = () => {
    if (value) {
      return (
        <label>
          Current submission:
          {Array.isArray(value)
            ? (
                value.map((file, index) => (
                  <React.Fragment key={index}>
                    <br />
                    <a
                      key={index}
                      href={file.uploaded_file_location}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.uploaded_file_name}
                    </a>
                  </React.Fragment>
                ))
              )
            : (
                <a
                  href={value.uploaded_file_location}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value.uploaded_file_name}
                </a>
              )}
        </label>
      );
    }
    return null;
  };

  const fileChangedHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size <= 0) {
        alert("Empty file uploaded. Please upload a file with content.");
        return false;
      }
    }
  };

  return (
    <div className="custom-file mb-3">
      {renderFileLinks()}
      <input
        className="custom-file-input form-control"
        type="file"
        name={questionName}
        aria-describedby={describerId}
        style={hasFieldError ? { border: "1px solid red" } : {}}
        accept={question.accept}
        multiple={!!question.multiple_entries}
        onChange={fileChangedHandler}
      />
    </div>
  );
}

FileInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  describerId: PropTypes.string,
  question: PropTypes.shape({
    accept: PropTypes.string,
    multiple_entries: PropTypes.bool,
    errors: PropTypes.array,
  }).isRequired,
  hasFieldError: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      uploaded_file_location: PropTypes.string,
      uploaded_file_name: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      uploaded_file_location: PropTypes.string,
      uploaded_file_name: PropTypes.string,
    })),
  ]),
};

FileInput.defaultProps = {
  describerId: "",
  hasFieldError: false,
};

export default FileInput;
