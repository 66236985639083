import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTransition, animated } from "@react-spring/web";

import consumer from "../../channels/consumer";
import ErrorBoundary from "../Shared/StatelessComponents/ErrorBoundary";

// This component is the top level entry point for the bundle
// We render this component using react_on_rails in the slim template, passing in the relevant props
// This component is responsible for providing access to the redux store
function NotificationCenter({ channel, admin_uuid, honeybadgerConfig, honeybadgerContext }) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    consumer.subscriptions.create({ channel, admin_uuid }, {
      received(data) {
        setNotifications([...notifications, data.notification]);
      },
    });
  }, [notifications]);

  const removeNotification = (id) => {
    setNotifications(notifications.filter((notification) => notification.id !== id));
  };

  const transitions = useTransition(notifications, {
    from: { transform: "translateX(100%)", height: 0 },
    leave: { transform: "translateX(100%)", height: 0 },
    enter: { transform: "translateX(0%)", height: 110 },
    update: { height: 110 },
    config: { duration: 300 },
  });

  return (
    <ErrorBoundary honeybadgerConfig={honeybadgerConfig} honeybadgerContext={honeybadgerContext}>
      <div id="notification_container">
        {transitions((style, notification) => (
          <animated.div style={style} className="notification_wrapper">
            <div className="notification">
              <a href={notification.url}>{notification.message}</a>
              <button
                className="close_button"
                onClick={() => removeNotification(notification.id)}
              >
                X
              </button>
            </div>
          </animated.div>
        ))}
      </div>
    </ErrorBoundary>
  );
}

NotificationCenter.propTypes = {
  channel: PropTypes.string.isRequired,
  admin_uuid: PropTypes.string.isRequired,
  honeybadgerConfig: PropTypes.object.isRequired,
  honeybadgerContext: PropTypes.object.isRequired,
};

export default NotificationCenter;
