import React from "react";
import PropTypes from "prop-types";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

import ErrorBoundaryDisplay from "./ErrorBoundaryDisplay";

function ErrorBoundary({ honeybadgerConfig, honeybadgerContext, children }) {
  const honeybadger = Honeybadger.configure({ ...honeybadgerConfig });
  honeybadger.setContext(honeybadgerContext);

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger} showUserFeedbackFormOnError={false} ErrorComponent={ ErrorBoundaryDisplay }>
      {children}
    </HoneybadgerErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  honeybadgerConfig: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    revision: PropTypes.string.isRequired,
  }).isRequired,
  honeybadgerContext: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
