import React from "react";
import PropTypes from "prop-types";

function CheckboxInput({ questionName, question, value, onChange, onBlur }) {
  const handleChange = (event) => {
    const targetValue = event.target.value;
    let newValue = value ? [...value] : [];
    if (event.target.checked) {
      newValue.push(targetValue);
      newValue = [...new Set(newValue)];
    } else {
      newValue = newValue.filter((value) => value !== targetValue);
    }
    onChange(newValue);
    onBlur(newValue);
  };

  return (
    <>
      {Object.entries(question.options).map((option, index) => {
        const input_id = `${questionName}_${index}`;

        return (
          <div key={input_id} className="form-check">
            <label className="switch">
              <input
                className="form-check-input question_block_checkbox_input switch_input"
                id={input_id}
                type="checkbox"
                name={`${questionName}[]`}
                value={option[0]}
                checked={(value || []).includes(option[0].toString())}
                onChange={handleChange}
                disabled={question.locked}
              />
              <span className="slider round"></span>
            </label>
            <label
              className="form-check-label question_block_checkbox_label"
              htmlFor={input_id}
            >
              {option[1]}
            </label>
          </div>
        );
      })}
    </>
  );
}

CheckboxInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  question: PropTypes.shape({
    locked: PropTypes.bool,
    options: PropTypes.object.isRequired,
  }).isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

CheckboxInput.defaultProps = {
  value: [],
};

export default CheckboxInput;
