import Helpers from './helpers'

class NotifySubproducerQuote {
  static trigger_email(event) {
    event.preventDefault();
    let $button = $("#email_subproducer_button")
    $button.addClass("loading")

    const url = $button.attr('data-ajax_url')
    Helpers.post_empty_form({
      url: url,
      success: (res) => { NotifySubproducerQuote.handle_success() },
      failure: (res) => { NotifySubproducerQuote.handle_failure(url, res) },
    })
  }

  static handle_success() {
    let $button = $("#email_subproducer_button")
    $button.removeClass("loading")
    $button.addClass("disabled")
    $("#checkmark_icon").removeClass("hidden")
    $("#envelope_icon").addClass("hidden")
    let $span = $("#email_subproducer_button span")
    $span.html("Email Sent!")
  }

  static handle_failure(url, res) {
    window.Honeybadger.notify(`Failure in triggering email to Subproducer! url: ${url} -- result: ${JSON.stringify(res, null, 2)}`)
    alert("Something went wrong with the notification. Refresh the page and try again please.")
  }
}

$(()=>{
  // If the button isn't on the page, then no need to set up the listener
  if($("#email_subproducer_button").length === 0) { return }

  $(document).on('click', '#email_subproducer_button', NotifySubproducerQuote.trigger_email)
})
