import React, { useState } from "react";
import PropTypes from "prop-types";

function AddressSuggestion({ suggestion, selectSuggestion, isFocused }) {
  const [isHovered, setIsHovered] = useState(false);

  const formatAutocompleteSuggestion = (suggestion) => {
    const street = suggestion.streetLine ? `${suggestion.streetLine} ` : "";
    const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : "";
    const entries = suggestion?.entries > 1 ? `(${suggestion.entries} more entries) ` : "";
    const city = suggestion?.city ? `${suggestion.city} ` : "";
    const state = suggestion?.state ? `${suggestion.state}, ` : "";
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : "";

    return street + secondary + entries + city + state + zip;
  };

  return (
    <div
      className={`address_suggestion ${isHovered ? "hovered" : ""} ${isFocused ? "focused" : ""}`}
      onMouseDown={() => selectSuggestion(suggestion)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {formatAutocompleteSuggestion(suggestion)}
    </div>
  );
}

AddressSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  selectSuggestion: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

export default AddressSuggestion;
