import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import deleteImage from "../../../images/subtract_gray.svg";
import { FormContext } from "../StatelessComponents/FormContext";

// This component renders the multi file input type widget for adding and removing files
// it is responsible for its own ajax calls to upload and delete files, and interacts
// with the form by dispatching actions to update the form state
function MultiFileInput({ question, questionIdentifier, onChange }) {
  const [highlighted, setHighlighted] = useState(false);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const acceptedFileTypes = question.accept.split(",").map((type) => type.trim());

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(false);
    handleFileUpload(e.dataTransfer.files);
  };

  const uploadUrl = useSelector(state => state.ajaxUrls.multiFileUpload);
  const deleteFileBaseUrl = useSelector(state => state.ajaxUrls.multiFileDelete);
  const { startLoading, stopLoading } = useContext(FormContext);

  const handleFileUpload = async (files) => {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split(".").pop();

      if (!acceptedFileTypes.includes("." + extension.toLowerCase())) {
        alert(`File type not accepted: ${file.name}\n\nPlease upload a file with one of the following extensions: ${question.accept}`);
        return;
      }

      formData.append(`files[${i}]`, file);
    }
    formData.append("question_identifier", questionIdentifier);
    dispatch(startLoading());
    const response = await fetch(uploadUrl, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("input[name=\"authenticity_token\"]")?.value,
      },
    });
    const data = await response.json();
    if (data.success) {
      onChange(data.question.value);
    } else {
      alert(data.error);
    }
    dispatch(stopLoading());
  };

  const handleDeleteFile = async (event, fileUUID) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("question_identifier", questionIdentifier);
    formData.append("file_uuid", fileUUID);
    dispatch(startLoading());
    const response = await fetch(deleteFileBaseUrl, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("input[name=\"authenticity_token\"]")?.value,
      },
    });
    const data = await response.json();

    if (data.success) {
      onChange(data.question.value);
    } else {
      alert(data.error);
    }
    dispatch(stopLoading());
  };

  return (
    <div className="multi_file_field_wrapper row">
      <div
        className={`drag_and_drop_wrapper ${highlighted ? "highlighted" : ""}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <div className="row">
          <div className="col-12 vertical_center_container">
            <span>Drag and Drop File Here or&nbsp;</span>
            <label className="hidden_file_input_label">
              Choose File(s)
              <input
                type="file"
                id={questionIdentifier}
                ref={fileInputRef}
                className="hidden_file_input"
                accept={question.accept}
                onChange={(e) => handleFileUpload(e.target.files)}
                multiple
              />
            </label>
          </div>
        </div>
      </div>
      <div className="uploaded_files_wrapper">
        {question.value &&
          question.value.map((file) => (
            <div key={file.uploaded_file_uuid} className="uploaded_file">
              <a
                href={file.uploaded_file_location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="file_name_display">
                  {file.uploaded_file_name}
                </span>
              </a>
              <button
                className="delete_file_button btn"
                onClick={(e) => handleDeleteFile(e, file.uploaded_file_uuid)}
              >
                <img src={deleteImage} alt="Delete" />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}

MultiFileInput.propTypes = {
  question: PropTypes.object.isRequired,
  questionIdentifier: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiFileInput;
