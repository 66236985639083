import React, { useRef, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { FormContext } from "../../Shared/StatelessComponents/FormContext";
import QuestionInput from "../../Shared/StatefulComponents/QuestionInput";
import QuestionLabel from "../../Shared/StatelessComponents/QuestionLabel";

// This is the core question block component that renders the label and input
// for a question based on the question type
// it is responsible for setting the name of the input on the html as
// well as the scroll ref for the form to be able to scroll to the first
// error on a submit attempt. I also has the logic for determining if
// an error should be displayed for the question at hand

const QuestionBlock = ({
  questionIdentifier,
  question,
  parentQuestionIdentifier,
  multiple,
  groupIndex,
  inputRef,
}) => {
  let questionName, describerId;
  const hasGroupIndex = groupIndex !== undefined && groupIndex !== null;

  if (parentQuestionIdentifier) {
    questionName = `${parentQuestionIdentifier}${
      multiple ? `[${groupIndex}]` : ""
    }[${questionIdentifier}]${question.multiple_entries ? "[]" : ""}`;
  } else {
    questionName = `${questionIdentifier}${
      question.multiple_entries ? "[]" : ""
    }`;
  }

  if (hasGroupIndex) {
    describerId = `for_${questionIdentifier}_${groupIndex}`;
  } else {
    describerId = `for_${questionIdentifier}`;
  }

  const fieldType = question.field_type;
  if (fieldType === undefined || fieldType === null) {
    throw new Error("fieldType is undefined");
  }
  const hasFieldError = useSelector((state) => {
    if (!state.form.attemptedSubmit) { return false; }

    return question.errors && question.errors.length > 0;
  });

  const ref = useRef(null);

  const { registerSubmitScrollRef } = useContext(FormContext);
  const justClickedSubmit = useSelector((state) => state.form.justClickedSubmit);

  useEffect(() => {
    if (hasFieldError) {
      registerSubmitScrollRef(ref);
    }
  }, [hasFieldError, justClickedSubmit]);

  const isHidden =
    questionIdentifier === "entity_identifier"
      ? false // don't use the css transition for entity identifier field type, or the input won't render. instead we hide it with regular css
      : useSelector((state) => {
        if (hasGroupIndex) {
          return state.form.questions[parentQuestionIdentifier].groups[
            groupIndex
          ][questionIdentifier].hidden;
        } else if (parentQuestionIdentifier) {
          return state.form.questions[parentQuestionIdentifier].group[
            questionIdentifier
          ].hidden;
        } else {
          return state.form.questions[questionIdentifier].hidden;
        }
      }) || false;

  const showExpectedAnswerWarning = "expected_answer" in question && question.value !== undefined && question.value !== null && question.value !== question.expected_answer;
  const expected_answer_warning_message = "expected_answer_message" in question ? question.expected_answer_message : "If you submit this answer, your application will be declined";
  return (
    <CSSTransition in={!isHidden} timeout={300} classNames="fade" unmountOnExit>
      <div
        id={hasGroupIndex ? `block_id_${questionIdentifier}_${groupIndex}` : `block_id_${questionIdentifier}`}
        ref={ref}
        className={`question_block ${fieldType}`}
        style={
          questionIdentifier === "entity_identifier" ? { display: "none" } : {}
        }
      >
        {fieldType !== "entity_identifier" && (
          <QuestionLabel
            question={question}
            fieldType={fieldType}
            hasFieldError={hasFieldError}
          />
        )}
        <QuestionInput
          inputRef={inputRef}
          question={question}
          fieldType={fieldType}
          hasFieldError={hasFieldError}
          describerId={describerId}
          questionName={questionName}
          questionIdentifier={questionIdentifier}
          parentQuestionIdentifier={parentQuestionIdentifier}
          groupIndex={groupIndex}
        />
        <div className="error_container">
          {hasFieldError && (
            <small
              className="form-text validation-error"
              id={describerId}
              style={{ color: "red" }}
            >
              {question.errors && question.errors[0]}
            </small>
          )}
          {showExpectedAnswerWarning && (
            <small
              className="form-text declination-warning"
              style={{ color: "red", backgroundColor: "yellow", padding: "1px 6px" }}
            >
              {expected_answer_warning_message}
            </small>
          )}
        </div>
        {question.help && (
          <small
            className="form-text text-muted question_block_help_text"
            id={describerId}
          >
            {question.help}
          </small>
        )}
      </div>
    </CSSTransition>
  );
};

QuestionBlock.propTypes = {
  questionIdentifier: PropTypes.string.isRequired,
  question: PropTypes.shape({
    multiple_entries: PropTypes.bool,
    conditionalStatement: PropTypes.shape({
      value: PropTypes.shape({
        identifier: PropTypes.string.isRequired,
      }).isRequired,
    }),
    field_type: PropTypes.string.isRequired,
    help: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.any,
    expected_answer: PropTypes.any,
    expected_answer_message: PropTypes.string,
  }).isRequired,
  parentQuestionIdentifier: PropTypes.string,
  multiple: PropTypes.bool,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  groupIndex: PropTypes.number,
};

QuestionBlock.defaultProps = {
  parentQuestionIdentifier: null,
  multiple: null,
  groupIndex: null,
};

export default QuestionBlock;
