import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import { store } from "./store";
import { initializeState as initializeQuestions } from "./formSlice";
import { initializeState as initializeAjaxUrls } from "./ajaxUrlsSlice";
import Form from "./components/Form";
import FormWrapper from "../Shared/StatefulComponents/FormWrapper";
import ErrorBoundary from "../Shared/StatelessComponents/ErrorBoundary";

// This component is the top level entry point for the bundle
// We render this component using react_on_rails in the slim template, passing in the relevant props
// This component is responsible for providing access to the redux store
function SubmissionsDataPage({ data, uuid, submitUrl, ajaxUrls, formAuthenticityToken, honeybadgerConfig, honeybadgerContext }) {
  return (
    <ErrorBoundary honeybadgerConfig={honeybadgerConfig} honeybadgerContext={honeybadgerContext}>
      <Provider store={store}>
        <FormWrapper
          data={data}
          ajaxUrls={ajaxUrls}
          initializeQuestions={initializeQuestions}
          initializeAjaxUrls={initializeAjaxUrls}
        >
          <Form
            uuid={uuid}
            action={submitUrl}
            formAuthenticityToken={formAuthenticityToken}
          />
        </FormWrapper>
      </Provider>
    </ErrorBoundary>
  );
}

SubmissionsDataPage.propTypes = {
  uuid: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  ajaxUrls: PropTypes.object.isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
  data: PropTypes.shape({
    questions: PropTypes.object.isRequired,
    attemptedSubmit: PropTypes.bool.isRequired,
  }).isRequired,
  honeybadgerConfig: PropTypes.object.isRequired,
  honeybadgerContext: PropTypes.object.isRequired,
};

export default SubmissionsDataPage;
