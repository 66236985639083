import Helpers from "./helpers";

class QuoteModificationsPage {
  static handle_add_subjectivities(e) {
    var $clone =  $('#subjectivity-form-row-template')
      .clone()

    $clone.toggleClass('hidden')
      .prop('id', 'subjectivity-form-row')
      .appendTo('#subjectivities')

    $clone.find('input').prop('required',true)
  }

  static handle_remove_subjectivities(e) {
    $(e.target).closest('#subjectivity-form-row').remove()
  }

  static handle_file_upload(e, files) {
    e.preventDefault()
    e.stopPropagation()

    let url = $(e.currentTarget).data('action')
    let $ajax_wrapper = $(e.currentTarget).closest('.ajax_wrapper')


    let form_data = new FormData
    form_data.append(`file`, files[0])

    $ajax_wrapper.addClass('loading')

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        $ajax_wrapper.html(res.partial)
        $ajax_wrapper.removeClass('loading')
      },
      failure: (res) => {
        console.error(res)
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error Quote Modifications file upload!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
        $ajax_wrapper.removeClass('loading')
        alert('Something went wrong. Please refresh the page and contact the Blitz tech team if the problem persists.')
      }
    })

  }

  static handle_delete_file(e, delete_button) {
    e.preventDefault()
    e.stopPropagation()
    let url = $(delete_button).data('action')
    let $ajax_wrapper = $(e.currentTarget).closest('.ajax_wrapper')

    $ajax_wrapper.addClass('loading')
    Helpers.post_form_data({
      url: url,
      form_data: new FormData,
      success: (res) => {
        $ajax_wrapper.html(res.partial)
        $ajax_wrapper.removeClass('loading')
      },
      failure: (res) => {
        console.error(res)
        let currentPage = window.location.href
        let postUrl = url
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error in Quote Modifications delete file!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}`)
        $ajax_wrapper.removeClass('loading')
        alert('Something went wrong. Please refresh the page and contact the Blitz tech team if the problem persists.')
      }
    })
  }
}

$(()=>{
  $(document).on('click', '.quote_modifications_wrapper #header_wrapper .add_button_wrapper', QuoteModificationsPage.handle_add_subjectivities)
  $(document).on('click', '.quote_modifications_wrapper #subjectivities .remove_button', QuoteModificationsPage.handle_remove_subjectivities)
})

window.QuoteModificationsPage = QuoteModificationsPage