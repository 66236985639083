import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { singular } from "pluralize";

import DynamicSVG from "../../Shared/StatelessComponents/DynamicSVG";

import {
  startLoading,
  stopLoading,
  updateField,
  ajaxUpdate,
  ajaxFileUpload,
  addEntry,
  removeEntry,
  ajaxDeleteEntity,
} from "../formSlice";
import ScheduleDropBox from "./ScheduleDropBox";
import ScheduleTable from "./ScheduleTable";
import GlobalErrorDisplay from "../../Shared/StatefulComponents/GlobalErrorDisplay";
import { FormContext } from "../../Shared/StatelessComponents/FormContext";

function InnerSchedulePage({ uuid, action, formAuthenticityToken, scheduleIdentifier, exampleCSVPath, brandingSettings }) {
  const isLoading = useSelector((state) => state.form.isLoading);
  const [showSpinner, setShowSpinner] = useState(false);
  const scheduleLabel = useSelector((state) => state.form.questions?.[scheduleIdentifier]?.label);
  const scheduleSubhead = useSelector((state) => state.form.questions?.[scheduleIdentifier]?.subhead ?? "");
  const dispatch = useDispatch();

  const formRef = useRef(null);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setShowSpinner(true);
      }, 250);
    } else {
      clearTimeout(timer);
      setShowSpinner(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isLoading]);

  const scheduleIdentifierWithoutVersion = scheduleIdentifier.replace(/_v\d+$/, "").replace("_schedule", "");

  const humanizedIdentifier = scheduleIdentifierWithoutVersion
    .replace(/_/g, " ")
    .split(" ")
    .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const fileUploadUrl = useSelector((state) => state.ajaxUrls.fileUpload);

  const addRow = () => {
    dispatch(addEntry({ questionIdentifier: scheduleIdentifier }));
  };

  const addSVGpath = brandingSettings?.svg_paths?.add_solid || "add_solid_orange.svg";
  return (
    <FormContext.Provider
      value={{ formRef, addEntry, removeEntry, updateField, ajaxUpdate, ajaxFileUpload, ajaxDeleteEntity, startLoading, stopLoading }}
    >
      <form
        ref={formRef}
        id="schedule_table_form"
        className={`form ${showSpinner ? "loading" : ""}`}
        action={action}
        method="post"
        encType="multipart/form-data"
      >
        <input name="uuid" type="hidden" value={uuid} />
        <input name="schedule_format" type="hidden" value="manual" />
        <input name="schedule_identifier" type="hidden" value={scheduleIdentifier} />
        <input
          name="authenticity_token"
          type="hidden"
          value={formAuthenticityToken}
        />
        <GlobalErrorDisplay />
        <div id="schedule_header_wrapper">
          <h3>
            {`${scheduleLabel} Schedule Input`}
          </h3>
          <h6 className="header_help_text">
            {`${scheduleSubhead}`}
          </h6>
          <div className="add_button_wrapper py-2 over">
            <button className="btn" onClick={() => { addRow(); }} type="button">
              <img src={DynamicSVG({ path: addSVGpath })} alt="Add" />
              Add {singular(humanizedIdentifier)}
            </ button>
          </div>
        </div>
        {fileUploadUrl && <ScheduleDropBox scheduleIdentifier={scheduleIdentifier} exampleCSVPath={exampleCSVPath}/>}
        <ScheduleTable
          scheduleIdentifier={scheduleIdentifier}
        />

        <div id="submit_row_wrapper" className="text-end">
          <div className="add_button_wrapper py-2 under">
            <button className="btn" onClick={() => { addRow(); }} type="button">
              <img src={DynamicSVG({ path: addSVGpath })} alt="Add" />
              Add {singular(humanizedIdentifier)}
            </button>
          </div>
          <button id="schedule_submit_button" className="btn d-inline-block orange_button" type="submit">
            Submit Schedule Data
          </button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </form>
    </FormContext.Provider>
  );
}

InnerSchedulePage.propTypes = {
  uuid: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
  scheduleIdentifier: PropTypes.string.isRequired,
  exampleCSVPath: PropTypes.string.isRequired,
  brandingSettings: PropTypes.object.isRequired,
};

export default InnerSchedulePage;
