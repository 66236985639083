import React from "react";
import PropTypes from "prop-types";

import QuestionInput from "../../Shared/StatefulComponents/QuestionInput";

const ScheduleTableCell = ({ question, scheduleIdentifier, groupIndex, questionIdentifier, inputRef, ...props }) => {
  const questionName = `${scheduleIdentifier}[${groupIndex}]${questionIdentifier}`;
  const hasFieldError = question.errors && question.errors[0] !== undefined;
  const isHiddenCell = questionIdentifier === "entity_identifier" ? "hidden_cell" : "";

  return (
    <td className={`${questionIdentifier} ${isHiddenCell}`}>
      {props.children}
      <QuestionInput
        inputRef={inputRef}
        question={question}
        fieldType={question.field_type}
        hasFieldError={hasFieldError}
        questionName={questionName}
        questionIdentifier={questionIdentifier}
        parentQuestionIdentifier={scheduleIdentifier}
        groupIndex={groupIndex}
      />
      {question.errors && <small className="error_text">{question.errors[0]}</small>}
    </td>
  );
};

ScheduleTableCell.propTypes = {
  question: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.string),
    field_type: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
  scheduleIdentifier: PropTypes.string.isRequired,
  groupIndex: PropTypes.number.isRequired,
  questionIdentifier: PropTypes.string.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default ScheduleTableCell;
