import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const DynamicSVG = ({ path }) => {
  const [SvgComponent, setSvgComponent] = useState(null);

  useEffect(() => {
    import(`../../../images/${path}`)
      .then((module) => {
        setSvgComponent(() => module.default);
      })
      .catch((error) => {
        console.error(`Failed to load SVG: ${error}`);
      });
  }, [path]);

  return SvgComponent || null;
};

DynamicSVG.propTypes = {
  path: PropTypes.string.isRequired,
};

export default DynamicSVG;
