import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { useSmartyStreets } from "../../Shared/Services/useSmartyStreets";
import QuestionBlock from "./QuestionBlock.jsx";
import { FormContext } from "../StatelessComponents/FormContext";

// This component encapulates the smarty streets autocomplete functionality
// It currently expects that the question it is passed aliases address fields
// to legacy individual address locations, but it could be upgraded to handle
// modern address questions with built in nesting in a normalized structure
// This component handles interaction with the smarty streets api and
// rendering of the suggestions and nested address fields
function SmartAddress({ question, parentQuestionIdentifier, groupIndex }) {
  const dispatch = useDispatch();
  const address1QuestionIdentifier = question.aliased_fields.address_1.question_identifier;
  const address1Value = useSelector((state) => {
    if (groupIndex === null || groupIndex === undefined) {
      return state.form.questions[parentQuestionIdentifier].group[address1QuestionIdentifier].value;
    } else {
      return state.form.questions[parentQuestionIdentifier].groups[groupIndex][address1QuestionIdentifier].value;
    }
  });
  const { formRef, updateField, ajaxUpdate } = useContext(FormContext);

  const updateAddressValue = (addressField, value) => {
    dispatch(updateField({
      questionIdentifier: parentQuestionIdentifier,
      groupIndex,
      childQuestionIdentifier: question.aliased_fields[addressField].question_identifier,
      value,
    }));
  };

  const {
    address1InputRef,
    renderedSuggestions,
  } = useSmartyStreets({
    initialAddress1Value: question.aliased_fields.address_1.value,
    currentAddress1Value: address1Value,
    callbackOnSelection: ({ address1, address2, city, state, zip }) => {
      updateAddressValue("address_1", address1);
      updateAddressValue("address_2", address2);
      updateAddressValue("city", city);
      updateAddressValue("state", state);
      updateAddressValue("zip", zip);
      setTimeout(() => {
        const formData = new FormData(formRef.current);
        dispatch(ajaxUpdate(formData));
      }, 0);
    },
  });

  const parentQuestion = useSelector((state) => state.form.questions[parentQuestionIdentifier]);

  return (
    <div className="smart_address">
      {renderedSuggestions}
      {Object.entries(question.aliased_fields).map(([addressFieldIdentifier, childQuestion]) => {
        return (
          <QuestionBlock
            key={addressFieldIdentifier}
            questionIdentifier={childQuestion.question_identifier}
            question={childQuestion}
            multiple={parentQuestion.multiple_entries}
            parentQuestionIdentifier={parentQuestionIdentifier}
            groupIndex={groupIndex}
            inputRef={addressFieldIdentifier === "address_1" ? address1InputRef : null}
          />
        );
      })}
    </div>
  );
}

SmartAddress.propTypes = {
  question: PropTypes.object.isRequired,
  parentQuestionIdentifier: PropTypes.string.isRequired,
  groupIndex: PropTypes.number,
};

export default SmartAddress;
