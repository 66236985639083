import React from "react";
import PropTypes from "prop-types";

import BooleanToggleInput from "./BooleanToggleInput";

function AttestationInput({
  question,
  questionName,
  value,
  onChange,
  onBlur,
  hasFieldError,
}) {
  const containerStyle = hasFieldError ? { border: "1px solid red" } : {};
  const labelStyle = hasFieldError ? { color: "red" } : {};

  return (
    <div className="attestation_container" style={containerStyle}>
      <span className="attestation">
        <div className="attestation_label" style={labelStyle}>
          {question.label}
        </div>
        <br />
        <div className="attestation_header">{question.attestation_header}</div>
        <br />
        {Object.entries(question.attestation_clauses).map(
          (statement, index) => (
            <ul key={index}>
              <li>{statement[1]}</li>
            </ul>
          ),
        )}
      </span>
      {question.attestation_footer}
      <div
        className="confirm"
        style={{ textAlign: "right", paddingTop: "20px" }}
      >
        <BooleanToggleInput
          questionName={questionName}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
}

AttestationInput.propTypes = {
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    attestation_header: PropTypes.string.isRequired,
    attestation_clauses: PropTypes.object.isRequired,
    attestation_footer: PropTypes.string,
  }).isRequired,
  questionName: PropTypes.string.isRequired,
  value: PropTypes.bool,
  hasFieldError: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

AttestationInput.defaultProps = {
  hasFieldError: false,
  value: null,
};

export default AttestationInput;
