import consumer from "./consumer"

// console.log("loaded report channel")

$(()=>{
  let current_admin = $('current_admin#current_admin_reference_uuid')
  if (current_admin[0] == undefined) { return }

  let admin_uuid = current_admin.text().trim()
  // console.log("connecting to report channel for admin uuid: ", admin_uuid)

  consumer.subscriptions.create({ channel: "ReportChannel", admin_uuid: admin_uuid }, {
    received(data) {
      this.handle_incoming_message(data)
    },

    handle_incoming_message(data) {
      if ( $(`.async_reports`)[0] == undefined ) {
        return
      }
      // console.log(data)
      let report_uuid = data['uuid']
      if (report_uuid == undefined) {
        return
      }

      let $target = $(`[data-pending_report_uuid="${report_uuid}"]`)
      if ($target == undefined) {
        return
      }

      if (data['event'] == 'progress_updated') {
        $target.siblings('.progress_message').text("Progress: "+data['message'])
      } else if (data['event'] == 'completed') {
        $target.attr('data-pending_repor_uuid', '')
        $target.siblings('.progress_message').text('')
        $target.removeClass('loading')
        $target.children('span').text($target.data('label'))
        $target.removeAttr('data-label')
        if (data['success'] == true) {
          $(`.report_download_button[data-report_identifier="${$target.data('report_identifier')}"] .updated_at`).text(data['updated_at'])
        } else {
          alert('Failed to generate report.')
        }
      }
    }
  })

  $(document).on('click', '.report_generate_button', (e)=> {
    e.preventDefault()
    let $target = $(e.currentTarget)
    if ($target.hasClass('loading') == true) {
      return
    }

    $target.addClass('loading')

    $.ajax({
      url: $target.attr('href'),
      type: 'POST',
      dataType: 'JSON',
      data: { 'domain': $target.data('report_domain'), 'identifier': $target.data('report_identifier') },
      processData: false,
      contentType: false,
      success: (res) => {
        $target.attr('data-pending_report_uuid', res['pending_report_uuid'])
        $target.attr('data-label', $target.text())
        $target.children('span').text('Pending...')
      },
      error: (res) => {
        $target.removeClass('loading')
        alert('Failed to trigger report generation.')
      }
    })
  })
})

