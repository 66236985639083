$(() => {
  $(document).on("change", "#portal_policy_container #send_email_attachment_field", function(event) {
    var totalSize = 0;
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
        totalSize += files[i].size;
    }

    if ( (totalSize / 1024 / 1024) > 20) {
      alert('Attachments are over 20MB. Please reduce file size and try again');
      event.target.value = '';
      return false;
    } 
    return true;
  });
})