import Helpers from '../apply/helpers.js'

class AdminStartNewApplicationForm {
  static rerender_form(e) {
    let $container = $('#admin_new_application_form')
    let $form = $container.find('form')
    let form_data = new FormData($form[0])
    let url = $container.data('ajax_route')
    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        $container.html(res.partial)
      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        let msg = `Error in admin new application form JS!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`
        // window.Honeybadger.notify(msg)
        console.error(msg)
      }
    })
  }
}

$(()=>{
  $('#new_admin_modal_submit').on('click', ()=>{
    if ($('#admin_form')[0].checkValidity()) {
      $('#admin_form').trigger('submit')
    } else {
      $('#admin_form')[0].reportValidity()
    }
  })

  $(document).on('change', '#admin_application_new_container #admin_new_application_form select', AdminStartNewApplicationForm.rerender_form)
})