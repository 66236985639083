

class HistoryPage {

  static toggle_detail(e) {

    let $button = $(e)
    let $detail_container = $button.next()

    if ($detail_container.html() == "") {
      // Need to populate detail container

      if ($detail_container.hasClass('json_diff_viewer')) {
        let before = $detail_container.data('before')
        let after = $detail_container.data('after')
        let diff_result = Diff.diffJson(before, after)
        let $pre_tag = $(document.createElement('pre'))
        diff_result.forEach((chunk)=>{
          let $span_tag = $(document.createElement('span'))

          if (chunk.added) {
            $span_tag.addClass('diff_added')
            $span_tag.text(chunk.value)
          } else if (chunk.removed) {
            $span_tag.addClass('diff_removed')
            $span_tag.text(chunk.value)
          } else {
            $span_tag.addClass('diff_common')
            if (chunk.count > 6) {
              // hide the bulk of the common chunk inside a toggle-able placeholder
              let chunk_lines = chunk.value.split("\n")
              $span_tag.append(chunk_lines.slice(0,2).join("\n"))
              let hidden_count = chunk.count - 4
              let $placeholder_wrapper = $(document.createElement('a'))
              $placeholder_wrapper.addClass("placeholder_wrapper")
              let $placeholder = $(document.createElement('p'))
              $placeholder.text(`Click to reveal ${hidden_count} hidden lines`)
              $placeholder_wrapper.append($placeholder)
              let $hidden_chunk = $(document.createElement('span'))
              $hidden_chunk.hide()
              $hidden_chunk.append("\n")
              $hidden_chunk.append(chunk_lines.slice(2, -2).join("\n"))
              $hidden_chunk.append("\n")
              $placeholder_wrapper.append($hidden_chunk)
              $span_tag.append($placeholder_wrapper)
              $span_tag.append(chunk_lines.slice(-2).join("\n"))
            } else {
              $span_tag.text(chunk.value)
            }
          }
          $pre_tag.append($span_tag)
        })
        $detail_container.html($pre_tag)


      }
    }

    $detail_container.slideToggle()
    $button.find('img').toggleClass('toggled')

  }
}

window.HistoryPage = HistoryPage

$(()=>{
  $(document).on('click', '.placeholder_wrapper', (e)=> {
    e.preventDefault()
    e.stopPropagation()
    let $clicked = $(e.currentTarget)
    $clicked.children().toggle()
  })

  $(document).on('click', '#legend_button', (e)=>{
    e.preventDefault()
    $('#legend_wrapper').slideToggle()
  })

  $(document).on('change', '#legend_wrapper .history_filter', (e)=>{
    let $checkbox = $(e.target)
    let filter = $checkbox.attr('name')
    var $scoped_elements = null

    // TODO - these filters don't combine very well, and are very coupled to the arbitrary node types spit out by the history generator
    // could be cleaned up to make the filters more composable and log types/classes more maintainable

    if (filter == 'show_no_op_events') {
      $scoped_elements = $('.history_node.no_op')
    } else if (filter == 'show_side_effect_events') {
      $scoped_elements = $('.history_node.with_side_effects')
    } else if (filter == 'show_journey_events') {
      $scoped_elements = $('.history_node.journey, .history_node.regress, .history_node.regress_with_invalidation, .history_node.submissions_data')
    } else if (filter == 'show_submissions_data_events') {
      $scoped_elements = $('.history_node.submissions_data_answer_creation, .history_node.no_op_submissions_data')
    } else if (filter == 'show_product_selection_events') {
      $scoped_elements = $('.history_node.product_selection_update, .history_node.no_op_product_selection_update')
    } else if (filter == 'show_product_events') {
      $scoped_elements = $('.history_node.product, .history_node.product_offer_creation, .history_node.product_order_creation, .history_node.product_taxes_and_fees, .history_node.no_op_product_taxes_and_fees, .history_node.product_offer_invalidation, .history_node.product_order_invalidation')
    } else if (filter == 'show_decisioning_events') {
      $scoped_elements = $('.history_node.decision_creation, .history_node.decision_invalidation')
    } else if (filter == 'show_agreement_events') {
      $scoped_elements = $('.history_node.agreement_creation, .history_node.agreement_invalidation')
    } else if (filter == 'show_document_storage_events') {
      $scoped_elements = $('.history_node.document_storage')
    } else if (filter == 'show_underwriting_events') {
      $scoped_elements = $('.history_node.underwriting')
    } else if (filter == 'show_system_actions') {
      $scoped_elements = $('.history_node.system')
    } else if (filter == 'show_admin_actions') {
      $scoped_elements = $('.history_node.admin')
    } else if (filter == 'show_producer_actions') {
      $scoped_elements = $('.history_node.producer')
    }

    if ($checkbox.is(":checked")) {
      $scoped_elements.show()
    } else {
      $scoped_elements.hide()
    }
  })
})