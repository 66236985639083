import consumer from "./consumer"
import SlidingPane from '../src/SlidingPane.js'

//console.log("loaded note refresh channel")

$(()=>{
  let current_app = $('current_app#current_app_reference_uuid')
  if (current_app[0] == undefined) { return }

  let app_uuid = current_app.text().trim()

  consumer.subscriptions.create({ channel: "NoteChannel", app_uuid: app_uuid }, {
    received(data) {
      this.handle_incoming_message(data)
    },

    handle_incoming_message(data) {
      //console.log("Incoming message on the note channel:")
      //console.log(data)
      if (data['should_refresh'] != true) {
        return
      }

      if ($('.slidingpane-wrap.open')[0] == undefined) {
        //console.log("flagging new note")
        $('.admin-notes-tab').addClass('new_note')
        return
      }

      $.ajax({
        url: $('.admin-notes-tab').data('sliding-pane-url'),
        type: "GET",
        dataType: "JSON",
        complete: function(res) {
          //console.log(res.responseText)
          $('.slidingpane-body').html(res.responseText)
        }
      })
    }

  })
})

