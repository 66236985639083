import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { FormContext } from "../../Shared/StatelessComponents/FormContext";
import { useSmartyStreets } from "../../Shared/Services/useSmartyStreets";
import ScheduleTableCell from "./ScheduleTableCell";

function ScheduleSmartAddressCells({ scheduleIdentifier, question, groupIndex }) {
  const dispatch = useDispatch();
  const address1QuestionIdentifier = question.aliased_fields.address_1.question_identifier;
  const address1Value = useSelector((state) => state.form.questions[scheduleIdentifier].groups[groupIndex][address1QuestionIdentifier].value);
  const { formRef, updateField, ajaxUpdate } = useContext(FormContext);

  const updateAddressValue = (addressField, value) => {
    dispatch(updateField({
      questionIdentifier: scheduleIdentifier,
      groupIndex,
      childQuestionIdentifier: question.aliased_fields[addressField].question_identifier,
      value,
    }));
  };

  const {
    address1InputRef,
    renderedSuggestions,
  } = useSmartyStreets({
    initialAddress1Value: question.aliased_fields.address_1.value,
    currentAddress1Value: address1Value,
    callbackOnSelection: ({ address1, address2, city, state, zip }) => {
      updateAddressValue("address_1", address1);
      updateAddressValue("address_2", address2);
      updateAddressValue("city", city);
      updateAddressValue("state", state);
      updateAddressValue("zip", zip);
      setTimeout(() => {
        const formData = new FormData(formRef.current);
        dispatch(ajaxUpdate(formData));
      }, 0);
    },
  });

  return (
    <React.Fragment>
      {Object.entries(question.aliased_fields).map(([addressField, addressQuestion], index) => {
        return (
          <ScheduleTableCell
            key={`${groupIndex}_${addressField}`}
            inputRef={addressField === "address_1" ? address1InputRef : null}
            question={addressQuestion}
            scheduleIdentifier={scheduleIdentifier}
            groupIndex={groupIndex}
            questionIdentifier={addressQuestion.question_identifier}
          >
            {addressField === "address_1" && (
              <div className="smart_address">
                {renderedSuggestions}
              </div>
            )}
          </ScheduleTableCell>
        );
      })}
    </React.Fragment>
  );
}

ScheduleSmartAddressCells.propTypes = {
  scheduleIdentifier: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default ScheduleSmartAddressCells;
