import Helpers from '../apply/helpers.js'
import bootstrap from 'bootstrap'
require('@popperjs/core')

class OfferAdjustments {
  static update_product(product_identifier) {
    let $form = $('#adjustment_form')
    let url = $form.attr('ajax')
    let form_data = new FormData($form[0])
    form_data.append('ajax_scope', 'product')
    form_data.append('product_identifier', product_identifier)
    $form.addClass('loading')

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        let $target = $(`#${product_identifier}`)
        $target.html(res.partial)
        $(`#${product_identifier} input.currency`).each((i, element) =>{
          Helpers.formatDollars($(element))
        })
        if ($(`#${product_identifier}_switch`).is(':checked')) {
          $(`#${product_identifier}`).removeClass('manually_declined')
        } else {
          $(`#${product_identifier}`).addClass('manually_declined')
        }
        let $summary_target = $('#offer_summary')
        $summary_target.html(res.offer_summary)
        $form.removeClass('loading')

        $('[data-bs-toggle="tooltip"]').tooltip({ container: '#offer_adjustment_wrapper' })
      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error in Offer Adjustment!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
        console.error(res)
      }
    })
  }

  static handle_event(e) {
    let $changed = $(e.target)
    if ($changed.hasClass('hidden_fold_input')) { return }

    let ajax_scope = $changed.data('ajax_scope')
    let product_identifier = $changed.data('product_identifier')
    let coverage_identifier = $changed.data('coverage_identifier')

    if ($changed.hasClass('top_level_coverage_input')) {
      let attribute_identifier = $changed.data('attribute_identifier')
      let selected_value = $changed.val()
      $(`#${coverage_identifier} select.${attribute_identifier}, #${coverage_identifier} input.${attribute_identifier}`).val(selected_value)
    }

    if (ajax_scope == 'product') {
      OfferAdjustments.update_product(product_identifier)
    } else {
      console.error(`Unrecognized ajax_scope: :${ajax_scope}`)
    }

  }

  static handle_coverage_click(e) {
    let $clicked = $(e.target)
    let coverage_identifier = $clicked.data('coverage_identifier')
    $(`#${coverage_identifier} .entity_switch`).each((i, element) => {
      let $entity_switch = $(element)
      if ($entity_switch.is(':disabled')) { return }

      if ($clicked.is(':checked')) {
        $entity_switch.prop('checked', true)
      } else {
        $entity_switch.prop('checked', false)
      }
    })

    let should_deselect_product = true
    let product_identifier = $clicked.data('product_identifier')
    $(`#${product_identifier} .coverage_switch`).each((i, element) => {

      let $target_switch = $(element)
      if ($target_switch.is(':checked')) {
        should_deselect_product = false
      }
    })
    if (should_deselect_product) {
      $(`#${product_identifier}_switch`).trigger('click')
    }
  }

  static handle_product_click(e) {
    let $clicked = $(e.target)
    let product_identifier = $clicked.data('product_identifier')

    $(`#${product_identifier} .coverage_switch, #${product_identifier} .entity_switch`).each((i, element) => {
      let $target_switch = $(element)
      if ($target_switch.is(':disabled')) { return }

      if ($clicked.is(':checked')) {
        $target_switch.prop('checked', true)
      } else {
        $target_switch.prop('checked', false)
      }
    })
  }

  static handle_entity_click(e) {
    let $clicked = $(e.target)
    let coverage_identifier = $clicked.data('coverage_identifier')
    let should_deselect_coverage = true

    $(`#${coverage_identifier} .entity_switch`).each((i,element) => {
      let $switch = $(element)
      if ($switch.is(':checked')) {
        should_deselect_coverage = false
      }
    })
    if (should_deselect_coverage) {
      $(`#${coverage_identifier}_switch`).trigger('click')
    }
  }
}


$(()=>{
  $(document).on('click', '#offer_adjustment_wrapper .product_wrapper .coverage_switch', OfferAdjustments.handle_coverage_click)
  $(document).on('click', '#offer_adjustment_wrapper .product_wrapper .product_switch', OfferAdjustments.handle_product_click)
  $(document).on('click', '#offer_adjustment_wrapper .entity_switch', OfferAdjustments.handle_entity_click)
  $(document).on('blur', '#offer_adjustment_wrapper input.text_input', (e) => { Helpers.enforce_min_max(e.target) })
  $(document).on('change', '#offer_adjustment_wrapper input, #offer_adjustment_wrapper select', OfferAdjustments.handle_event)
  $(document).on('click', '#offer_adjustment_wrapper .product_wrapper .fold_button input', (e)=>{
    let $clicked = $(e.target)
    let coverage_identifier = $clicked.data('coverage_identifier')
    let product_identifier = $clicked.data('product_identifier')
    if (coverage_identifier != undefined) {
      if ($clicked.is(':checked')) {
        $(`#${coverage_identifier} .folding_wrapper`).slideUp()
      } else {
        $(`#${coverage_identifier} .folding_wrapper`).slideDown()
      }
    } else if (product_identifier != undefined) {
      if ($clicked.is(':checked')) {
        $(`#${product_identifier} > .folding_wrapper`).slideUp()
      } else {
        $(`#${product_identifier} > .folding_wrapper`).slideDown()
      }
    }
  })
  $(document).on('click', '#offer_adjustment_wrapper #offer_summary .fold_button input', (e)=>{
    let $clicked = $(e.target)
    let product_identifier = $clicked.data('product_identifier')
    if ($clicked.is(':checked')) {
      $(`#summary_${product_identifier}_coverages`).slideUp()
    } else {
      $(`#summary_${product_identifier}_coverages`).slideDown()
    }
  })

  $(document).on('click', '#offer_adjustment_wrapper #renewal_order_summary .fold_button input', (e)=>{
    let $clicked = $(e.target)
    let product_identifier = $clicked.data('product_identifier')
    if ($clicked.is(':checked')) {
      $(`#renewal_summary_${product_identifier}_coverages`).slideUp()
    } else {
      $(`#renewal_summary_${product_identifier}_coverages`).slideDown()
    }
  })

  $('#offer_adjustment_wrapper .btn[data-bs-toggle="tooltip"]').tooltip({ container: '#offer_adjustment_wrapper' })
})