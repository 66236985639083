import Helpers from './helpers.js'

class StartNewApplicationForm {
  static handle_program_selection(e) {
    StartNewApplicationForm.update_program_dependent_state_dropdown()
    StartNewApplicationForm.show_program_summary()
  }

  static show_program_summary() {
    let $form = $('#apply_index_form')
    let form_data = new FormData($form[0])
    let $program_summary_card = $('#program_summary_info')
    let url = $program_summary_card.data('ajax_route')

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      enctype: 'multipart/form-data',
      success: (res) => {
        if (!res.empty)
        {
          $program_summary_card.html(res.partial)
          $program_summary_card.removeClass('hidden')
        }
        else
        {
          $program_summary_card.addClass('hidden')
        }

      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error in Program Selection!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
      }
    })
  }

  static update_program_dependent_state_dropdown() {
    let $form = $('#apply_index_form')
    let form_data = new FormData($form[0])
    let $dropdown_container = $('#program_dependent_state_dropdown')
    let $states_list_container = $('#issuable_states_container')
    let url = $dropdown_container.data('ajax_route')
    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      enctype: 'multipart/form-data',
      success: (res) => {
        let new_dropdown_html = res.partial
        let new_states_html = res.issuable_states_text
        $dropdown_container.html(new_dropdown_html)
        $states_list_container.html(new_states_html)
      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error in Program Selection!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
      }
    })
  }
}


$(()=>{
  $(document).on('change', '#apply_index_container #program_selection select', StartNewApplicationForm.handle_program_selection)
})