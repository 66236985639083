import consumer from "./consumer"

// console.log("loaded run rate channel")
$(()=>{
  // Only subscribe on the run rate page
  if ($('#run_rate')[0] == undefined) {
    return
  }
  // console.log("subscribing to run_rate channel")

  consumer.subscriptions.create({ channel: "RunRateChannel" }, {
    received(data) {
      this.handle_incoming_message(data)
    },

    handle_incoming_message(data) {
      // console.log("incoming message on run_rate channel:", data)

      // only refresh on the run rate page
      if ($('#run_rate')[0] != undefined) {
        $('#issued_container').removeClass('pending')
        window.setupConfetti()

        //console.log(data)
        let number_of_policies = data['number_of_policies']
        let total_premium_booked_nb = data['total_premium_booked_nb']
        let total_premium_booked_renewal = data['total_premium_booked_renewal']
        let total_premium_booked = data['total_premium_booked']
        let pct_change = data['pct_change']
        let yearly_run_rate = data['yearly_run_rate']

        $('#current_month_number_of_policies').text(number_of_policies)
        $('#current_month_total_premium_booked_nb').text(total_premium_booked_nb)
        $('#current_month_total_premium_booked_renewal').text(total_premium_booked_renewal)
        $('#current_month_total_premium_booked').text(total_premium_booked)
        $('#current_month_pct_change').text(pct_change)
        $('#current_month_yearly_run_rate').text(yearly_run_rate)
      }
    }

  })
})
