class SlidingPane {
  constructor($slidingPane) {
    this.$slidingPane = $slidingPane

    $(document).on("click", '[data-sliding-pane-url]', function(e) {
      this.open($(e.currentTarget).data('sliding-pane-url'))
      setTimeout(()  => {
        this.getNotesTemplate();
        $('#uw_notes_email_topic').on('change', (event) => {
          this.getNotesTemplate();
        });
      }, 200);
      
    }.bind(this))

    $(document).on("click", ".slidingpane-close .fa-times", function(e) {
      this.close()
    }.bind(this))

    $(document).on("submit", '#add_note_form', function(e) {
      e.preventDefault()
      this.submitForm(e.target)
    }.bind(this))

    $(document).on("submit", '#send_email_form', function(e) {
      e.preventDefault()
      this.submitEmailForm(e.target)
    }.bind(this))
  }

  static renderPane() {
    const $slidingPane = $(`
      <div class="slidingpane-wrap">
        <div class="slidingpane-close">
          <i class="fas fa-times" style="position: absolute; width: 15px; top: 0; height: 42px"></i>
        </div>
        <div class="slidingpane-body">
        </div>
      </div>
    `)
    $slidingPane.appendTo('body')

    new SlidingPane($slidingPane)
  }

  open(dataUrl) {
    this.$slidingPane.css("transform", "translate(0%)")

    this.loadData(dataUrl)
    $('.slidingpane-wrap').addClass('open')
  }

  close() {
    this.$slidingPane.css("transform", "translate(100%)")
    $('.slidingpane-wrap').removeClass('open')
    $('.admin-notes-tab').removeClass('new_note')
  }

  loadData(url) {
    $.ajax({
      url: url,
      type: "GET",
      dataType: "JSON",
      complete: function(res) {
        this.updateBody(res.responseText)
        let last_note = $('.note-container:last-child')[0]
        if (last_note != undefined) {
          $('.note-container:last-child')[0].scrollIntoView()
        }
      }.bind(this)
    })
  }

  updateBody(html) {
    this.$slidingPane.children('.slidingpane-body').html(html)
    this.getNotesTemplate();
    $('#uw_notes_email_topic').on('change', (event) => {
      this.getNotesTemplate();
    });
  }

  submitForm(form) {
    $.ajax({
      url: form.action,
      type: form.method || "POST",
      dataType: "JSON",
      data: new FormData(form),
      processData: false,
      contentType: false,
      success: function(res) {
        this.loadData(res.url)
      }.bind(this)
    })
  }

  submitEmailForm(form) {
    $.ajax({
      url: form.action,
      type: form.method,
      data: new FormData(form),
      processData: false,
      contentType: false,
      success: function(res) {
        setTimeout(function() {
          alert("Quote Follow-up Email Sent. Refresh page to see note");
          $('#ask_producer_modal_submit_side').removeClass('disabled_with_spinner');
          $('#ask_producer_modal_submit_side').prop('disabled', false);
        }, 1000);
      },
      error: function(res) {
        alert("Email Failed to Send, You can connect your email in the Admins tab");
      }
    })
  }

  getNotesTemplate() {
    var selected_option = $('#uw_notes_email_topic').val();
    if (typeof selected_option === 'undefined') { selected_option = 'quote_follow_up_1'; }
  
    var selected_option_key = selected_option.trim().toLowerCase().replace(/\s+/g, '_').replace('-', '_');
    var ajax_route = $('#uw_notes_email_topic').data('ajax_route');
    if ( ajax_route === undefined ) { return; }
    var url =  ajax_route + '&topic=' + selected_option_key

    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'json',
      success: function(response) {
        if (!(response && response.template && response.template.body)) {return;}
        window.template = response.template;
        if (window.template) {
          $('#notes_email_body_text_area').val(window.template.body);
          $('#notes_email_subject_text_area').val(window.template.subject);
        }
      },
      error: function(xhr, status, error) {
        if (!(xhr && xhr.responseText)) 
        {
          window.Honeybadger.notify(`Error in Email Template!\nxhr: ${xhr}\nurl: ${url}\nselected_option: ${selected_option}`)
          return;
        }
        var errorMessage = JSON.parse(xhr.responseText).error;
        console.log("Error message:", errorMessage);
      }
    });
  };
}

export default SlidingPane
