import consumer from "./consumer"

$(()=>{
  // Only subscribe on the bulk runs page
  if ($('#bulk_runs')[0] == undefined) {
    return
  }
  // console.log("subscribing to bulk_runs channel")

  consumer.subscriptions.create({ channel: "BulkRunChannel" }, {
    received(data) {
      this.handle_incoming_message(data)
    },

    handle_incoming_message(data) {
      // console.log("incoming message on bulk_runs channel:", data)

      // only refresh on the bulk runs page
      if ($('#bulk_runs')[0] != undefined) {
        window.location.reload()
      }
    }

  })
})

