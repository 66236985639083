$(document).ready(function() {
  if( !$('#topic_selection_template').length ) {return;}
  getTemplate();

  $('#topic_selection_template').change(function() {
    getTemplate();
    $('#ask_underwriter_modal_submit').prop('disabled', !$('#topic_selection_template').val());
  });
});

var getTemplate = function() {
  var selected_option = $('#topic_selection_template').val();

  if (!selected_option) {selected_option = "other";}
  var selected_option_key = selected_option.trim().toLowerCase().replace(/\s+/g, '_').replace('-', '_');

  let url = $('#topic_selection_template').data('ajax_route');
  if (!url) {url = $('#topic_selection_template').data('ajaxRoute');}
  if (!url) {return;}
  if (url.indexOf('topic=') === -1) {
    url += '&topic=' + selected_option_key;
  }


  $.ajax({
    url: url,
    method: 'GET',
    dataType: 'json',
    success: function(response) {
      if (!(response && response.template && response.template.body)) {return;}
      if (window.template && window.template.hasOwnProperty('body'))
      {
        if (window.template.body == response.template.body) {return;}
      }
      window.template = response.template;

      if (window.template) {
        $('#body_text_area').val(window.template.body);
      }
    },
    error: function(xhr, status, error) {
      if (!(xhr && xhr.responseText)) 
      {
        window.Honeybadger.notify(`Error in Email Template!\nxhr: ${xhr}\nurl: ${url}\nselected_option: ${selected_option}`)
        return;
      }
      var errorMessage = JSON.parse(xhr.responseText).error;
      console.log("Error message:", errorMessage);
    }})

  $('#ask_underwriter_modal_submit').prop('disabled', !$('#topic_selection_template').val());
};
