import React from "react";
import PropTypes from "prop-types";

function RadioInput({ question, questionName, value, hasFieldError, onChange, onBlur }) {
  const handleChange = (event) => {
    onChange(event.target.value);
    onBlur(event.target.value);
  };

  return (
    <div>
      {Object.entries(question.options).map(([option_value, option_label], index) => (
        <div className="form-check" key={`${questionName}${index}`}>
          <label className="switch">
            <input
              className="form-check-input question_block_radio_input"
              type="radio"
              style={hasFieldError ? { border: "1px solid red" } : {}}
              name={questionName}
              id={`${questionName}${index}`}
              value={option_value}
              checked={value != null && value.toString() === option_value.toString()}
              disabled={question.locked}
              onChange={handleChange}
            />
            <span className="slider round"></span>
          </label>
          <label
            className="form-check-label question_block_radio_label"
            htmlFor={`${questionName}${index}`}
          >
            {option_label}
          </label>
        </div>
      ))}
    </div>
  );
}

RadioInput.propTypes = {
  question: PropTypes.shape({
    locked: PropTypes.bool,
    options: PropTypes.object.isRequired,
  }).isRequired,
  questionName: PropTypes.string.isRequired,
  hasFieldError: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

RadioInput.defaultProps = {
  hasFieldError: false,
  value: null,
};

export default RadioInput;
