import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {};

// This is the slice of the redux store that holds the form state
// all the actions triggered by the component tree are defined here
// This is also where the ajax calls are made

export const ajaxFileUpload = createAsyncThunk(
  "form/ajaxFileUpload",
  async (formData, thunkAPI) => {
    const state = thunkAPI.getState();
    const updateUrl = state.ajaxUrls.fileUpload;

    const response = await fetch(updateUrl, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();

    return data;
  },
);

export const ajaxUpdate = createAsyncThunk(
  "form/ajaxUpdate",
  async (formData, thunkAPI) => {
    const state = thunkAPI.getState();
    const updateUrl = state.ajaxUrls.dryRun;

    const response = await fetch(updateUrl, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();

    return data;
  },
);

export const ajaxDeleteEntity = createAsyncThunk(
  "form/ajaxDeleteEntity",
  async ({ entityUuid, questionIdentifier, groupIndex }, thunkAPI) => {
    const state = thunkAPI.getState();
    const deleteUrl = state.ajaxUrls.deleteEntity;
    const formData = new FormData();
    formData.append("deletion_entity_uuid", entityUuid);
    formData.append("deletion_entity_type", questionIdentifier);
    formData.append("authenticity_token", document.querySelector("input[name=\"authenticity_token\"]")?.value);

    const response = await fetch(deleteUrl, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return { ...data, questionIdentifier, groupIndex };
  },
);

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    initializeState: (state, action) => ({ ...state, ...action.payload }),
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    updateField: (state, action) => {
      const {
        questionIdentifier, groupIndex, childQuestionIdentifier, value,
      } = action.payload;

      if (groupIndex !== undefined && groupIndex !== null) {
        state.questions[questionIdentifier].groups[groupIndex][
          childQuestionIdentifier
        ].value = value;
      } else if (childQuestionIdentifier) {
        state.questions[questionIdentifier].group[
          childQuestionIdentifier
        ].value = value;
      } else {
        state.questions[questionIdentifier].value = value;
      }
    },
    addEntry: (state, action) => {
      const { questionIdentifier } = action.payload;
      state.questions[questionIdentifier].groups.push(
        state.questions[questionIdentifier].questions,
      );
      state.addedNewEntry = true;
    },
    removeEntry: (state, action) => {
      const { questionIdentifier, groupIndex } = action.payload;
      state.questions[questionIdentifier].groups.splice(groupIndex, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ajaxFileUpload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ajaxFileUpload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questions = action.payload;
      })
      .addCase(ajaxFileUpload.rejected, (state) => {
        state.isLoading = false;
        state.errors = [
          "An error occurred in processing your uploaded file. Please try again or contact the Blitz tech team if issue persists.",
        ];
      })
      .addCase(ajaxUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ajaxUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questions = action.payload;
      })
      .addCase(ajaxUpdate.rejected, (state) => {
        state.isLoading = false;
        state.errors = [
          "An error occurred while updating the form. Please try again or contact the Blitz tech team if issue persists.",
        ];
      })
      .addCase(ajaxDeleteEntity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ajaxDeleteEntity.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          const { questionIdentifier, groupIndex } = action.payload;
          state.questions[questionIdentifier].groups.splice(groupIndex, 1);
        } else {
          state.errors = [
            "An error occurred when attempting to delete that row. Please try again or contact the Blitz tech team if issue persists.",
          ];
        }
      })
      .addCase(ajaxDeleteEntity.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = [
          "An error occurred when attempting to delete that row. Please try again or contact the Blitz tech team if issue persists.",
        ];
      });
  },
});

export const {
  initializeState,
  startLoading,
  stopLoading,
  updateField,
  addEntry,
  removeEntry,
} = formSlice.actions;
export default formSlice.reducer;
