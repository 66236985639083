import Helpers from './helpers.js'

class OfferPage {

  static set_value($element, value) {
    $element.val(value)
    let target_name = $element.attr('name')
    $(`input[name="${target_name}"]`).val(value)
    if ($element.hasClass('propagate_to_entities')) {
      let target_coverage_identifier = $element.data('coverage_identifier')
      let attribute_identifier = $element.data('attribute_identifier')
      $(`#${target_coverage_identifier} select.${attribute_identifier}, #${target_coverage_identifier} input.${attribute_identifier}`).val(value)
    }
  }

  static select_coverage(coverage_identifier, { ignored_identifiers = [] }={}) {
    let coverages_to_update = [coverage_identifier]
    let $target_container = $(`#${coverage_identifier}`)
    let $target_switch = $(`#${coverage_identifier}_switch`)
    $target_switch.prop('checked', true)
    $target_container.addClass('selected')
    OfferPage.select_product($target_switch.data('product_identifier'))
    OfferPage.select_entities_for_container($target_container)
    // available when selected
    OfferPage.handle_revealing_available_on_coverage_selection($target_switch)
    // unavailable when selected
    let disable_outcome = OfferPage.handle_disabling_unavailable_on_coverage_selection($target_switch)
    coverages_to_update = coverages_to_update.concat(disable_outcome.coverages_to_update)
    // required when selected
    if ($target_switch.hasClass('with_required_when_selected')) { // if has any required when selected
      let required_when_selected = $target_switch.data('required_when_selected')
      required_when_selected.forEach(target_identifier => {
        if (ignored_identifiers.includes(target_identifier)) { return }
        // select those coverages
        ignored_identifiers.push(target_identifier)
        let selection_outcome = OfferPage.select_coverage(target_identifier, { ignored_identifiers })
        // add to update list
        coverages_to_update = coverages_to_update.concat(selection_outcome.coverages_to_update)
      })
    }

    return { coverages_to_update }
  }

  static select_entities_for_container($coverage_container) {
    $coverage_container.find('.entity_switch').each((i, element) => {
      let $entity_switch = $(element)
      if ($entity_switch.is(':disabled')) {
        if ($entity_switch.hasClass('with_required_when_not_selected') && $entity_switch.hasClass('with_unavailable_when_selected')) {
          // This entity switch is part of a "requires ONLY one grouping"
          // TODO - formalize this summarized dependency rule better in the product module upgrade
        } else {
          return
        }
      }
      $entity_switch.prop('checked', true)
    })
  }

  static select_product(product_identifier) {
    let $target = $(`#${product_identifier}_is_selected`)
    $target.val('true')
  }

  static deselect_product(product_identifier) {
    let $target = $(`#${product_identifier}_is_selected`)
    $target.val('false')
  }

  static deselect_coverage(coverage_identifier, { ignored_identifiers=[] }={}) {
    let coverages_to_update = [coverage_identifier]
    let $coverage_container = $(`#${coverage_identifier}`)
    let $coverage_switch = $(`#${coverage_identifier}_switch`)
    $coverage_switch.prop('checked', false)
    $coverage_container.removeClass('selected')
    OfferPage.deselect_entities_for_container($coverage_container)
    // available when selected
    if ($coverage_switch.hasClass('with_available_when_selected')) { // if has any available when selected
      // reveal and enable those coverages
      let available_when_selected = $coverage_switch.data('available_when_selected')
      let available_when_selected_ids = available_when_selected.map((i)=>{ return `#${i}.last_coverage`})
      let should_assign_new_last_block = false
      if ($(available_when_selected_ids.join(', '))[0] != undefined) {
        should_assign_new_last_block = true
      }
      available_when_selected.forEach(target_identifier => {
        let $target_container = $(`#${target_identifier}`)
        let $target_switch = $(`#${target_identifier}_switch`)
        if (!OfferPage.coverage_switch_visible($target_switch)) {
          if ($target_switch.is(':checked')) { // if any are selected
            // deselect coverages (unless made available by another)
            ignored_identifiers.push(target_identifier)
            let deselection_outcome = OfferPage.deselect_coverage(target_identifier, { ignored_identifiers })
            // add to update list
            coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
          }
          // conceal and disable those coverages
          OfferPage.disable_coverage(target_identifier)
          OfferPage.hide_container($target_container)
        }
      })
      if (should_assign_new_last_block) {
        setTimeout(()=>{
          $(available_when_selected_ids.join(', ')).removeClass('last_coverage')
          $coverage_container.addClass('last_coverage')
        }, 600)
      }
    }

    // unavailable when selected
    OfferPage.handle_enabling_unavailable_on_coverage_deselection($coverage_switch)

    // required when selected
    if ($coverage_switch.hasClass('with_required_when_selected')) { // if has any required when selected
      let required_when_selected = $coverage_switch.data('required_when_selected')
      // deselect those coverages
      required_when_selected.forEach(target_identifier => {
        if (ignored_identifiers.includes(target_identifier)) { return }
        ignored_identifiers.push(target_identifier)
        let deselection_outcome = OfferPage.deselect_coverage(target_identifier, { ignored_identifiers })
        // add to update list
        coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
      })
    }

    // required when not selected
    if ($coverage_switch.hasClass('with_required_when_not_selected')) { // if has any required when not selected
      let required_when_not_selected = $coverage_switch.data('required_when_not_selected')
      if (!OfferPage.coverage_switch_at_least_one_requirement_satisfied($coverage_switch)) { // if none are selected
        // select those coverages
        required_when_not_selected.forEach(target_identifier => {
          let ignored_identifiers = [coverage_identifier]
          let selection_outcome = OfferPage.select_coverage(target_identifier, { ignored_identifiers })
          // add to update list
          coverages_to_update = coverages_to_update.concat(selection_outcome.coverages_to_update)
        })
      } else if ($coverage_switch.hasClass('with_entities')) {
        $coverage_container.find('.entity_switch').each((i, element) => {
          let $entity_switch = $(element)

          // if none are selected
          if (!OfferPage.entity_switch_at_least_one_requirement_satisfied($entity_switch)) {
            // select them all
            required_when_not_selected.forEach(target_identifier => {
              let entity_key = $entity_switch.data('entity_key')
              let selection_outcome = OfferPage.select_entity_for_coverage(target_identifier, entity_key)
              // add to update list
              coverages_to_update = coverages_to_update.concat(selection_outcome.coverages_to_update)
            })
          }
        })
      }
    }

    var should_deselect_product = true
    $(`#product_${$coverage_switch.data('product_identifier')} .coverage_switch`).each((i, element) => {
      let $element = $(element)
      if ($element.is(':checked')) {
        should_deselect_product = false
      }
    })
    if (should_deselect_product) {
      OfferPage.deselect_product($coverage_switch.data('product_identifier'))
    }

    return { coverages_to_update }
  }

  static deselect_entities_for_container($coverage_container) {
    $coverage_container.find('.entity_switch').each((i, element) => {
      let $entity_switch = $(element)
      $entity_switch.prop('checked', false)
    })
  }

  static select_entity_for_coverage(coverage_identifier, entity_key, { ignored_identifiers=[] }={}) {
    let coverages_to_update = [coverage_identifier]
    let $target_container = $(`#${coverage_identifier}`)
    let $target_coverage_switch = $(`#${coverage_identifier}_switch`)
    let $target_entity_switch = $(`#${coverage_identifier}_entity_${entity_key}_switch`)
    $target_entity_switch.prop('checked', true)
    $target_coverage_switch.prop('checked', true)
    $target_container.addClass('selected')

    // available when selected
    OfferPage.handle_revealing_available_on_entity_selection($target_entity_switch)
    // unavailable when selected
    OfferPage.handle_disabling_unavailable_on_entity_selection($target_entity_switch)
    // required when selected
    if ($target_entity_switch.hasClass('with_required_when_selected')) {
      let required_when_selected = $target_entity_switch.data('required_when_selected')
      required_when_selected.forEach(target_identifier => {
        if (ignored_identifiers.includes(target_identifier)) { return }
        ignored_identifiers.push(target_identifier)
        let selection_outcome = OfferPage.select_entity_for_coverage(target_identifier, entity_key, { ignored_identifiers })
        coverages_to_update = coverages_to_update.concat(selection_outcome.coverages_to_update)
      })
    }
    return { coverages_to_update }
  }

  static deselect_entity_for_coverage(coverage_identifier, entity_key, { ignored_identifiers=[] }={}) {
    let coverages_to_update = [coverage_identifier]
    let $coverage_container = $(`#${coverage_identifier}`)
    let $entity_switch = $(`#${coverage_identifier}_entity_${entity_key}_switch`)
    $entity_switch.prop('checked', false)
    if (OfferPage.all_entities_deselected_for_container($coverage_container)) {
      return OfferPage.deselect_coverage(coverage_identifier)
    }
    // available when selected
    if ($entity_switch.hasClass('with_available_when_selected')) {
      let available_when_selected = $entity_switch.data('available_when_selected')
      available_when_selected.forEach(target_identifier => {
        let $target_entity_switch = $(`#${target_identifier}_entity_${entity_key}_switch`)
        let $target_container = $(`#${target_identifier}`)
        let $target_coverage_switch = $(`#${target_identifier}_switch`)

        if (!OfferPage.entity_switch_available($target_entity_switch)) {
          if ($target_entity_switch.is(':checked')) {
            let deselection_outcome = OfferPage.deselect_entity_for_coverage(target_identifier, entity_key)
            coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
          }
          $target_entity_switch.prop('disabled', true)

          if (!OfferPage.coverage_switch_visible($target_coverage_switch)) {
            OfferPage.disable_coverage(target_identifier)
            OfferPage.hide_container($target_container)
          }
        }
      })
    }
    // unavailable when selected
    OfferPage.handle_enabling_unavailable_on_entity_deselection($entity_switch)

    // required when selected
    if ($entity_switch.hasClass('with_required_when_selected')) {
      let required_when_selected = $entity_switch.data('required_when_selected')
      required_when_selected.forEach(target_identifier => {
        if(ignored_identifiers.includes(target_identifier)) { return }
        ignored_identifiers.push(target_identifier)
        let deselection_outcome = OfferPage.deselect_entity_for_coverage(target_identifier, entity_key, { ignored_identifiers})
        coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
      })
    }
    // required when not selected
    if ($entity_switch.hasClass('with_required_when_not_selected')) {
      // check if any are still selected
      if (!OfferPage.entity_switch_at_least_one_requirement_satisfied($entity_switch)) { // if none are selected
        // select them all
        let required_when_not_selected = $entity_switch.data('required_when_not_selected')
        required_when_not_selected.forEach(target_identifier => {
          if (ignored_identifiers.includes(target_identifier)) { return }
          ignored_identifiers.push(target_identifier)
          let selection_outcome = OfferPage.select_entity_for_coverage(target_identifier, entity_key, { ignored_identifiers })
          // add to update list
          coverages_to_update = coverages_to_update.concat(selection_outcome.coverages_to_update)
        })
      }
    }

    return { coverages_to_update }
  }

  static handle_disabling_unavailable_on_coverage_selection($coverage_switch) {
    let coverages_to_update = []
    if ($coverage_switch.hasClass('with_unavailable_when_selected')) { // if has any unavailable when selected
      let unavailable_when_selected = $coverage_switch.data('unavailable_when_selected')
      unavailable_when_selected.forEach(target_identifier => {
        let $target_switch = $(`#${target_identifier}_switch`)
        let $target_container = $(`#${target_identifier}`)
        if ($target_switch.is(':checked')) { // if coverages are already selected
          if ($target_switch.hasClass('with_entities')) {
            // means pre-existing partial selection
            // disable any entity switches that aren't selected already
            OfferPage.disable_entities_for_container($target_container)
          } else {
            // This used to not be possible since we disabled unavailable when selected coverages
            // But now if a coverage has unavailable when selected settings AND requires at least one settings, we keep it
            // enabled to allow toggling between coverages
            // TODO - clean up when we formalize "requires ONLY one" grouping rules
          }
        }
        // deselect coverages
        if (OfferPage.coverage_switch_unavailable($target_switch)) {

          let deselection_outcome = OfferPage.deselect_coverage($target_switch.data('coverage_identifier'))
          coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
        } else if ($target_switch.hasClass('with_entities')) {
          $target_container.find('.entity_switch').each((i, element) => {
            let $entity_switch = $(element)
            if (!OfferPage.entity_switch_available($entity_switch)) {
              $entity_switch.prop('checked', false)
            }
          })
        }
      })
    }

    return { coverages_to_update }
  }

  static handle_disabling_unavailable_on_entity_selection($entity_switch) {
    if ($entity_switch.hasClass('with_unavailable_when_selected')) {
      let entity_key = $entity_switch.data('entity_key')
      let unavailable_when_selected = $entity_switch.data('unavailable_when_selected')
      unavailable_when_selected.forEach(target_identifier => {
        let $target_coverage_switch = $(`#${target_identifier}_switch`)
        let $target_entity_switch = $(`#${target_identifier}_entity_${entity_key}_switch`)

        // deselect entity switch for that coverage
        $target_entity_switch.prop('checked', false)

        if (OfferPage.coverage_switch_unavailable($target_coverage_switch)) {
          OfferPage.deselect_coverage(target_identifier)
        }
      })
    }
  }

  static handle_revealing_available_on_coverage_selection($coverage_switch) {
    if ($coverage_switch.hasClass('with_available_when_selected')) { // if has any available when selected
      let $coverage_block = $coverage_switch.closest('.offer_coverage_block')
      let should_assign_new_last_block = false
      if ($coverage_block.hasClass('last_coverage')) {
        should_assign_new_last_block = true
        $coverage_block.removeClass('last_coverage')
      }
      // reveal and enable those coverages
      let available_when_selected = $coverage_switch.data('available_when_selected')
      if (should_assign_new_last_block) {
        let available_when_selected_ids = available_when_selected.map((i)=>{ return `#${i}`})
        let $last_block = $(available_when_selected_ids.join(', ')).last()
        $last_block.addClass('last_coverage')
      }
      available_when_selected.forEach(target_identifier => {
        let $target_container = $(`#${target_identifier}`)
        OfferPage.show_container($target_container)
        // enable switch for that coverage (unless still blocked by something else)
        OfferPage.enable_coverage(target_identifier)
      })
    }
  }

  static handle_revealing_available_on_entity_selection($entity_switch) {
    if ($entity_switch.hasClass('with_available_when_selected')) {
      let entity_key = $entity_switch.data('entity_key')
      let available_when_selected = $entity_switch.data('available_when_selected')
      available_when_selected.forEach(target_identifier => {
        let $target_container = $(`#${target_identifier}`)
        // reveal the container
        OfferPage.show_container($target_container)
        OfferPage.enable_entity_for_coverage(target_identifier, entity_key)
      })
    }
  }

  static handle_enabling_unavailable_on_coverage_deselection($coverage_switch) {
    if ($coverage_switch.hasClass('with_unavailable_when_selected')) { // if has any unavailable when selected
      let unavailable_when_selected = $coverage_switch.data('unavailable_when_selected')
      unavailable_when_selected.forEach(target_identifier => {
        OfferPage.enable_coverage(target_identifier)
      })
    }
  }

  static handle_enabling_unavailable_on_entity_deselection($entity_switch) {
    if ($entity_switch.hasClass('with_unavailable_when_selected')) {
      let entity_key = $entity_switch.data('entity_key')
      let unavailable_when_selected = $entity_switch.data('unavailable_when_selected')
      unavailable_when_selected.forEach(target_identifier => {
        OfferPage.enable_entity_for_coverage(target_identifier, entity_key)
      })
    }
  }

  static enable_coverage(coverage_identifier, { and_entities=true, ignored_identifiers=[] }={}) {
    let $target_container = $(`#${coverage_identifier}`)
    let $target_switch = $(`#${coverage_identifier}_switch`)
    if (!OfferPage.coverage_switch_unavailable($target_switch)) {
      // enabled switches should have value true while hidden inputs have value false
      $(`input[name="${$target_switch.attr('name')}"]`).val('false')
      $target_switch.val('true')
      $target_switch.prop('disabled', false)
      $target_container.removeClass('disabled')
      if (and_entities) {
        OfferPage.enable_entities_for_container($target_container)
      }

      // re-enable required when not selected coverages that were disabled when this coverage was unavailable
      if ($target_switch.hasClass('with_required_when_not_selected')) {
        ignored_identifiers.push(coverage_identifier)
        let required_when_not_selected = $target_switch.data('required_when_not_selected')
        required_when_not_selected.forEach(target_identifier => {
          if (ignored_identifiers.includes(target_identifier)) { return }

          OfferPage.enable_coverage(target_identifier, { and_entities, ignored_identifiers })
        })
      }
    }
  }

  static enable_entities_for_container($coverage_container) {
    $coverage_container.find('.entity_switch').each((i, element) => {
      let $entity_switch = $(element)
      if (OfferPage.entity_switch_available($entity_switch)) {
        $entity_switch.prop('disabled', false)
      }
    })
  }

  static disable_coverage(coverage_identifier, { and_entities=true, ignored_identifiers=[] }={}) {
    let $target_container = $(`#${coverage_identifier}`)
    let $target_switch = $(`#${coverage_identifier}_switch`)
    let is_selected = $target_switch.is(':checked')
    $(`input[name="${$target_switch.attr('name')}"]`).val(is_selected)
    $target_switch.prop('disabled', true)
    $target_container.addClass('disabled')
    if (and_entities) {
      OfferPage.disable_entities_for_container($target_container)
    }

    if ($target_switch.hasClass('with_required_when_not_selected')) {
      ignored_identifiers.push(coverage_identifier)
      let required_when_not_selected = $target_switch.data('required_when_not_selected')
      required_when_not_selected.forEach(target_identifier => {
        if (ignored_identifiers.includes(target_identifier)) { return }
        let $target_switch = $(`#${target_identifier}_switch`)
        if (this.coverage_switch_required($target_switch)) {
          this.disable_coverage(target_identifier, { and_entities, ignored_identifiers})
        }
      })
    }
  }

  static disable_entities_for_container($coverage_container) {
    $coverage_container.find('.entity_switch').each((i, element) => {
      let $entity_switch = $(element)
      if (!$entity_switch.is(':checked')) {
        $entity_switch.prop('disabled', true)
      }
    })
  }

  static all_entities_deselected_for_container($coverage_container) {
    var all_entities_deselected = true
    $coverage_container.find('.entity_switch').each((i, element) => {
      if (!all_entities_deselected) { return }

      let $entity_switch = $(element)
      if ($entity_switch.is(':checked')) {
        all_entities_deselected = false
      }
    })
    return all_entities_deselected
  }

  static all_entities_selected_for_container($coverage_container) {
    var all_entities_selected = true
    $coverage_container.find('.entity_switch').each((i, element) => {
      if (!all_entities_selected) { return }

      let $entity_switch = $(element)
      if (!$entity_switch.is(':checked')) {
        all_entities_selected = false
      }
    })
    return all_entities_selected
  }

  static enable_entity_for_coverage(coverage_identifier, entity_key) {
    let $target_entity_switch = $(`#${coverage_identifier}_entity_${entity_key}_switch`)
    if (OfferPage.entity_switch_available($target_entity_switch)) {
      $target_entity_switch.prop('disabled', false)
      OfferPage.enable_coverage(coverage_identifier, false)
    }
  }

  static coverage_switch_at_least_one_requirement_satisfied($coverage_switch) {
    let required_when_not_selected = $coverage_switch.data('required_when_not_selected')
    // check if any are still selected
    var at_least_one_selected = false
    required_when_not_selected.forEach(target_identifier=>{
      if ($(`#${target_identifier}_switch`).is(':checked')) {
        at_least_one_selected = true
      }
    })
    return at_least_one_selected
  }

  static entity_switch_at_least_one_requirement_satisfied($entity_switch) {
    let entity_key = $entity_switch.data('entity_key')
    let required_when_not_selected = $entity_switch.data('required_when_not_selected')
    // check if any are still selected
    var at_least_one_selected = false
    required_when_not_selected.forEach(target_identifier => {
      if (at_least_one_selected) { return }

      if ($(`#${target_identifier}_entity_${entity_key}_switch`).is(':checked')) {
        at_least_one_selected = true
      }
    })
    return at_least_one_selected
  }

  static coverage_switch_visible($coverage_switch) {
    var coverage_visible = false
    let target_made_available_by = $coverage_switch.data('made_available_by')
    if (target_made_available_by == undefined) {
      return true
    }
    target_made_available_by.forEach(identifier => {
      if ($(`#${identifier}_switch`).is(':checked')) {
        coverage_visible = true
      }
    })
    return coverage_visible
  }

  static hide_container($coverage_container) {
    $coverage_container.addClass('hidden_with_transition')
    setTimeout(() => {
      $coverage_container.slideUp()
    }, 200)
  }

  static show_container($coverage_container) {
    if ($coverage_container.hasClass('hidden_with_transition')) {
      $coverage_container.slideDown()
      setTimeout(()=>{
        $coverage_container.removeClass('hidden_with_transition')
      }, 400)
    }
  }

  static entity_switch_available($entity_switch) {
    let entity_key = $entity_switch.data('entity_key')
    var entity_available = false
    let target_made_available_by = $entity_switch.data('made_available_by')
    if (target_made_available_by == undefined) {
      entity_available = true
    } else {
      target_made_available_by.forEach(parent_identifier => {
        if (entity_available) { return }

        if ($(`#${parent_identifier}_entity_${entity_key}_switch`).is(':checked')) {
          entity_available = true
        }
      })
    }
    if ($entity_switch.hasClass('with_unavailable_when_selected')) {
      let unavailable_when_selected = $entity_switch.data('unavailable_when_selected')
      unavailable_when_selected.forEach(blocking_identifier => {
        if ($(`#${blocking_identifier}_entity_${entity_key}_switch`).is(':checked')) {
          entity_available = false
        }
      })
    }
    return entity_available
  }

  static coverage_switch_unavailable($coverage_switch) {
    if ($coverage_switch.hasClass('with_unavailable_when_selected')) {
      let target_unavailable_when_selected = $coverage_switch.data('unavailable_when_selected')
      var unavailable = false
      target_unavailable_when_selected.forEach(blocking_identifier => {
        if (unavailable) { return }

        let $blocking_switch = $(`#${blocking_identifier}_switch`)
        if ($blocking_switch.is(':checked')) {
          if ($blocking_switch.hasClass('with_entities')) {
            let $blocking_container = $(`#${blocking_identifier}`)
            if (OfferPage.all_entities_selected_for_container($blocking_container)) {
              unavailable = true
            }
          } else {
            unavailable = true
          }
        }
      })
      return unavailable
    } else {
      return false
    }
  }

  static coverage_switch_required($coverage_switch) {
    // returns true if coverage is the only one available out of an at least one grouping
    if ($coverage_switch.hasClass('with_required_when_not_selected')) {
      let required_when_not_selected = $coverage_switch.data('required_when_not_selected')
      var required = true
      required_when_not_selected.forEach(identifier => {
        if (!this.coverage_switch_unavailable($(`#${identifier}_switch`))) {
          required = false
        }
      })
      return required
    } else {
      return false
    }
  }

  static handle_switch_click(e) {
    OfferPage.start_loading()

    let $switch = $(e.target)
    let product_identifier = $switch.data('product_identifier')
    let coverage_identifier = $switch.data('coverage_identifier')
    let $coverage_container = $(`#${coverage_identifier}`)
    // add coverage to update list
    let coverages_to_update = [coverage_identifier]
    // determine kind of switch clicked
    if ($switch.hasClass('coverage_switch')) {
      if ($switch.is(':checked')) { // if selected
        let ignored_identifiers = [coverage_identifier]
        let selection_outcome = OfferPage.select_coverage(coverage_identifier, { ignored_identifiers })
        // add to update list
        coverages_to_update = coverages_to_update.concat(selection_outcome.coverages_to_update)
      } else { // if deselected
        let deselection_outcome = OfferPage.deselect_coverage(coverage_identifier)
        coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
      }

    } else if ($switch.hasClass('entity_switch')) { // if entity switch
      let entity_key = $switch.data('entity_key')
      if ($switch.is(':checked')) { // if selected
        let ignored_identifiers = [coverage_identifier]
        let selection_outcome = OfferPage.select_entity_for_coverage(coverage_identifier, entity_key, { ignored_identifiers })
        coverages_to_update = coverages_to_update.concat(selection_outcome.coverages_to_update)
      } else { // if deselected
        if (OfferPage.all_entities_deselected_for_container($coverage_container)) {
          // deselecting the last entity switch has the same effect as deselecting the whole coverage
          let deselection_outcome = OfferPage.deselect_coverage(coverage_identifier)
          coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
        } else {
          let deselection_outcome = OfferPage.deselect_entity_for_coverage(coverage_identifier, entity_key)
          coverages_to_update = coverages_to_update.concat(deselection_outcome.coverages_to_update)
        }
      }
    } else {
      console.error("encountered bad switch", $switch)
    }
    coverages_to_update = [...new Set(coverages_to_update)]
    OfferPage.update_coverages(product_identifier, coverages_to_update)
  }

  static handle_select_change(e) {
    OfferPage.start_loading()

    let $select = $(e.target)
    let product_identifier = $select.data('product_identifier')
    let coverage_identifier = $select.data('coverage_identifier')
    let attribute_identifier = $select.data('attribute_identifier')
    let entity_key = $select.data('entity_key')

    if (entity_key == undefined) { // coverage select
      if ($select.hasClass('propagate_to_entities')) {
        $(`#${coverage_identifier} select.${attribute_identifier}, #${coverage_identifier} input.${attribute_identifier}`).val($select.val())
      }
    }

    OfferPage.update_coverages(product_identifier, [coverage_identifier])
  }

  static handle_text_input_blur(e) {
    OfferPage.start_loading()

    let $input = $(e.target)
    let product_identifier = $input.data('product_identifier')
    let coverage_identifier = $input.data('coverage_identifier')
    let attribute_identifier = $input.data('attribute_identifier')
    let entity_key = $input.data('entity_key')

    if (entity_key == undefined) { // coverage input
      if ($input.hasClass('propagate_to_entities')) {
        $(`#${coverage_identifier} input.${attribute_identifier}`).val($input.val())
      }
    }

    OfferPage.update_coverages(product_identifier, [coverage_identifier])
  }

  static update_coverages(product_identifier, coverage_identifiers) {
    let $form = $('#offer_form')
    let url = $form.attr('ajax')
    let form_data = new FormData($form[0])
    form_data.append('product_identifier', product_identifier)
    for (let index = 0; index < coverage_identifiers.length; index++) {
      form_data.append(`coverage_identifiers[${index}]`, coverage_identifiers[index])
    }

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        setTimeout(()=>{
          Object.entries(res.partials).forEach(key_value => {
            let [coverage_identifier, coverage_container_html] = key_value
            $(`#${coverage_identifier}`).html(coverage_container_html)
            $(`#${coverage_identifier} input.currency`).each((i, element) =>{
              Helpers.formatDollars($(element))
            })
          })
          let summary_html = res.partials.offer_summary
          $('#offer_summary').html(summary_html)
          $('[data-bs-toggle="tooltip"]').tooltip()

          let renewal_changes_html = res.partials.renewal_coverage_changes
          if (renewal_changes_html != undefined) {
            $('#renewal_coverage_changes').html(renewal_changes_html)
          }

          setTimeout(()=>{
            OfferPage.stop_loading()
          }, 10)
        }, 450)
      },
      failure: (res) => {
        console.error(res)
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Failed to post form data for update_coverages!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)

        var error_message = res?.responseJSON?.error_message ?? "Something went wrong. Please refresh the page and contact the Blitz tech team if problem persists.";
        alert(error_message)
        OfferPage.stop_loading()
      }
    })
  }

  static handle_coverage_fold(e) {
    let $clicked = $(e.target)
    let coverage_identifier = $clicked.data('coverage_identifier')
    if ($clicked.is(':checked')) {
      $(`#${coverage_identifier} .coverage_entity_table_wrapper`).slideUp()
    } else {
      $(`#${coverage_identifier} .coverage_entity_table_wrapper`).slideDown()
    }
  }

  static handle_summary_fold(e) {
    let $clicked = $(e.target)
    let product_identifier = $clicked.data('product_identifier')
    if ($clicked.is(':checked')) {
      $(`#summary_${product_identifier}_coverages`).slideUp()
    } else {
      $(`#summary_${product_identifier}_coverages`).slideDown()
    }
  }

  static start_loading() {
    let $form = $('#offer_form')
    $form.addClass('loading')
  }

  static stop_loading() {
    let $form = $('#offer_form')
    $form.removeClass('loading')
  }
}

$(()=>{

  // handle clicks of switches
  $(document).on('click', '.offer_wrapper .switch_input', OfferPage.handle_switch_click)

  // handle change of selects
  $(document).on('change', '.offer_wrapper select', OfferPage.handle_select_change)

  // handle blur of text inputs
  $(document).on('blur', '.offer_wrapper input.text_input', (e) => { Helpers.enforce_min_max(e.target) }) // not specific to offer page
  $(document).on('blur', '.offer_wrapper input.text_input', OfferPage.handle_text_input_blur)

  // handle coverage and summary folding
  $(document).on('click', '.offer_wrapper .offer_coverage_block label.fold_button input', OfferPage.handle_coverage_fold)
  $(document).on('click', '.offer_wrapper #offer_summary label.fold_button input', OfferPage.handle_summary_fold)

  $(document).on('click', '.offer_wrapper #offer_submit_button', (e)=>{
    OfferPage.start_loading()
    let $effective_date_selector = $('#effective_date_input')
    if ($effective_date_selector.val() == $effective_date_selector.data('requested_effective_date')) {
      // If the producer didn't change the effective date, disable the input to prevent the min validation from firing
      // The value will flow through via the hidden input instead
      $effective_date_selector.prop('disabled', true)
    }
  })
})
