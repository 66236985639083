$(()=>{

  if ($('#open_quotes_grid_assigned_to_me').is(':checked')) {
    $('#open_quotes_grid_admin_assigned').val([]).trigger('change');
    $('#open_quotes_grid_admin_assigned_select_and_label').addClass('hidden');
    $('#open_quotes_grid_primary_bd').val([]).trigger('change');
    $('#open_quotes_grid_primary_bd_select_and_label').addClass('hidden');
  }

  // only do this setup if the date range picker is present
  if($("#effective_date_range").length) {
    var start = null;
    var start_value = $('#effective_date_start').val();
    if(start_value != ''){
      start = moment(start_value);
    }
    else {
      start = moment().Today;
    }

    var end = null;
    var end_value = $('#effective_date_end').val();
    if(end_value != ''){
      end = moment(end_value);
    }
    else {
      end = moment().Today;
    }

    $("#effective_date_range").daterangepicker({
      startDate: start,
      endDate: end,
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD',
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Next 7 Days': [moment().add(6, 'days'), moment()],
        'Next 30 Days': [moment().add(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
    }
    });

    if(start && end) {
      $('#effective_date_range').val(start_value + ' - ' + end_value);
    }
    else {
      $('#effective_date_range').val('');
    }

    $('#effective_date_range').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));

      $('#effective_date_start').val(picker.startDate.format('YYYY-MM-DD'))
      $('#effective_date_end').val(picker.endDate.format('YYYY-MM-DD'))

      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );
    });

    $('#effective_date_range').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
      $('#effective_date_start').val(null)
      $('#effective_date_end').val(null)

      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );
    });

    $('#open_quotes_grid_company_id').on('change', function(ev, picker) {
      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );
    });

    $('#open_quotes_grid_producer_uuid').on('change', function(ev, picker) {
      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );
    });

    $('#open_quotes_grid_application_type').on('change', function(ev, picker) {
      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );
    });

    $('#open_quotes_grid_admin_assigned').on('change', function(ev, picker) {
      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );
    });

    $('#open_quotes_grid_primary_bd').on('change', function(ev, picker) {
      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );
    });

    $('#open_quotes_grid_assigned_to_me').on('change', function(ev, picker) {
      $("#new_open_quotes_grid_open_quotes_grid").trigger( "submit" );

      if ($('#open_quotes_grid_assigned_to_me').is(':checked')) {
        $('#open_quotes_grid_admin_assigned').val([]).trigger('change');
        $('#open_quotes_grid_admin_assigned_select_and_label').addClass('hidden');
        $('#open_quotes_grid_primary_bd').val([]).trigger('change');
        $('#open_quotes_grid_primary_bd_select_and_label').addClass('hidden');
      }

      if ($('#open_quotes_grid_assigned_to_me').not(':checked')) {
        $('#open_quotes_grid_admin_assigned_select_and_label').removeClass('hidden');
        $('#open_quotes_grid_primary_bd_select_and_label').removeClass('hidden');
      }

    });


  }
})