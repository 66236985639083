$(()=>{

  $('#product_selection .product_button input').on('click', (e)=>{
    let $clicked = $(e.target)
    let product_identifier = $clicked.attr('id')
    let coverage_container_id = `#${product_identifier}_coverages`
    // console.log(coverage_container_id)
    let $target = $(coverage_container_id)
    // console.log($target)
    if ($clicked.is(':checked')) {
      // $target.show()
      $(`${coverage_container_id} input`).prop('checked', true)
      $(`${coverage_container_id} .coverage_button`).addClass((i) =>{
        return "transition_delay_" + i
      })
      setTimeout(()=>{
        $(`${coverage_container_id} .coverage_button`).removeClass((i) =>{
          return "transition_delay_" + i
        })
      }, 400)

      $(`${coverage_container_id} .selectable`).addClass('selected')
      $(`${coverage_container_id} .selectable`).removeClass('hidden_with_transition')
      $(`${coverage_container_id} .selectable`).removeClass('last_coverage')
      $(`${coverage_container_id} .selectable`).show()
      $(`${coverage_container_id} .selectable`).last().addClass('last_coverage')


      $target.slideDown()
    } else {
      // $target.hide()
      $(`${coverage_container_id} input`).prop('checked', false)
      $(`${coverage_container_id} .coverage_button`).addClass((i) =>{
        return "transition_delay_" + i
      })
      setTimeout(()=>{
        $(`${coverage_container_id} .coverage_button`).removeClass((i) =>{
          return "transition_delay_" + i
        })
      }, 400)
      $(`${coverage_container_id} .selectable`).removeClass('selected')
      $(`${coverage_container_id} .selectable.nested_coverage_1, ${coverage_container_id} .selectable.nested_coverage_2`).addClass('hidden_with_transition')

      setTimeout(()=>{
        $(`${coverage_container_id} .selectable.nested_coverage_1, ${coverage_container_id} .selectable.nested_coverage_2`).slideUp()
      }, 450)


      setTimeout(()=>{
        $target.slideUp()
      }, 500)

    }
  })

  $('#product_selection .coverage_button, .product_button input').on('change', (e)=>{
    let $clicked = $(e.target)
    // console.log($clicked)
    if ($clicked.is(':checked')) {
      $clicked.closest('.selectable').addClass('selected')
    } else {
      $clicked.closest('.selectable').removeClass('selected')
    }
  })

  $('#product_selection .product_button input').on('change', (e)=>{
    let $clicked = $(e.target)

    if ($clicked.is(':checked')) {
      $clicked.closest('.product_group').addClass('selected')
    } else {
      $clicked.closest('.product_group').removeClass('selected')
    }
  })

  function coverage_unavailable(coverage_identifier) {
    let $switch = $(`#${coverage_identifier}`)

    var available = false
    let made_available_by = $switch.data('made_available_by')
    if (made_available_by == undefined) {
      available = true
    } else {
      made_available_by.forEach(enabling_identifier => {
        if (available) { return }

        if ($(`#${enabling_identifier}`).is(':checked')) {
          available = true
        }
      })
    }
    return !available
  }


  function enable_coverage(coverage_identifier) {
    let $switch = $(`#${coverage_identifier}`)
    if (!coverage_unavailable(coverage_identifier)) {
      $switch.prop('disabled', false)
    }
  }

  function disable_coverage(coverage_identifier, { ignored=[] }={}) {
    let $switch = $(`#${coverage_identifier}`)

    if (coverage_unavailable(coverage_identifier)) {
      ignored.push(coverage_identifier)
      deselect_coverage(coverage_identifier, { ignored })
      $switch.prop('disabled', true)
    }
  }

  function select_coverage(coverage_identifier, {ignored=[]}={}) {
    let $switch =  $(`#${coverage_identifier}`)
    $switch.prop('checked', true)
    $switch.closest('.selectable').addClass('selected')
    ignored.push(coverage_identifier)

    if ($switch.hasClass('with_available_when_selected')) {

      let available_when_selected = $switch.data('available_when_selected')
      let available_when_selected_ids = available_when_selected.map((i) => { return `#${i}_label`})
      if ($switch.closest('.selectable').hasClass('last_coverage')) {
        $switch.closest('.selectable').removeClass('last_coverage')
        let $last_block = $(available_when_selected_ids.join(', ')).last()
        $last_block.addClass('last_coverage')
      }
      $(available_when_selected_ids.join(', ')).addClass((i) =>{
        return "transition_delay_" + i
      })
      setTimeout(()=>{
        $(available_when_selected_ids.join(', ')).removeClass((i) =>{
          return "transition_delay_" + i
        })
      }, 600)
      available_when_selected.forEach(target_identifier => {

        reveal_coverage(target_identifier)
        enable_coverage(target_identifier)
        setTimeout(()=>{
          select_coverage(target_identifier)
        },200)
      })
    }

    if ($switch.hasClass('with_required_when_selected')) {
      let required_when_selected = $switch.data('required_when_selected')
      required_when_selected.forEach(target_identifier => {
        if (ignored.includes(target_identifier)) { return }

        select_coverage(target_identifier, { ignored })
      })
    }
  }

  function deselect_coverage(coverage_identifier, {ignored=[]}={}) {
    let $switch = $(`#${coverage_identifier}`)
    $switch.prop('checked', false)
    $switch.closest('.selectable').removeClass('selected')
    ignored.push(coverage_identifier)

    if ($switch.hasClass('with_required_when_not_selected')) {
      let required_when_not_selected = $switch.data('required_when_not_selected')
      var at_least_one_selected = false
      required_when_not_selected.forEach(target_identifier=>{
        if ($(`#${target_identifier}`).is(':checked')) {
          at_least_one_selected = true
        }
      })
      if (!at_least_one_selected) {
        required_when_not_selected.forEach(target_identifier => {
          select_coverage(target_identifier, { ignored })
        })
      }
    }

    if ($switch.hasClass('with_available_when_selected')) {
      let available_when_selected = $switch.data('available_when_selected')
      let available_when_selected_ids = available_when_selected.map((i) => { return `#${i}_label`})
      let last_coverage_ids = available_when_selected_ids.map(i=>{ return `${i}.last_coverage`})
      if ($(last_coverage_ids.join(', '))[0] != undefined) {
        setTimeout(()=>{
          $(last_coverage_ids.join(', ')).removeClass('last_coverage')
          setTimeout(()=>{
            $switch.closest('.selectable').addClass('last_coverage')
          }, 300)
        }, 800)
      }
      let count = $(available_when_selected_ids.reverse().join(', ')).length
      $(available_when_selected_ids.reverse().join(', ')).addClass((i) =>{
        return "transition_delay_" + (count - 1 - i)
      })
      setTimeout(()=>{
        $(available_when_selected_ids.reverse().join(', ')).removeClass((i) =>{
          return "transition_delay_" + (count - 1 - i)
        })
      }, 800)
      available_when_selected.forEach(target_identifier => {
        disable_coverage(target_identifier, { ignored })
        setTimeout(()=>{
          conceal_coverage(target_identifier)
        },100)
      })
    }

    if ($switch.hasClass('with_required_when_selected')) {
      let required_when_selected = $switch.data('required_when_selected')
      required_when_selected.forEach(target_identifier => {
        if (ignored.includes(target_identifier)) { return }

        deselect_coverage(target_identifier, { ignored })
      })
    }
  }

  function reveal_coverage(coverage_identifier) {
    let $label = $(`#${coverage_identifier}_label`)
    if ($label.hasClass('hidden_with_transition')) {
      $label.slideDown()
      setTimeout(()=>{
        $label.removeClass('hidden_with_transition')
      }, 400)
    }
  }

  function conceal_coverage(coverage_identifier) {
    let $label = $(`#${coverage_identifier}_label`)
    $label.addClass('hidden_with_transition')
    setTimeout(()=>{
      $label.slideUp()
    }, 700)
  }

  function deselect_product_if_no_coverages_selected(product_identifier) {
    let $coverage_switches = $(`.coverage_switch[data-product_identifier="${product_identifier}"]`)
    var all_deselected = true
    $coverage_switches.each((i, element) => {
      if (!all_deselected) { return }

      let $switch = $(element)
      if ($switch.is(':checked')) {
        all_deselected = false
      }
    })

    if (all_deselected) {
      deselect_product(product_identifier)
    }
  }

  function deselect_product(product_identifier) {
    let $switch = $(`#${product_identifier}`)
    $switch.prop('checked', false)
    $switch.closest('.selectable').removeClass('selected')
    $switch.closest('.product_group').removeClass('selected')
    setTimeout(()=>{
      $(`#${product_identifier}_coverages`).slideUp()
    }, 500)
  }

  $('#product_selection .coverage_button .coverage_switch').on('click', (e) => {
    let $clicked = $(e.target)
    let coverage_identifier = $clicked.data('coverage_identifier')
    if ($clicked.is(':checked')) {
      select_coverage(coverage_identifier)
    } else {
      deselect_coverage(coverage_identifier)
      deselect_product_if_no_coverages_selected($clicked.data('product_identifier'))
    }
  })

})
