$(document).ready(function() {
  if( !$('#topic_selection').length ) {return;}
  $.getTemplate();

  $('#topic_selection').change(function() {
    $.getTemplate();
  });

});

$.getTemplate = function() {
  var selected_option = $('#topic_selection').val();
  if (!selected_option) {selected_option = "other";}
  var selected_option_key = selected_option.trim().toLowerCase().replace(/\s+/g, '_').replace('-', '_');
  let url = $('#topic_selection').data('ajax_route');
  if (!url) {url = $('#topic_selection').data('ajaxRoute');}
  if (!url) {return;}
  if (url.indexOf('topic=') === -1) {
    url += '&topic=' + selected_option_key;
  }

  $.ajax({
    url: url,
    method: 'GET',
    dataType: 'json',
    success: function(response) {

      if (!(response && response.template && response.template.body)) {return;}
      if ($('body_text_area').val() == response.template.body) {return;}
      window.template = response.template;
      if (window.template) {
        $('#body_text_area').val(window.template.body);
        $('#subject_text_area').val(window.template.subject);
      }
    },
    error: function(xhr, status, error) {
      if (!(xhr && xhr.responseText)) 
      {
        window.Honeybadger.notify(`Error in Email Template!\nxhr: ${xhr}\nurl: ${url}\nselected_option: ${selected_option}`)
        return;
      }      var errorMessage = JSON.parse(xhr.responseText).error;
      console.log("Error message:", errorMessage);
    }})
};