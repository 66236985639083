import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { FormContext } from "../../Shared/StatelessComponents/FormContext";
import QuestionGroupLabel from "./QuestionGroupLabel";
import QuestionGroupEntry from "./QuestionGroupEntry";

// This component renders multiple entry question groups
// it includes the labels, headers and add buttons

function MultipleEntryQuestionGroup({ question, questionIdentifier }) {
  const { addEntry } = useContext(FormContext);
  const dispatch = useDispatch();
  const handleAddButtonClick = () => {
    dispatch(addEntry({ questionIdentifier }));
  };

  const isHidden =
    useSelector((state) => {
      return state.form.questions[questionIdentifier].hidden;
    }) || false;

  const groups = question.groups;

  return (
    <CSSTransition in={!isHidden} timeout={300} classNames="fade" unmountOnExit>
      <div className="question_group" id={`group_${questionIdentifier}`}>
        <QuestionGroupLabel
          question={question}
          questionIdentifier={questionIdentifier}
          onAddButtonClick={handleAddButtonClick}
        />
        <div className="multiple_entry_container" id={`all-${questionIdentifier}-container`}>
          {groups.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <div className="question_group_entry_header">
                <label>(#{groupIndex + 1})</label>
              </div>
              <QuestionGroupEntry
                question={question}
                group={group}
                questionIdentifier={questionIdentifier}
                groupIndex={groupIndex}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </CSSTransition>
  );
}

MultipleEntryQuestionGroup.propTypes = {
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  questionIdentifier: PropTypes.string.isRequired,
};

export default MultipleEntryQuestionGroup;
