import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";

import { FormContext } from "../StatelessComponents/FormContext";
// stateless components
import DateInput from "../StatelessComponents/DateInput";
import EmailInput from "../StatelessComponents/EmailInput";
import TextInput from "../StatelessComponents/TextInput";
import TextareaInput from "../StatelessComponents/TextareaInput";
import FileInput from "../StatelessComponents/FileInput";
import HiddenInput from "../StatelessComponents/HiddenInput";
import RadioInput from "../StatelessComponents/RadioInput";
import BooleanToggleInput from "../StatelessComponents/BooleanToggleInput";
import AttestationInput from "../StatelessComponents/AttestationInput";
import SelectInput from "../StatelessComponents/SelectInput";
import CurrencyInput from "../StatelessComponents/CurrencyInput";
import NumberInput from "../StatelessComponents/NumberInput";
import CheckboxInput from "../StatelessComponents/CheckboxInput";
import OrderEntitySelectInput from "../StatelessComponents/OrderEntitySelectInput";
import MultiSelectInput from "../StatelessComponents/MultiSelectInput";
// stateful components
import ForeignEntitySelectInput from "./ForeignEntitySelectInput";
import MultiFileInput from "./MultiFileInput";

// This component is responsible for rendering the correct input component
// based on the fieldType
// It also contains the logic for updating the form state and triggering
// ajax pings when input values change

const QuestionInput = ({ fieldType, ...props }) => {
  const value = useSelector((state) => {
    if (props.groupIndex !== undefined && props.groupIndex !== null) {
      return state.form.questions[props.parentQuestionIdentifier].groups[
        props.groupIndex
      ][props.questionIdentifier].value;
    } else if (props.parentQuestionIdentifier) {
      return state.form.questions[props.parentQuestionIdentifier].group[
        props.questionIdentifier
      ].value;
    } else {
      return state.form.questions[props.questionIdentifier].value;
    }
  });

  const isSchedule = useSelector((state) => state.form.isSchedule);

  const [prevValue, setPrevValue] = useState(value);
  const dispatch = useDispatch();

  const { formRef, updateField, ajaxUpdate } = useContext(FormContext);

  const handleBlur = (value) => {
    if (value !== prevValue) {
      const formData = new FormData(formRef.current);
      dispatch(ajaxUpdate(formData));
      setPrevValue(value);
    }
  };

  const handleChange = (value) => {
    dispatch(
      updateField({
        questionIdentifier:
          props.parentQuestionIdentifier || props.questionIdentifier,
        groupIndex: props.groupIndex,
        childQuestionIdentifier:
          props.parentQuestionIdentifier && props.questionIdentifier,
        value,
      }),
    );
  };

  const sharedProps = {
    value,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  switch (fieldType) {
    case "date":
      return <DateInput {...props} {...sharedProps} />;
    case "email":
      return <EmailInput {...props} {...sharedProps} />;
    case "text":
      return <TextInput {...props} {...sharedProps} />;
    case "textarea":
      return <TextareaInput {...props} {...sharedProps} />;
    case "entity_identifier":
      // render the entity identifier as a hidden input
      // alongside a hidden input for entity_active
      return (
        <>
          <HiddenInput {...props} value={value || uuidv4()} />
          <HiddenInput
            fieldType="entity_active"
            questionName={props.questionName.replace(
              "entity_identifier",
              "entity_active",
            )}
            value={"true"}
          />
        </>
      );
    case "radio":
      if (isSchedule) { return <SelectInput {...props} {...sharedProps} />; }
      return <RadioInput {...props} {...sharedProps} />;
    case "boolean":
      return <BooleanToggleInput {...props} {...sharedProps} />;
    case "attestation":
      return <AttestationInput {...props} {...sharedProps} />;
    case "checkbox":
      if (isSchedule) { return <MultiSelectInput {...props} {...sharedProps} />; }
      return <CheckboxInput {...props} {...sharedProps} />;
    case "multi_select":
      return <MultiSelectInput {...props} {...sharedProps} />;
    case "select":
      return <SelectInput {...props} {...sharedProps} />;
    case "float":
      return <NumberInput step="0.01" {...props} {...sharedProps} />;
    case "integer":
      return <NumberInput step="1" {...props} {...sharedProps} />;
    case "currency":
      return <CurrencyInput {...props} {...sharedProps} />;
    case "file":
      return <FileInput {...props} {...sharedProps} />;
    case "multi_file":
      return (
        <MultiFileInput
          {...props}
          value={value}
          onChange={handleChange}
        />
      );
    case "foreign_entity_select":
      return <ForeignEntitySelectInput {...props} {...sharedProps} />;
    case "order_entity_select":
      return <OrderEntitySelectInput {...props} {...sharedProps} />;
    default:
      throw new Error(`Missing implementation for fieldType: ${fieldType}`);
  }
};

QuestionInput.propTypes = {
  fieldType: PropTypes.string.isRequired,
  questionName: PropTypes.string.isRequired,
  questionIdentifier: PropTypes.string.isRequired,
  parentQuestionIdentifier: PropTypes.string,
  groupIndex: PropTypes.number,
};
export default QuestionInput;
