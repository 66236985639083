import AgreementChannel from "../channels/agreement_channel"

/*
This module progressively enhances the #download_indication_letter_button link to perform an AJAX request which
can either return the PDF URL directly or a sidekiq jid to listen for from the AgreementChannel.
*/
$(()=>{
  $('#download_indication_letter_button').on('click', function (e) {
    e.preventDefault()
    const $btn = $(this)
    const $btnSpan = $btn.find('span').first()
    const url = $btn.attr('href')

    const previousText = $btnSpan.text()
    $btnSpan.text('Generating Indication Letter')
    $btn.prop('disabled', true)
    $btn.addClass('disabled')
    const $form = $btn.closest('form')
    if ($form.length) {
      $form.addClass('loading')
    }

    let restored = false
    const restore = (failed=false) => {
      if (restored) {
        return
      }
      $btnSpan.text(previousText)
      $btn.prop('disabled', false)
      $btn.removeClass('disabled')
      if ($form.length) {
        $form.removeClass('loading')
      }
      if (failed) {
        alert("Something went wrong. Please refresh the page and contact the Blitz Tech Team if the problem persists.")
      }
      restored = true
    }

    // 5 min timeout
    setTimeout(()=>{restore(true)}, 300000)

    const req = $.ajax({
      url: url,
      data: { async: true },
      type: 'GET',
      dataType: 'json',
    })

    req.then(function (response) {
      if (response.pdf_url) {
        window.open(response.pdf_url, '_blank')
        restore()
      } else if (response.jid) {
        const handleDownload = (pdfUrl) => {
          if (pdfUrl == undefined) {
            alert("Something went wrong. Please refresh the page and try again. Contact the Blitz Tech Team if the problem persists.")
            restore()
            return
          }
          const url = new URL(pdfUrl, window.location.href)
          // set disposition=attachment to force browser to download
          url.searchParams.set('disposition', 'attachment')
          window.location.href = url.toString()
          restore()
        }

        // TODO - make some kind of accessor on the page to set a data attribute to get the agreement identifier
        // these indication agreement identifiers are both implementation specific, so we'll need to find some way to make this
        // platform feature not include hardcoded implementation identifiers
        AgreementChannel.listen('indication_proposal', response.jid, handleDownload)
        AgreementChannel.listen('indication', response.jid, handleDownload)
      } else if (response.error) {
        if (response.error == 'locked_error') {
          alert("This application is currently processing simultaneous activity. Please wait a few seconds and try again.")
          restore()
        } else {
          alert("Something went wrong. Please refresh the page and try again. Contact the Blitz Tech Team if the problem persists.")
          restore()
        }
      } else {
        // unhandled response
        restore()
      }
    }).catch(function(err) {
      console.error(err)
      restore(true)
    })
  })
})

