import Helpers from "../apply/helpers";

class UnderwritingNotes {
  static edit_note_click(event) {
    let $edit_button     = $(event.target);
    let note_text_field  = $edit_button.siblings(".note-text")[0]
    let text_content     = note_text_field.textContent;
    let current_note_type = $(note_text_field).data('note_type')

    let text_area_field = document.createElement('textarea');
    text_area_field.value = text_content;
    text_area_field.name = 'underwriting_note[text]';
    text_area_field.dataset['previous_text'] = text_content
    $(text_area_field).addClass("form-control")

    $(note_text_field).html(text_area_field);

    let note_type_field = document.createElement('select');
    note_type_field.name = 'underwriting_note[note_type]';
    $(note_type_field).append($('<option>', { value: "underwriting", text: "Underwriting" }));
    $(note_type_field).append($('<option>', { value: "quote_follow_up", text: "Quote Follow Up" }));
    $(note_type_field).addClass("form-control")
    $(note_type_field).val(current_note_type)

    $(note_text_field).append(note_type_field);

    $edit_button.off("click")
    $edit_button.on("click", UnderwritingNotes.submit_note_edit)
    $edit_button.html("Save")
    $edit_button.removeClass("btn-danger")
    $edit_button.removeClass("edit-button")
    $edit_button.addClass("btn-primary")
  }

  static submit_note_edit(event) {
    event.preventDefault();
    event.stopPropagation();

    let $save_button = $(event.target);
    let $notes = $($save_button.siblings('.note-text')[0]);
    let $textarea = $($notes.find('textarea[name="underwriting_note[text]"]'));
    let $select = $($notes.find('select[name="underwriting_note[note_type]"]'));
    let $form     = $($textarea.parents('form'))
    let url       = $form.data('update_url')
    let form_data = new FormData($form[0])

    let new_text      = $textarea.val()
    let new_note_type = $select.val()

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {UnderwritingNotes.replace_textarea_with_text($textarea, $save_button, new_text, new_note_type)},
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error in Not Submission!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
        alert("Something went wrong when trying to update the note. Please refresh the page and try again.")
      }
    })
  }

  static replace_textarea_with_text($textarea, $save_button, text, new_note_type) {
    let $parent    = $($textarea.parent());
    let $note_text = $(document.createElement('div'));

    $note_text.addClass('note-text');
    $note_text.html(text);
    $parent.html($note_text[0]);

    $parent.data('note_type', new_note_type);

    let $note_tag = $parent.siblings('span.note-tag');
    $note_tag.text(new_note_type.replace(/_/g, ' '));

    $save_button.off('click').on('click', UnderwritingNotes.edit_note_click);
    $save_button.html("Edit");
    $save_button.addClass("btn-danger");
    $save_button.addClass("edit-button");
  }

  static getNotesTemplate() {
    var selected_option = $('#uw_notes_email_topic').val();
    if (typeof selected_option === 'undefined') { selected_option = 'quote_follow_up_1'; }
  
    var selected_option_key = selected_option.trim().toLowerCase().replace(/\s+/g, '_').replace('-', '_');
    var ajax_route = $('#uw_notes_email_topic').data('ajax_route');
    if ( ajax_route === undefined ) { return; }
    var url =  ajax_route + '&topic=' + selected_option_key
  
    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'json',
      success: function(response) {
        if (!(response && response.template && response.template.body)) {return;}
        window.template = response.template;
        if (window.template) {
          $('#notes_email_body_text_area').val(window.template.body);
          $('#notes_email_subject_text_area').val(window.template.subject);
        }
      },
      error: function(xhr, status, error) {
        if (!(xhr && xhr.responseText)) 
        {
          window.Honeybadger.notify(`Error in Email Template!\nxhr: ${xhr}\nurl: ${url}\nselected_option: ${selected_option}`)
          return;
        }
        var errorMessage = JSON.parse(xhr.responseText).error;
        console.log("Error message:", errorMessage);
      }})
  };
}

$(() => {
  $(document).on("click", ".edit-button", UnderwritingNotes.edit_note_click);
  UnderwritingNotes.getNotesTemplate();
  $('#uw_notes_email_topic').on('change', (event) => {
    UnderwritingNotes.getNotesTemplate();
  });
})

