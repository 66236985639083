import React from "react";
import PropTypes from "prop-types";
import CheckboxInput from "./CheckboxInput";

// This component can potentially be renamed to something more generic now that it only uses passed in options rather than generating them itself
const OrderEntitySelectInput = ({
  question,
  questionName,
  hasFieldError,
  questionIdentifier,
  onChange,
  onBlur,
  value,
}) => {
  const options = question.options;

  const handleChange = (event) => {
    onChange(event.target.value);
    onBlur(event.target.value);
  };

  if (question.multiple_entries) {
    return (
      <CheckboxInput
        question={question}
        questionName={questionName.slice(0, -2)}
        onChange={onChange}
        onBlur={onBlur}
        value={value || []}
      />
    );
  } else {
    return (
      <select
        className="form-select question_block_select"
        style={hasFieldError ? { border: "1px solid red" } : {}}
        name={questionName}
        aria-label={`for_${questionIdentifier}`}
        disabled={question.locked}
        onChange={handleChange}
        value={value || ""}
      >
        {options.map(([optionValue, optionLabel]) => (
          <option key={optionValue} value={optionValue}>
            {optionLabel}
          </option>
        ))}
      </select>
    );
  }
};

OrderEntitySelectInput.propTypes = {
  question: PropTypes.shape({
    locked: PropTypes.bool,
    multiple_entries: PropTypes.bool,
    options: PropTypes.object.isRequired,
  }).isRequired,
  questionName: PropTypes.string.isRequired,
  hasFieldError: PropTypes.bool,
  questionIdentifier: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

OrderEntitySelectInput.defaultProps = {
  hasFieldError: false,
  value: "",
};

export default OrderEntitySelectInput;
