import { configureStore } from "@reduxjs/toolkit";

import formSlice from "./formSlice";
import ajaxUrlsSlice from "./ajaxUrlsSlice";

export const store = configureStore({
  reducer: {
    form: formSlice,
    ajaxUrls: ajaxUrlsSlice,
  },
});

window.isFormLoading = function() {
  return store.getState().form.isLoading;
};
