export const bootstrapNavigation = (tabId, defaultTabId, parentId, tabClass) => {
  let tabToShow
  if (tabId) {
    if ($(parentId).find(tabId).length != 0) {
      tabToShow = tabId
    } else {
      tabToShow = defaultTabId
    }
  } else {
    tabToShow = defaultTabId
  }
  $(tabToShow).tab('show')

  $(tabClass).click((e) => {
    history.replaceState(null, null, `#${e.target.id}`)
  })
}
