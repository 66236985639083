import React from "react";
import PropTypes from "prop-types";

const SelectInput = ({
  question,
  questionName,
  hasFieldError,
  questionIdentifier,
  onChange,
  onBlur,
  multiple,
  value,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
    onBlur(event.target.value);
  };

  return (
    <select
      className="form-select question_block_select"
      style={hasFieldError ? { border: "1px solid red" } : {}}
      name={questionName}
      aria-label={`for_${questionIdentifier}`}
      disabled={question.locked}
      onChange={handleChange}
      value={value ? value.toString() : ""}
      multiple={multiple}
    >
      <option value="">Select an Option</option>
      {Object.entries(question.options).map(([optionValue, optionLabel]) => (
        <option key={optionValue} value={optionValue}>
          {optionLabel}
        </option>
      ))}
    </select>
  );
};

SelectInput.propTypes = {
  question: PropTypes.shape({
    locked: PropTypes.bool,
    options: PropTypes.object.isRequired,
  }).isRequired,
  questionName: PropTypes.string.isRequired,
  hasFieldError: PropTypes.bool,
  questionIdentifier: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiple: PropTypes.bool,
};

SelectInput.defaultProps = {
  hasFieldError: false,
  value: "",
  multiple: false,
};

export default SelectInput;
