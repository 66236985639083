import Helpers from '../apply/helpers.js'

require('webpack-jquery-ui/draggable');
require('webpack-jquery-ui/droppable');

class DocumentStorage {
  static drop_handler(e) {
    e.preventDefault()
    e.stopPropagation()
    let $drop_target = $(e.target).closest('.drop_target')
    $drop_target.removeClass('highlighted')
    if (e.dataTransfer == undefined) { return }

    let files = e.dataTransfer.files
    DocumentStorage.handle_file_upload(files, $drop_target)
  }
  static dragenter_handler(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer == undefined) { return }
    let $drop_target = $(e.target).closest('.drop_target')
    // console.log('dragenter', $drop_target)
    $drop_target.addClass('highlighted')
  }

  static dragleave_handler(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer == undefined) { return }
    let $drop_target = $(e.target).closest('.drop_target')
    // console.log('dragleave', $drop_target)
    $drop_target.removeClass('highlighted')
  }

  static dragover_handler(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer == undefined) { return }
    let $drop_target = $(e.target).closest('.drop_target')
    // console.log('dragover', $drop_target)
    $drop_target.addClass('highlighted')
  }

  static handle_file_upload(file_list, $drop_target) {
    // TODO - remove this limitation
    if (file_list.length > 1) {
      alert("Only one file may be uploaded at a time, please try again")
      return
    }

    let $form = $('#document_upload_form')
    let file_input = $('#document_upload_form input.hidden_file_input')[0]
    if ($drop_target != undefined) {
      let drop_scope_input = $('#document_upload_form input[name="drop_scope"]')[0]
      drop_scope_input.value = $drop_target.data('drop_scope')
      if (drop_scope_input.value == 'folder') {
        let folder_uuid_input = $('#document_upload_form input[name="folder_uuid"]')[0]
        folder_uuid_input.value = $drop_target.data('folder_uuid')
      }
    }

    file_input.files = file_list
    $form.trigger('submit')
  }
}

window.DocumentStorage = DocumentStorage

$(()=>{
  $(document).on('click', (e)=>{
    $('#right_click_menu').slideUp(200)
    $('.right_clickable.highlighted').removeClass('highlighted')
  })

  // Move the document viewer to the body
  let $document_viewer = $('#document_viewer').detach()
  $('body').append($document_viewer)

  let $new_folder_form = $('#new_folder_form').detach()
  $('body').append($new_folder_form)

  let $rename_form = $('#rename_form').detach()
  $('body').append($rename_form)

  let $delete_form = $('#delete_form').detach()
  $('body').append($delete_form)

  let $notes_form = $('#notes_form').detach()
  $('body').append($notes_form)

  $(document).on('click', '#admin_document_storage_container tr.file', (e)=>{
    let $clicked = $(e.target)
    let $row = $clicked.parents('tr.file')
    let file_name = $row.data('file_name')
    const regex = /\d{3}\.eml$/;
    if (regex.test(file_name)) {file_name = file_name.slice(0, -7);}
    let file_url = $row.data('file_url')
    let viewer_url = $row.data('viewer_url')
    // console.log(file_name)
    // console.log(file_url)
    // console.log(viewer_url)

    $('#document_viewer #document_viewer_header h5').text(file_name)
    $('#document_viewer').removeClass('hidden')
    $('#document_viewer #document_frame').prop('src', viewer_url)
    setTimeout(()=>{
      $('#document_viewer #iframe_wrapper').removeClass('loading')
    }, 1000)
  })

  $(document).on('click', '#document_viewer a.close_button', (e)=> {
    // console.log(e)
    e.preventDefault()
    $('#document_viewer').addClass('hidden')
    $('#document_viewer #document_frame').prop('src', '')
    $('#document_viewer #iframe_wrapper').addClass('loading')
  })

  $(document).on('click', '#new_folder_form .close_button', (e)=> {
    // console.log(e)
    e.preventDefault()
    $('#new_folder_form').addClass('hidden')
    $('#new_folder_form input[type="text"]').val('')
    $('#new_folder_form input[name="folder_uuid').val('')
  })

  $(document).on('click', '#rename_form .close_button', (e) => {
    // console.log(e)
    e.preventDefault()
    $('#rename_form').addClass('hidden')
    $('#rename_form input[name="document_item_type"]').val('')
    $('#rename_form input[name="document_item_uuid"]').val('')
    $('#rename_form input[name="name"]').val('')
  })

  $(document).on('click', '#delete_form .close_button', (e) => {
    e.preventDefault()
    $('#delete_form').addClass('hidden')
    $('#delete_form input[name="document_item_type"]').val('')
    $('#delete_form input[name="document_item_uuid"]').val('')
    $('#delete_form input[name="name"]').val('')
  })

  $(document).on('click', '#notes_form .close_button', (e) => {
    e.preventDefault()
    $('#notes_form').addClass('hidden')
    $('#notes_form input[name="document_item_type"]').val('')
    $('#notes_form input[name="document_item_uuid"]').val('')
    $('#notes_form input[name="notes"]').val('')
  })

  $(document).on('click', '#tags_form .close_button', (e) => {
    e.preventDefault()
    $('#tags_form').addClass('hidden')
    $('#tags_form input[name="document_item_type"]').val('')
    $('#tags_form input[name="document_item_uuid"]').val('')
    $('#tags_form input[name="tags"]').val('')
  })

  $('#upload_button').on('click', (e)=>{
    e.preventDefault();
    $('#document_upload_form input.hidden_file_input').trigger('click')
  })

  $('#new_folder_button').on('click', (e)=> {
    e.preventDefault();
    $('#new_folder_form').removeClass('hidden')
  })

  $('#right_click_new_folder_button').on('click', (e)=>{
    e.preventDefault();
    let relevant_folder_uuid = $('#right_click_menu').attr('data-folder_uuid')
    let $right_clickable = $(`#folder_row_${relevant_folder_uuid}`)
    // console.log($right_clickable)
    setTimeout(()=>{
      $right_clickable.addClass('highlighted')
    },10)

    $('#new_folder_form input[name="folder_uuid"]').val(relevant_folder_uuid)
    $('#new_folder_form').removeClass('hidden')
  })

  $('#right_click_rename_button').on('click', (e)=>{
    e.preventDefault();
    let relevant_file_uuid = $('#right_click_menu').attr('data-file_uuid')
    let relevant_folder_uuid = $('#right_click_menu').attr('data-folder_uuid')

    if (relevant_file_uuid != undefined) {
      let $right_clickable = $(`#file_row_${relevant_file_uuid}`)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      }, 10)
      $('#rename_form input[name="document_item_type"]').val('file')
      $('#rename_form input[name="document_item_uuid"]').val(relevant_file_uuid)
      $('#rename_form input[name="name"]').val($right_clickable.data('file_name'))
    } else if (relevant_folder_uuid != undefined) {
      let $right_clickable = $(`#folder_row_${relevant_folder_uuid}`)
      // console.log($right_clickable)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      },10)
      $('#rename_form input[name="document_item_type"]').val('folder')
      $('#rename_form input[name="document_item_uuid"]').val(relevant_folder_uuid)
      $('#rename_form input[name="name"]').val($right_clickable.data('folder_name'))
    } else {
      alert('Something went wrong. Please refresh the page and alert the Blitz tech team if the problem persists.')
    }

    $('#rename_form').removeClass('hidden')
  })

  $('#right_click_delete_button').on('click', (e)=>{
    e.preventDefault();
    let relevant_file_uuid = $('#right_click_menu').attr('data-file_uuid')
    let relevant_folder_uuid = $('#right_click_menu').attr('data-folder_uuid')

    if (relevant_file_uuid != undefined) {
      let $right_clickable = $(`#file_row_${relevant_file_uuid}`)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      }, 10)
      $('#delete_form input[name="document_item_type"]').val('file')
      $('#delete_form input[name="document_item_uuid"]').val(relevant_file_uuid)
      $('#delete_form input[name="name"]').val($right_clickable.data('file_name'))
    } else if (relevant_folder_uuid != undefined) {
      let $right_clickable = $(`#folder_row_${relevant_folder_uuid}`)
      // console.log($right_clickable)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      },10)
      $('#delete_form input[name="document_item_type"]').val('folder')
      $('#delete_form input[name="document_item_uuid"]').val(relevant_folder_uuid)
      $('#delete_form input[name="name"]').val($right_clickable.data('folder_name'))
    } else {
      alert('Something went wrong. Please refresh the page and alert the Blitz tech team if the problem persists.')
    }

    $('#delete_form').removeClass('hidden')
  })

  $('#right_click_edit_notes_button').on('click', (e)=>{
    e.preventDefault();
    let relevant_file_uuid = $('#right_click_menu').attr('data-file_uuid')
    let relevant_folder_uuid = $('#right_click_menu').attr('data-folder_uuid')

    if (relevant_file_uuid != undefined) {
      let $right_clickable = $(`#file_row_${relevant_file_uuid}`)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      }, 10)
      $('#notes_form input[name="document_item_type"]').val('file')
      $('#notes_form input[name="document_item_uuid"]').val(relevant_file_uuid)
      $('#notes_form textarea[name="notes"]').val($right_clickable.data('notes'))
    } else if (relevant_folder_uuid != undefined) {
      let $right_clickable = $(`#folder_row_${relevant_folder_uuid}`)
      // console.log($right_clickable)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      },10)
      $('#notes_form input[name="document_item_type"]').val('folder')
      $('#notes_form input[name="document_item_uuid"]').val(relevant_folder_uuid)
      $('#notes_form textarea[name="notes"]').val($right_clickable.data('notes'))
    } else {
      alert('Something went wrong. Please refresh the page and alert the Blitz tech team if the problem persists.')
    }

    $('#notes_form').removeClass('hidden')
    $('#notes_form textarea[name="notes"]').height($('#notes_form textarea[name="notes"]')[0].scrollHeight)
    setTimeout(()=>{

    }, 1)
  })

  $('#right_click_edit_tags_button').on('click', (e)=>{
    e.preventDefault();

    let relevant_file_uuid = $('#right_click_menu').attr('data-file_uuid')
    let relevant_folder_uuid = $('#right_click_menu').attr('data-folder_uuid')

    if (relevant_file_uuid != undefined) {
      let $right_clickable = $(`#file_row_${relevant_file_uuid}`)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      }, 10)
      $('#tags_form input[name="document_item_type"]').val('file')
      $('#tags_form input[name="document_item_uuid"]').val(relevant_file_uuid)
      $('#tags_form textarea[name="tags"]').val($right_clickable.data('tags'))
    } else if (relevant_folder_uuid != undefined) {
      let $right_clickable = $(`#folder_row_${relevant_folder_uuid}`)
      // console.log($right_clickable)
      setTimeout(()=>{
        $right_clickable.addClass('highlighted')
      },10)
      $('#tags_form input[name="document_item_type"]').val('folder')
      $('#tags_form input[name="document_item_uuid"]').val(relevant_folder_uuid)
      $('#tags_form textarea[name="tags"]').val($right_clickable.data('tags'))
    } else {
      alert('Something went wrong. Please refresh the page and alert the Blitz tech team if the problem persists.')
    }

    $('#tags_form').removeClass('hidden')
    $('#tags_form textarea[name="tags"]').height($('#tags_form textarea[name="tags"]')[0].scrollHeight)
    setTimeout(()=>{

    }, 1)

  })

  function determine_mouse_x(e) {
    if (e.pageX) {
      return e.pageX;
    } else if (e.clientX) {
      return e.clientX + (document.documentElement.scrollLeft ?
          document.documentElement.scrollLeft :
          document.body.scrollLeft);
    } else {
        return null;
    }
  }

  function determine_mouse_y(e) {
    var window_height = $(window).height()

    var click_mouse_y = null
    if (e.pageY) {
      click_mouse_y = e.pageY;
    } else if (e.clientY) {
      click_mouse_y = e.clientY + (document.documentElement.scrollTop ?
      document.documentElement.scrollTop :
      document.body.scrollTop);
    } else {
      click_mouse_y =  null;
    }

    var menu_height = $('#right-click-menu-div').height()
    // console.log(menu_height)

    if (window_height - click_mouse_y < menu_height){
      return window_height - menu_height
    } else {
      return click_mouse_y
    }
  }

  $(document).on('contextmenu', '#admin_document_storage_container tr.file, #admin_document_storage_container tr.folder', (e)=>{
    e.preventDefault();

    $('.right_clickable.highlighted').removeClass('highlighted')
    // console.log('right clicked on', e.target)
    let $right_clickable = $(e.target).closest('.right_clickable')
    $right_clickable.addClass('highlighted')
    // console.log($right_clickable)

    let $right_click_menu = $('#right_click_menu')
    $right_click_menu.removeAttr('data-folder_uuid')
    $right_click_menu.removeAttr('data-file_uuid')
    $right_click_menu.attr('data-folder_uuid', $right_clickable.data('folder_uuid'))
    $right_click_menu.attr('data-file_uuid', $right_clickable.data('file_uuid'))
    if ($right_clickable.hasClass('file')) {
      $('#right_click_download_button').show()
      $('#right_click_download_button').attr('href', $right_clickable.data('file_url'))
      $('#right_click_download_button').attr('download', $right_clickable.data('file_name'))
    } else {
      $('#right_click_download_button').hide()
      $('#right_click_download_button').attr('href', '')
      $('#right_click_download_button').attr('download', '')
    }
    if ($('.alert')[0] != undefined) {
      $right_click_menu.css('top', `calc(${determine_mouse_y(e) + 'px'} - 204px)`)
    } else {
      $right_click_menu.css('top', `calc(${determine_mouse_y(e) + 'px'} - 130px)`)
    }

    let wrapper_margin = $('main').css('margin-left')
    $right_click_menu.css('left', `calc(${determine_mouse_x(e) + 'px'} - ${wrapper_margin} - 20px)`)
    $right_click_menu.slideDown(300)
  })

  $(document).on('click', '#admin_document_storage_container label.fold_button input', (e)=>{
    let $clicked = $(e.target)
    let folder_uuid = $clicked.data('folder_uuid')

    if ($clicked.is(':checked')) {
      $(`#folder_wrapper_${folder_uuid}`).slideDown()
    } else {
      $(`#folder_wrapper_${folder_uuid}`).slideUp()
    }
    let $form = $('#document_upload_form')
    let url = $form.data('view_options_url')
    // console.log(url)
    let form_data = new FormData($form[0])
    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        // console.log(res)
      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error in Document Storage!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
      }
    })
  })


  $('.draggable').draggable({
    containment: '#admin_document_storage_container',
    cursor: 'move',
    stack: 'table',
    revert: true,
    start: (e, ui) => {
      $(e.target).addClass('dragging')
    },
    stop: (e, ui) => {
      $(e.target).removeClass('dragging')
    }
  })

  $('.drop_target').droppable({
    accept: '.draggable',
    greedy: true,
    tolerance: 'intersect',
    hoverClass: 'highlighted',
    drop: (e, ui) => {
      let $drop_target = $(e.target)
      let drop_scope = $drop_target.data('drop_scope')
      let drop_uuid = $drop_target.data('drop_uuid')
      // console.log(`drop scope: ${drop_scope}`)

      let $draggable = ui.draggable
      // console.log($draggable)
      let draggable_type = $draggable.data('draggable_type')
      let draggable_uuid = $draggable.data('draggable_uuid')
      let draggable_parent = $draggable.data('draggable_parent')

      // console.log(`dropped ${draggable_type} with uuid ${draggable_uuid}`)

      if (draggable_parent != drop_uuid && drop_uuid != draggable_uuid) {
        $draggable.draggable('option', 'revert', false)
        setTimeout(()=>{
          $draggable.addClass('dragging')
          $drop_target.addClass('highlighted')
        },1)

        $('#admin_document_storage_container').addClass('loading')
        $.post({
          url: $('#document_upload_form').data('move_item_url'),
          data: {
            authenticity_token: $('input[name="authenticity_token"]')[0].value,
            document_item_type: draggable_type,
            document_item_uuid: draggable_uuid,
            parent_uuid: drop_uuid,
            drop_scope: drop_scope,
          },
          success: (res) => {
            // console.log(res)
            // window.location = res
            window.location.reload()
          },
        })
      } else {
        // console.log("dragged to same parent")
      }



    },
  })
})
