import React, { useRef, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { FormContext } from "../StatelessComponents/FormContext";
import removeSolidOrangeImage from "../../../images/remove_solid_orange.svg";
import QuestionBlock from "./QuestionBlock";
import SmartAddress from "./SmartAddress";
import SmartBuildingValue from "./SmartBuildingValue";

// This component renders a single entry of a question group
// It is responsible for registering the scroll ref for the form to be able
// to scroll to new entries as they're added as well as the logic for
// the delete entry button (for multiple entry groups)
function QuestionGroupEntry({
  questionIdentifier,
  question,
  group,
  groupIndex,
}) {
  const dispatch = useDispatch();
  const { registerNewEntryScrollRef, removeEntry, ajaxDeleteEntity } = useContext(FormContext);
  const handleDeleteEntry = (groupIndex) => {
    const relevantGroup = question.groups[groupIndex];
    if (relevantGroup?.entity_identifier?.value !== undefined && relevantGroup?.entity_identifier?.value !== "") {
      dispatch(ajaxDeleteEntity({ questionIdentifier, groupIndex, entityUuid: relevantGroup.entity_identifier.value }));
    } else {
      dispatch(removeEntry({ questionIdentifier, groupIndex }));
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    registerNewEntryScrollRef(ref);
  }, [groupIndex]);

  return (
    <div className={"question_group_entry card"} ref={ref} id={`${questionIdentifier}-container-${groupIndex}`}>
      {Object.entries(group).map(([childQuestionIdentifier, childQuestion]) => {
        if (childQuestion.keep === false) { return null; }
        if (childQuestion.ui_component === "smart_address") {
          return (
            <SmartAddress
              key={childQuestionIdentifier}
              question={childQuestion}
              parentQuestionIdentifier={questionIdentifier}
              groupIndex={groupIndex}
            />
          );
        }
        if (childQuestion.ui_component === "smart_building_value") {
          return (
            <SmartBuildingValue
              key={childQuestionIdentifier}
              question={childQuestion}
              parentQuestionIdentifier={questionIdentifier}
              groupIndex={groupIndex}
            />
          );
        }
        return (<QuestionBlock
          key={childQuestionIdentifier}
          question={childQuestion}
          questionIdentifier={childQuestionIdentifier}
          multiple={question.multiple_entries}
          parentQuestionIdentifier={questionIdentifier}
          groupIndex={groupIndex}
        />);
      })}
      {groupIndex !== undefined && groupIndex !== null && !question.exact_count && (
        <button
          className="remove_button btn"
          onClick={() => handleDeleteEntry(groupIndex)}
          type="button"
        >
          <img src={removeSolidOrangeImage} alt="Remove" />
        </button>
      )}
    </div>
  );
}

QuestionGroupEntry.propTypes = {
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    group: PropTypes.objectOf(PropTypes.object),
    groups: PropTypes.arrayOf(PropTypes.object),
    multiple_entries: PropTypes.bool,
    exact_count: PropTypes.number,
  }).isRequired,
  questionIdentifier: PropTypes.string.isRequired,
  groupIndex: PropTypes.number,
  group: PropTypes.objectOf(PropTypes.object).isRequired,
};

QuestionGroupEntry.defaultProps = {
  groupIndex: undefined,
};

export default QuestionGroupEntry;
