/* global $ */
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { FormContext } from "../../Shared/StatelessComponents/FormContext";
import subtractGray from "../../../images/subtract_gray.svg";
import ScheduleTableCell from "./ScheduleTableCell";
import ScheduleSmartAddressCells from "./ScheduleSmartAddressCells";
import questionMarkImage from "../../../images/question_mark.svg";

function ScheduleTable({ scheduleIdentifier }) {
  const scheduleData = useSelector(state => state.form?.questions?.[scheduleIdentifier] || null);
  const dispatch = useDispatch();
  const { removeEntry, ajaxDeleteEntity } = useContext(FormContext);

  useEffect(() => {
    $("[data-bs-toggle=\"tooltip\"]").tooltip();
  }, [scheduleData]);

  if (!scheduleData) {
    return null;
  }

  const removeRow = (e, groupIndex) => {
    e.preventDefault();
    const relevantGroup = scheduleData.groups[groupIndex];
    if (relevantGroup?.entity_identifier?.value !== undefined && relevantGroup?.entity_identifier?.value !== "") {
      dispatch(ajaxDeleteEntity({ questionIdentifier: scheduleIdentifier, groupIndex, entityUuid: relevantGroup.entity_identifier.value }));
    } else {
      dispatch(removeEntry({ questionIdentifier: scheduleIdentifier, groupIndex }));
    }
  };

  return (
    <div id="schedule_table_wrapper">
      <table className="table table-borderless">
        <thead>
          <tr>
            {Object.entries(scheduleData.questions).map(([column, values], index) => {
              if (column === "entity_identifier" || values.keep === false) return null;

              if (values.ui_component === "smart_address") {
                return (
                  <React.Fragment key={index}>
                    {Object.entries(values.aliased_fields).map(([address_field, question], questionIndex) => (
                      <th key={`${index}${questionIndex}`}>
                        {question.label.toUpperCase()}
                      </th>
                    ))}
                  </React.Fragment>
                );
              }

              return (
                <th key={index}>
                  {values.label.toUpperCase()}
                  {values.tooltip && (
                    <div
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title={values.tooltip}
                      style={{ display: "inline", width: "40px" }}
                    >
                      <img
                        src={questionMarkImage}
                        style={{ width: "20px", marginLeft: "5px" }}
                        alt="Tooltip icon"
                      />
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={scheduleData.questions.entity_identifier ? "with_entity_identifier" : ""}>
          {scheduleData.groups.map((group, groupIndex) => (
            <tr key={groupIndex} data-group_index={groupIndex}>
              {Object.entries(group).map(([questionIdentifier, question]) => {
                if (question.keep === false) { return null; }

                if (question.ui_component === "smart_address") {
                  return (
                    <ScheduleSmartAddressCells key={`${groupIndex}_${questionIdentifier}`} question={question} groupIndex={groupIndex} scheduleIdentifier={scheduleIdentifier} />
                  );
                }

                return (
                  <ScheduleTableCell
                    key={`${groupIndex}_${questionIdentifier}`}
                    question={question}
                    scheduleIdentifier={scheduleIdentifier}
                    groupIndex={groupIndex}
                    questionIdentifier={questionIdentifier}
                  />);
              })}
              <td className="hidden_cell">
                <button className="btn remove_button" onClick={(e) => { removeRow(e, groupIndex); }} type="button">
                  <img src={subtractGray} alt="Remove" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ScheduleTable.propTypes = {
  scheduleIdentifier: PropTypes.string.isRequired,
};

export default ScheduleTable;
