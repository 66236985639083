import consumer from "./consumer"

// console.log("loaded refresh channel")

function trigger_refresh() {
  // Don't refresh if on adjustment/task pages. the refresh signal comes in before the submit finishes redirecting
  if ($('#offer_adjustment_wrapper')[0] != undefined) { return }
  if ($('#coverage_modification_wrapper')[0] != undefined) { return }
  if ($('#specialist_task_show_wrapper')[0] != undefined) { return }
  // Don't refresh if there's a currently submitting form
  if ($('.disabled_with_spinner')[0] != undefined) { return }
  if ($('.disable_on_submits.disabled')[0] != undefined) { return }

  // console.log("triggering refresh")
  window.location.reload()
}

// This chunk of code catches interrupted refreshes and finishes them off
try {
  if (window.localStorage.getItem('pending_refresh') != undefined) {
    setTimeout(()=>{
      if (window.localStorage.getItem('pending_refresh') != undefined) {
        window.localStorage.removeItem('pending_refresh')
        trigger_refresh()
      }
    }, 1500)
  }
} catch (e) {
  // wasn't able to access local storage, probably due to privacy settings
  // no big deal, just ignore it
  // window.Honeybadger.notify(e)
}

$(()=>{
  let current_app = $('current_app#current_app_reference_uuid')
  if (current_app[0] == undefined) { return }

  let app_uuid = current_app.text().trim()
  // console.log("creating subscription")
  consumer.subscriptions.create({ channel: "RefreshChannel", app_uuid: app_uuid }, {
    received(data) {
      this.handle_incoming_message(data)
    },

    handle_incoming_message(data) {
      // console.log("Incoming message on the refresh channel:")
      // console.log(data)

      if (data['pending_refresh'] != undefined) {
        // Don't refresh if there's a currently submitting form
        if ($('.disabled_with_spinner')[0] != undefined) { return }
        if ($('.disable_on_submits.disabled')[0] != undefined) { return }

        // Setting this cookie will ensure there's a followup on the refresh later
        try { window.localStorage.setItem('pending_refresh', 'true') } catch (e) { /* ignore */ }
        if ($('#fallback_journey_flow')[0] != undefined) {
          $('#admin_app_flow_container > .card').addClass('loading')
        }
      } else if (data['should_refresh']) {
        try { window.localStorage.removeItem('pending_refresh') } catch (e) { /* ignore */ }

        var already_on_target_stage = false
        let current_stage = $('current_stage#current_stage')
        if (current_stage[0] != undefined) {
          let current_stage_value = current_stage.text().trim()
          already_on_target_stage = data['target_stage'] == current_stage_value
        }
        if (already_on_target_stage) { return }

        trigger_refresh()
      }
    }

  })
})

