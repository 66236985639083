import Helpers from "../apply/helpers"

class IntegrationsPage {
  static update_submissions_data_form(e) {
    let $form = $('#submissions_data_integration_documentation_form')
    let url = $form.attr('ajax')
    let form_data = new FormData($form[0])

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        // console.log(res)
        $form.html(res.partial)
      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error in IntegrationsPage!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
      }
    })
  }

  static update_product_interests_form(e) {
    let $form = $('#product_interests_integration_documentation_form')
    let url = $form.attr('ajax')
    let form_data = new FormData($form[0])

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        if (res.success) {
          $form.html(res.partial)
        } else {
          $form.append("<p class='text-danger'>There was an error updating the Product Interests API Documentation. Please try again later.</p>")
        }
      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error updating Product Interests API Documentation: ${err}`,
          {
            context: {
              result: result,
              outgoingPayload: outgoingPayload,
              url: postUrl,
              currentPage: currentPage
            }
          }
        )
      }
    })
  }

  static update_bulk_runs_form(e) {
    let $form = $('#new_bulk_run_form')
    let url = $form.attr('ajax')
    let form_data = new FormData($form[0])

    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        $form.closest('div').html(res.partial)
      },
      failure: (res) => {
        let currentPage = window.location.href
        let postUrl = url
        let outgoingPayload = JSON.stringify(form_data);
        let result = JSON.stringify(res, null, 2)
        window.Honeybadger.notify(`Error while attempting to rerender new_bulk_run_form!\nresult: ${result}\ncurrentPage: ${currentPage}\npostUrl: ${postUrl}\noutgoingPayload: ${outgoingPayload}`)
      }
    })
  }

  static filter_relevant_questions(e) {
    // Hide all question_sections
    $('.question_section').addClass('visually-hidden')

    // Unhide selected question_section
    $(`#${e.target.value}`).removeClass('visually-hidden')
  }
}

$(()=>{
  $(document).on('change', '#portal_integrations_container #submissions_data_integration_documentation_form select', IntegrationsPage.update_submissions_data_form)
  $(document).on('change', '#bulk_runs #new_bulk_run_form select', IntegrationsPage.update_bulk_runs_form)
  $(document).on('change', '#product_interests_integration_documentation_form select', IntegrationsPage.update_product_interests_form)

  $('#portal_integrations_container #api_key_placeholder').on('click', (e)=>{
    // console.log('clicked button')
    $('#api_key_placeholder').addClass('hidden')
    $('#api_key').removeClass('hidden')
  })

  $('#portal_integrations_container #api_key_clipboard_button').on('click', (e)=>{
    e.preventDefault()
    let api_key = $('#api_key').val()
    navigator.clipboard.writeText(api_key)
    $('#api_key_clipboard_button .copy_icon').addClass('hidden')
    $('#api_key_clipboard_button .checkmark_icon').removeClass('hidden')
    $('#api_key_copied_message').removeClass('hidden')
    setTimeout(()=>{
      $('#api_key_clipboard_button .copy_icon').removeClass('hidden')
      $('#api_key_clipboard_button .checkmark_icon').addClass('hidden')
      $('#api_key_copied_message').addClass('hidden')
    }, 3000)
  })

  $('#portal_integrations_container #digital_application_link_clipboard_button').on('click', (e)=>{
    e.preventDefault()
    let digital_application_link = $('#digital_application_link').val()
    navigator.clipboard.writeText(digital_application_link)
    $('#digital_application_link_clipboard_button .copy_icon').addClass('hidden')
    $('#digital_application_link_clipboard_button .checkmark_icon').removeClass('hidden')
    $('#digital_application_link_copied_message').removeClass('hidden')
    setTimeout(()=>{
      $('#digital_application_link_clipboard_button .copy_icon').removeClass('hidden')
      $('#digital_application_link_clipboard_button .checkmark_icon').addClass('hidden')
      $('#digital_application_link_copied_message').addClass('hidden')
    }, 3000)
  })

  $('#portal_integrations_container #company_digital_application_link_clipboard_button').on('click', (e)=>{
    e.preventDefault()
    let digital_application_link = $('#company_digital_application_link').val()
    navigator.clipboard.writeText(digital_application_link)
    $('#company_digital_application_link_clipboard_button .copy_icon').addClass('hidden')
    $('#company_digital_application_link_clipboard_button .checkmark_icon').removeClass('hidden')
    $('#company_digital_application_link_copied_message').removeClass('hidden')
    setTimeout(()=>{
      $('#company_digital_application_link_clipboard_button .copy_icon').removeClass('hidden')
      $('#company_digital_application_link_clipboard_button .checkmark_icon').addClass('hidden')
      $('#company_digital_application_link_copied_message').addClass('hidden')
    }, 3000)
  })

  $('#portal_integrations_container #emebeddable_digital_submission_clipboard_button').on('click', (e)=>{
    e.preventDefault()
    let emebeddable_digital_submission = $('#emebeddable_digital_submission').val()
    navigator.clipboard.writeText(emebeddable_digital_submission)
    $('#emebeddable_digital_submission_clipboard_button .copy_icon').addClass('hidden')
    $('#emebeddable_digital_submission_clipboard_button .checkmark_icon').removeClass('hidden')
    $('#emebeddable_digital_submission_copied_message').removeClass('hidden')
    setTimeout(()=>{
      $('#emebeddable_digital_submission_clipboard_button .copy_icon').removeClass('hidden')
      $('#emebeddable_digital_submission_clipboard_button .checkmark_icon').addClass('hidden')
      $('#emebeddable_digital_submission_copied_message').addClass('hidden')
    }, 3000)
  })

  $('#portal_integrations_container #company_emebeddable_digital_submission_clipboard_button').on('click', (e)=>{
    e.preventDefault()
    let emebeddable_digital_submission = $('#company_emebeddable_digital_submission').val()
    navigator.clipboard.writeText(emebeddable_digital_submission)
    $('#company_emebeddable_digital_submission_clipboard_button .copy_icon').addClass('hidden')
    $('#company_emebeddable_digital_submission_clipboard_button .checkmark_icon').removeClass('hidden')
    $('#company_emebeddable_digital_submission_copied_message').removeClass('hidden')
    setTimeout(()=>{
      $('#company_emebeddable_digital_submission_clipboard_button .copy_icon').removeClass('hidden')
      $('#company_emebeddable_digital_submission_clipboard_button .checkmark_icon').addClass('hidden')
      $('#company_emebeddable_digital_submission_copied_message').addClass('hidden')
    }, 3000)
  })

  $(document).on('click', '.btn-check', IntegrationsPage.filter_relevant_questions)
})