import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { FormContext } from "../../Shared/StatelessComponents/FormContext";

function ScheduleDropBox({ scheduleIdentifier, exampleCSVPath }) {
  const [highlighted, setHighlighted] = useState(false);
  const dispatch = useDispatch();
  const { ajaxFileUpload } = useContext(FormContext);

  const dropHandler = (e) => {
    handleEndHighlight(e);

    const files = e.dataTransfer.files;
    handleFileUpload(files);
  };

  const handleStartHighlight = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(true);
  };

  const handleEndHighlight = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(false);
  };

  const handleFileUpload = (fileList) => {
    if (fileList.length > 1) {
      alert("Only one file may be uploaded at a time, please try again");
      return;
    }

    if (fileList[0] == null) { return; }
    const extension = fileList[0].name.split(".").pop();
    if (extension.toLowerCase() !== "csv") {
      alert(`File type not accepted: ${fileList[0].name}\n\nPlease upload a file with one of the following extensions: .csv`);
      return;
    }

    const formData = new FormData();
    formData.append("schedule_data", fileList[0]);
    formData.append("schedule_identifier", scheduleIdentifier);
    formData.append("schedule_format", "standard");
    formData.append("authenticity_token", document.querySelector("input[name=\"authenticity_token\"]")?.value);

    dispatch(ajaxFileUpload(formData));
  };

  return (
    <div id="schedule_drop_box_wrapper" className="row py-4">
      <div
        id="drag_and_drop_wrapper"
        onDrop={dropHandler}
        onDragOver={handleStartHighlight}
        onDragEnter={handleStartHighlight}
        onDragLeave={handleEndHighlight}
        className={highlighted ? "highlighted" : ""}
      >
        <div className="row">
          <div id="schedule_file_upload_error"></div>
          <div className="col-4 vertical_center_container">
            <span>
              Drag and Drop File Here or {}
              <label className="hidden_file_input_label">
                Choose File
                <input className="hidden_file_input" type="file" onChange={(e) => handleFileUpload(e.target.files)} accept=".csv" />
              </label>
            </span>
          </div>
          <div className="col-8 vertical_center_container">
            <span style={{ margin: "10px" }}>
              Upload a <b>Blitz Standard CSV</b> (<a href={exampleCSVPath}>Download Example</a>)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

ScheduleDropBox.propTypes = {
  scheduleIdentifier: PropTypes.string.isRequired,
  exampleCSVPath: PropTypes.string.isRequired,
};

export default ScheduleDropBox;
