import PropTypes from "prop-types";
import React from "react";

const TextInput = (props) => {
  return (
    <input
      ref={props.inputRef}
      className="text form-control question_block_input"
      type="text"
      name={props.questionName}
      aria-describedby={props.describerId}
      style={props.hasFieldError ? { border: "1px solid red" } : {}}
      placeholder={props.placeholder}
      value={props.value || ""}
      disabled={props.question.locked}
      onChange={(e) => props.onChange(e.target.value)}
      onBlur={(e) => props.onBlur(e.target.value)}
    ></input>
  );
};

TextInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hasFieldError: PropTypes.bool,
  describerId: PropTypes.string,
  question: PropTypes.shape({
    locked: PropTypes.bool,
  }).isRequired,
  questionName: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

TextInput.defaultProps = {
  value: "",
};

export default TextInput;
