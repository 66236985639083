import React from "react";
import PropTypes from "prop-types";

function DateInput({
  question,
  questionName,
  describerId,
  value,
  onChange,
  hasFieldError,
}) {
  return (
    <input
      className="form-control question_block_input date"
      type="date"
      name={questionName}
      aria-describedby={describerId}
      style={hasFieldError ? { border: "1px solid red" } : {}}
      value={value || ""}
      placeholder={question.placeholder}
      disabled={question.locked}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

DateInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  describerId: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    placeholder: PropTypes.string,
    locked: PropTypes.bool,
  }).isRequired,
  hasFieldError: PropTypes.bool,
};

DateInput.defaultProps = {
  value: "",
  hasFieldError: false,
};

export default DateInput;
