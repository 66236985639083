import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

// This is the slice of the redux store that holds the ajax urls for the app
// that got passed in from the rails view
export const ajaxUrlsSlice = createSlice({
  name: "ajaxUrls",
  initialState,
  reducers: {
    initializeState: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { initializeState } = ajaxUrlsSlice.actions;
export default ajaxUrlsSlice.reducer;
